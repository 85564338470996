import { Alert, Dialog, IconButton, Slide } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react'
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import { useState } from 'react';
import usePostData from '../../hooks/usePostData';
import { REFUND_DETAILS } from './ledgerInputs';
import { useEffect } from 'react';
import { getList } from '../../api/collections';
import { Modal } from '@mui/material'
import { useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import useFetchList from '../../hooks/useFetchList';

const EditEntry = ({ open, handleClose, data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    fee_book_no: searchParams.get('feebook_no') || '',
    year_id: searchParams.get('year_id') || '',
    prefix_id: searchParams.get('prefix_id') || '',
    ...data
  }
  const [optionsArray, setOptionsArray] = useState({ yearOptions: [], prefixOptions: [], standardTypeOptions: [], termOptions: [] })
  const [ledgerEntry, setLedgerEntry] = useState(initialState)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const { handlePostData } = usePostData({
    queryName: 'getPaymentdetails',
    url: 'updateReceiptEntry',
    onSuccessCb: () => {
      setError(false)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
        setError(false)
      }, 4000)
      handleClose()
    },
    onErrorCbCb: () => {
      setSuccess(false)
      setError(true)
      setTimeout(() => {
        setError(false)
        setSuccess(false)
      }, 4000)
    }
  })
  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...ledgerEntry,
      [e.target.name]: value
    }
    setLedgerEntry(data)
  }

  const handleSubmitStudent = (e) => {
    e.preventDefault()
    handlePostData.mutate({ ...ledgerEntry })
  }

  useEffect(() => {
    getList("Year")
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          yearOptions: res.data
        }));
      })
    getList("Prefix")
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          prefixOptions: res.data
        }));
      })
    getList("StandardType")
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          standardTypeOptions: res.data
        }));
      })

  }, []);

  const handleDurationSuccess = (res) => {
    let tempTerm = []
    res.data?.map((it) => {
      return tempTerm.push({
        value: it.id,
        label: it.name
      })
    })
    setOptionsArray(prevOptions => ({
      ...prevOptions,
      termOptions: tempTerm
    }));
  }

  const { handleGetData } = useFetchList('getDurationList', { ...ledgerEntry }, handleDurationSuccess)

  const handleTermChange = (value) => {
    if (value) {
      const temp = []
      value.map((it) => {
        return temp.push(it.value)
      });
      setLedgerEntry(prevState => ({
        ...prevState,
        term_id: temp
      }))
    }
  }
  return (
    <>
      <Modal disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className='max-w-[80%] m-auto p-6 mb-10 mt-20 bg-white'>
          <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
            <div>
              <IconButton onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className='heading'>Edit Entry</h1>
            </div>
          </div>
          <div className='form-body py-8'>
            <form className=' space-y-4' onSubmit={handleSubmitStudent} autoComplete='off'>
              <div className=' space-y-7'>
                <div className='presonal_detail--section space-y-4'>
                  <div className="grid grid-cols-3 gap-4">
                    {REFUND_DETAILS.map((personal_input, idx) => {
                      const { name } = personal_input
                      return <FormElement
                        key={idx}
                        {...personal_input}
                        value={ledgerEntry[name]}
                        onChange={handleSetFormData}
                        optionValue={
                          personal_input.optionValue ? personal_input.optionValue :
                            name === 'year_id' ? optionsArray.yearOptions
                              : name === 'prefix_id' ? optionsArray.prefixOptions
                                : optionsArray.standardTypeOptions}
                      />
                    })}
                    <div className='basis-[33.33%]' style={{ flex: '1 0 21%' }}>
                      <label htmlFor="standardType" className='label'>Terms</label>
                      {/* <Select
                        isMulti
                        options={optionsArray.termOptions}
                        onChange={handleTermChange}
                        value={data?.termsSelected}
                      /> */}
                      <p className='input font-semibold'>{data?.quarter}</p>
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  {handlePostData.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                </div>
                {error && <Alert onClose={() => setError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}> Please try again later</Alert>}
                {success && <Alert onClose={() => setSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Entry Edited Succesfully</Alert>}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditEntry