const IS_REQUIRED = true

export const REFUND_DETAILS = [
  {
    name: 'payment_mode',
    placement: 'Select Mode of Payment',
    label: 'Mode of Payment',
    type: 'text',
    element: 'select',
    required: IS_REQUIRED,
    errorMessage: "This Field Cannot Be Empty !",
    optionValue: [
      {
        name: 'Cash',
        value: "cash",
      },
      {
        name: 'Cheque',
        value: "cheque",
      }
    ]
  },
  {
    name: 'payment_date',
    placeholder: 'Enter Payment Date',
    label: 'Payment Date',
    type: 'date',
    element: 'input',
    required: IS_REQUIRED,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'amount',
    placeholder: 'Enter Amount',
    label: 'Amount',
    type: 'number',
    element: 'input',
    required: IS_REQUIRED,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'remark',
    placeholder: 'Enter Remark',
    label: 'Remark',
    type: 'text',
    element: 'input',
    required: false,
    errorMessage: 'This Field Cannot Be Empty !',
  },
]
