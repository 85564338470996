import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { PlusIcon } from '@heroicons/react/solid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddParticular from './AddParticular';
import EditParticular from './EditParticular';
import useGetData from './useGetData';
import DeleteParticular from './DeleteParticular';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import Pagination from '../../components/paginate';
import { Alert, Snackbar } from '@mui/material';

function Particulars() {
    const {handleGetDataList,getFormData,setGetFormData} = useGetData('getParticularList')
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [dataId, setDataId] = useState('')
    const [singleDataObject,setSingleDataObject] = useState({})
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const handleAddData = (e) => {
        setOpenCreateModal(true)
    }
    const handleEditModal = (e) => {
        e.stopPropagation();
        let element = e.currentTarget
        let id = element.getAttribute('data-id')
        let action = element.getAttribute('data-action')
        setDataId(id)
        if (action === 'edit') {
            let dataObject = handleGetDataList?.data?.data.find(x => x.id === id)
            setSingleDataObject(dataObject);
            setOpenEditModal(true)
        }else{
            setOpenDeleteModal(true)
        }
    }
    const handlePageClick = (val) => {
        let data = {
            ...getFormData,
            pageNo: val.selected
        }
        setGetFormData(data)
    }
    return (
        <>
            <Layout>
                <div className='space-y-4'>
                    <div className=' text-right'>
                        <button className='primaryButton' data-type="Standard" onClick={handleAddData}>
                            <div className='flex items-center space-x-3'>
                                <PlusIcon className='w-6' />
                                <span>Add Particular</span>
                            </div>
                        </button>
                    </div>
                    {handleGetDataList?.isLoading ? <Spinner /> 
                        : handleGetDataList?.status === "error" ? <Errors errorObject={handleGetDataList?.error} inline /> :
                        <>
                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Particulars
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {handleGetDataList?.data?.data.map((standardList, idx) => {
                                            const { name, id } = standardList
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{name}</span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <div className='flex items-center justify-center'>
                                                        <div data-type="Standard" data-action='edit' data-id={id} onClick={handleEditModal}>
                                                            <IconButton>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </div>
                                                        <div data-type="Standard" data-action='delete' data-id={id} onClick={handleEditModal}>
                                                            <IconButton>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className=" my-7">
                                <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetDataList?.data.total_count / getFormData.limit)} pageNo={handleGetDataList?.data?.page_no} />
                            </div>
                        </>
                     }
                </div>
            </Layout>
            {openCreateModal && <AddParticular open={openCreateModal} handleClose={() => setOpenCreateModal(false)} />}
            {openEditModal && <EditParticular data={singleDataObject} id={dataId} open={openEditModal} handleClose={() => setOpenEditModal(false)} />}
            {openDeleteModal && <DeleteParticular id={dataId} onDelete={() => setIsDeletedSuccess(true)} open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}/>}
            <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Subject Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default Particulars