import { Alert, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getList } from '../../api/collections'
import FormElement from '../../components/Form/FormElement'
import Layout from '../../components/Layout'
import usePostData from '../../hooks/usePostData'
import { useGetData } from '../../hooks/useGetData'
import Cookies from 'universal-cookie';
import { decode } from 'base-64'
import { FEES } from '../../config'

const optionsState = {
  divisionOptions: [],
  standardOptions: [],
  yearOptions: [],
}

const defaultOptions = {
  standard_id: '',
  year_id: '',
  division_id: ''
}


const cookies = new Cookies
const RollNumbers = () => {
  const userRole = decode(cookies.get("_role_token"));
  const [optionsArray, setOptionsArray] = useState(optionsState)
  const [searchFilter, setSearchFilter] = useState(defaultOptions)
  const [loading, setLoading] = useState(false)

  const { handlePostData, error, success, onError, onSuccess } = usePostData({ queryName: "", url: "rearrangeRollNo" })

  const handleGenerateForm = (e) => {
    e.preventDefault()
    handlePostData.mutate(searchFilter)
  }

  const handleSetFromData = (e) => {
    e.preventDefault();
    let value = e.target.value
    let data = {
      ...searchFilter,
      [e.target.name]: value
    }
    setSearchFilter(data)
  }

  useEffect(() => {
    const typeList = ["Standard", "Section", "Year"]

    typeList.forEach((type) => {
      fetchOptionData(type)
    })

  }, [])

  const fetchOptionData = (type) => {
    getList(type)
      .then(res => {
        if (type === "Standard") {
          setOptionsArray(prevState => ({
            ...prevState,
            standardOptions: res.data
          }));
        } else if (type === "Section") {
          setOptionsArray(prevState => ({
            ...prevState,
            divisionOptions: res.data
          }));
        } else if (type === "Year") {
          let tempId = res.data.filter((i) => i.current_year === "1")
          setOptionsArray(prevState => ({
            ...prevState,
            yearOptions: res.data
          }));
          setSearchFilter({ ...searchFilter, year_id: tempId[0]?.id })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const { handleGetPostList } = useGetData('generateFeeBookNo', {
    onSuccess: (res) => {
      alert('Fee Book Has Been Generated')
    }
  })

  const handleGenerateFeebookNo = () => {
    if (searchFilter['year_id'] && searchFilter['standard_id']) {
      handleGetPostList.mutate({
        join_year: searchFilter['year_id'],
        class_std: searchFilter['standard_id']
      })
    } else {
      alert('Standard / Division / Year field is required')
    }
  }

  return (
    <Layout>
      <div className='mt-4'>
        <h1 className='heading'>Assign {userRole === FEES ? 'Fee book' : 'Roll'}  Numbers</h1>
        <form onSubmit={handleGenerateForm}>
          <div className='grid grid-cols-5 gap-4'>
            <FormElement
              label={'Standard'}
              name={`standard_id`}
              element="select"
              optionValue={optionsArray['standardOptions']}
              value={searchFilter['standard_id']}
              onChange={handleSetFromData}
              required={true}
            />
            {/* <FormElement
              label={'Division'}
              name={`division_id`}
              element="select"
              optionValue={optionsArray['divisionOptions']}
              value={searchFilter['division_id']}
              onChange={handleSetFromData}
              required={true}
            /> */}
            <FormElement
              label={'Year'}
              name={`year_id`}
              element="select"
              optionValue={optionsArray['yearOptions']}
              value={searchFilter['year_id']}
              onChange={handleSetFromData}
              required={true}
            />
            {userRole !== FEES ? 
              <div className="button self-end mb-[1px]">
                <button className='primaryButton flex text-center items-center justify-center disabled:cursor-not-allowed text-xs w-full' disabled={handlePostData.isLoading}> <span>Generate Roll Numbers</span> {handlePostData.isLoading ?
                  <div className='ml-4'>
                    <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div> :
                  null}</button>
              </div> 
              : 
            <div className="button self-end mb-[1px]">
              <button type='button' onClick={handleGenerateFeebookNo} className='primaryButton w-full flex items-center justify-center disabled:cursor-not-allowed text-xs' disabled={handleGetPostList.isLoading}><span>Generate Fee Book</span> {handleGetPostList.isLoading ?
                <div className='ml-4'>
                  <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div> :
                null}</button>
            </div>
}
          </div>
        </form>
        <Snackbar open={success} autoHideDuration={6000} onClose={() => onSuccess(false)}>
          <Alert onClose={() => onSuccess(false)} severity="success" sx={{ width: '100%' }}>
            This is a success message!
          </Alert>
        </Snackbar>
        <Snackbar open={error} autoHideDuration={6000} onClose={() => onError(false)}>
          <Alert onClose={() => onError(false)} severity="error" sx={{ width: '100%' }}>
            {handlePostData?.error?.response?.data.message}
          </Alert>
        </Snackbar>
        {/* {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%', background: "" }}>{handlePostData?.error?.response?.data.message}</Alert>} */}
        {/* {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Roll Numbers Created Succesfully</Alert>} */}
      </div>
    </Layout>
  )
}

export default RollNumbers