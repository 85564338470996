import { ChevronLeftIcon, PencilIcon } from '@heroicons/react/solid'
import { IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import useFetchList from '../../hooks/useFetchList';
import usePostData from '../../hooks/usePostData';
import FormElement from '../../components/Form/FormElement';


const MarksEntryStudentMarks = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState([])
  const { getFormData, setGetFormData, handlePostData, error, success, onError, onSuccess } = usePostData({queryName: "marksSchemeList", url: "getMarksGradeDetails", onSuccessCb: (res) => {marksSuccessData(res)}})
  const { handlePostData: handleSchemeData} = usePostData({queryName: "marksSchemeList", url: "createStudentsMarks", onSuccessCb: () => {}})

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem('marksEntryMarksScheme'));
    let temp = []
    localData.map((it) => {
      let item;
      if(it.grade){
        item = {...it, edit: false, is_new: false}
        temp.push(item)
      }else {
        item = {...it, edit: false, grade: "", is_new: true, pass_status: "", remarks: ""}
        temp.push(item)
      }
    })
    setFormData(temp)
  }, [])

  const marksSuccessData = (res) => {
    const temp = [...formData]
    console.log(res.data)
    let idx = temp.findIndex(e => parseInt(e.scheme_id) === parseInt(res.data.scheme_id))
    temp[idx].grade = res.data.grade
    temp[idx].pass_status = res.data.pass_status
    setFormData(temp)
  }

  const onMarksChange = (e,id) => {
    const temp = [...formData]
    let idx = temp.findIndex(it => parseInt(it.scheme_id) === parseInt(id))
    temp[idx].marks = e.target.value
    setFormData(temp)
    let data = JSON.parse(localStorage.getItem('marksEntryQuery'))
    handlePostData.mutate(({...data,marks: e.target.value,scheme_id: temp[idx].scheme_id }))
  }

  const onSchemeSave = (e,id) => {
    e.preventDefault()
    const temp = [...formData]
    
    let schemeData = temp.find(it => parseInt(it.scheme_id) === parseInt(id))
    let queryData = JSON.parse(localStorage.getItem('marksEntryQuery'))
    let form = {
      ...schemeData,
      ...queryData
    }
    handleSchemeData.mutate(form)
  }

  const handleRemarksChange = (e,id) => {
    const temp = [...formData]
    let idx = temp.findIndex(it => parseInt(it.scheme_id) === parseInt(id))
    temp[idx].remarks = e.target.value;
    setFormData(temp)
  }

  const onEnterMarks = (e,id) => {
    e.preventDefault()
    let temp = [...formData]
    let idx = temp.findIndex(it => parseInt(it.scheme_id) === parseInt(id))
    temp[idx].edit = true;
    temp[idx].marks = 0;
    temp[idx].is_new = false
    setFormData(temp)
  }

  return (
    <Layout>
      <div className='mb-10'>
        <div className='flex items-center mt-8'>
            <div>
              <button onClick={() => {navigate('/marks-entry/students')}} className='flex items-center'>
                  <ChevronLeftIcon className='w-6' />
                  <span className='text-lg underline'>Marks Entry / Subject / Students</span>
              </button>
            </div>
        </div>
      </div>
      <div className='mb-6'>
        <h1 className='font-semibold text-2xl '>Enter Marks</h1>
      </div>
      <div>
        {
          formData?.map((it) => {
            const {scheme_id:id,marks_scheme,marks,outoff_marks,min_marks,grade,pass_status, is_new, remarks} = it
            return (
              <div className='p-6 border mb-4 bg-white drop-shadow rounded-md'>
                <div className='flex justify-between'>
                  <h1 className='font-semibold text-lg'>{marks_scheme}</h1>
                  <div className='flex'>
                    <div className='flex mr-4'>
                      <h1 className='font-semibold'>outoff_marks:</h1>
                      <h1 className='ml-4'>{outoff_marks}</h1>
                    </div>
                    <div className='flex '>
                      <h1 className='font-semibold'>min_marks:</h1>
                      <h1 className='ml-4'>{min_marks}</h1>
                    </div>
                  </div>
                </div>
                <div className='flex mt-4 justify-between  mb-4'>
                  <h1>Marks Gained</h1>
                  {!is_new ? (<div className='flex'>
                    <div className='w-outoff_marks flex'>
                      <input type="text" value={marks} onChange={(e) => onMarksChange(e,id)}  className={`input w-14 text-right`}/>
                    </div>
                  </div>) : (
                    <div className='flex justify-end'>
                      <button className='primaryButton flex items-center disabled:cursor-not-allowed' onClick={(e) => onEnterMarks(e,id)}>Enter Marks</button>
                    </div>
                  )}
                </div>
                {/* {
                  !is_new && !edit && (
                    <div className='flex justify-end'>
                      <button className='primaryButton flex items-center disabled:cursor-not-allowed' onClick={(e) => onEditMarks(e,id)}>Edit Marks</button>
                    </div>
                  )
                } */}
                {/* {
                  !is_new && edit && (
                    <div className='flex justify-end'>
                      <button className='primaryButton flex items-center disabled:cursor-not-allowed' onClick={(e) => onSaveMarks(e,id)}>Save Marks</button>
                    </div>
                  )
                } */}
                <div className='flex mt-4 justify-between  mb-4'>
                  <h1>Grade</h1>
                  <h1>
                    {grade}
                  </h1>
                </div>
                <div className='flex mt-4 justify-between  mb-4'>
                  <h1>Result Status</h1>
                  <h1>
                    {pass_status}
                  </h1>
                </div>
                <FormElement 
                  label='Remarks'
                  element={'input'}
                  placeholder={`Enter Marks Scheme`}
                  name={'remarks'}
                  required={true}
                  errorMessage='This Field Cannot Be Empty !'
                  onChange={(e) => handleRemarksChange(e,id)}
                  value={remarks}
                />
                {
                  !is_new && (
                    <div className='flex justify-end mt-4'>
                      <button className='primaryButton flex items-center disabled:cursor-not-allowed' onClick={(e) => onSchemeSave(e,id)}>Save Marks</button>
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </div>
    </Layout>
  )
}

export default MarksEntryStudentMarks