import React from 'react'
import spinner from '../../assets/images/spinner.gif'

function Spinner({ className }) {
    return (
        <div className={`${className ? className : 'w-14 h-14'} mx-auto  my-8`}>
            <img src={spinner} alt="spinner" className='w-full h-full' />
        </div>

    )
}
export default Spinner
