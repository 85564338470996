import { useState } from 'react'
import axios from '../../api/axios'
import { useMutation } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from 'react-router-dom'
import { decode, encode } from 'base-64'
import logo from '../../assets/images/panday_logo_colour.png'
import { UserContext } from '../../App';
import { ADMIN, FEES, TEACHER } from '../../config';

const cookies = new Cookies
function Login() {
  let token = cookies.get("_pn_token");
  let role = cookies.get('_role_token')
  let navigate = useNavigate()
  const initialState = {
    email: '',
    password: '',
  }
  const [userData, setuserData] = useState(initialState)
  const [error, setError] = useState(false)

  const handleAuthFormInput = (e) => {
    let value = e.target.value
    let data = {
      ...userData,
      [e.target.name]: value
    }
    setuserData(data)
  }
  const handleAuth = useMutation((data) => handleFormSubmit(data), {
    onSuccess: (data) => {
      cookies.set("_pn_token", data.data.token, { path: '/' })
      cookies.set("_role_token", encode(data.data.role), { path: '/' })
      cookies.set("_username", data.data.name, { path: '/' })
      cookies.set("_teacher_id", data.data.user_id, { path: '/' })
      cookies.set("_sub_role", JSON.stringify(data.data.sub_role), { path: '/' })
      if (data.data.role === ADMIN) {
        navigate("/dashboard", { replace: true });
      } else if (data.data.role === FEES) {
        navigate("/dashboard", { replace: true });
      } else if (data.data.role === TEACHER ) {
        navigate("/students", { replace: true });
      }
    },
    onError: () => {
      setError(true);
    }
  })

  const handleFormSubmit = async (getFormData) => {
    let fd = new FormData()
    for (var key in getFormData) {
      fd.append(key, getFormData[key]);
    }
    const response = await axios({
      method: 'POST',
      url: '/login',
      data: fd,
      // withCredentials: true, 
    })
    return response
  }

  const handleAuthSubmit = (e) => {
    e.preventDefault()
    handleAuth.mutate(userData)
  }

  if (token) {
    if (decode(role) === 'teachers' || decode(role) === 'class_teacher') {
      return <Navigate to="/students" replace />
    }
    return <Navigate to="/dashboard" replace />
  }
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
        <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
          <div className="bg-white rounded-2xl shadow-2xl flex w-2/3 max-w-4xl">
            <div className="w-3/5 py-20 px-9">
              <p className=" text-3xl font-semibold mb-4 roboto">Login</p>
              <form className=" space-y-4" onSubmit={handleAuthSubmit} autoComplete='off'>
                <div>
                  <label htmlFor="email" className=" block text-xl roboto">Email</label>
                  <input type="text" required
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="email" placeholder="Enter your email here" autoComplete='username' value={userData.email} onChange={handleAuthFormInput}></input>
                </div>
                <div>
                  <label htmlFor="password" className=" block text-xl roboto">Password</label>
                  <input type="password" required value={userData.password} onChange={handleAuthFormInput}
                    className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                    name="password" placeholder="Enter your password here" autoComplete='current-password'></input>
                </div>
                {error && <div className='text-red-700 text-center'>
                  <small>Email or password is incorrect</small>
                </div>}
                {handleAuth.isLoading ? <Spinner /> : <div className="button">
                  <button type="submit" className=" w-full py-2 bg-blue-500 text-white hover:bg-blue-600 rounded roboto">Login</button>
                </div>}
              </form>
            </div>
            <div className="w-2/5  text-white rounded-r-2xl rounded-br-2xl flex flex-col justify-center px-12 text-center bg-blue-500">
              <p className=" roboto text-sl font-semibold text-[color:var(--color1)]">Welcome To <span className="font-extrabold">Panday School Dashboard</span></p>
              <div className=" flex justify-center mt-8">
                <img src={logo} alt="logo" className=' h-40' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Login