import { Navigate, Outlet, redirect, useNavigate } from 'react-router-dom'
import Cookies from "universal-cookie";
import { decode } from 'base-64'
import { ADMIN, FEES, TEACHER } from '../config';
const cookies = new Cookies();

function ProtectedRoutes({ children, ...restOfProps }) {
    let token = cookies.get("_pn_token");
    const navigate = useNavigate()
    const { accessTo } = restOfProps
    if (!token) {
        return <Navigate to="/" replace />;
    } else {
        let userRole = decode(cookies.get("_role_token"));
        if (accessTo.includes(userRole)) {
            return children;
        } else {
            if (userRole === ADMIN) {
                return <Navigate to="/dashboard" replace />;
            } else if (userRole === FEES) {
                return <Navigate to="/dashboard" replace />;
            } else if (userRole === TEACHER) {
                return <Navigate to="/students" replace />;
            }
        }

    }
}

export default ProtectedRoutes
