import React, { useEffect, useState } from 'react'
import { getList } from '../../api/collections';
import FormElement from '../../components/Form/FormElement';
import Layout from '../../components/Layout'
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useFetchList from '../../hooks/useFetchList';
import useGetData from '../Standard/useGetData';
import Spinner from '../../components/spinner';
import usePostData from '../../hooks/usePostData';
import MarksEntrySubject from './MarksEntrySubject';
import { useNavigate, useSearchParams } from 'react-router-dom';

const standardInitialState = {
  limit: 100,
  pageNo: 0,
  type: 'Standard'
}

const initialState = {
  year_id: '',
  term_id: ''
}


const MarksEntry = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialSearchParams = {
    limit: 10,
    page_no: searchParams.get('pageNo') || 0,
    term_id: searchParams.get('term_id') ||'',
    year_id: searchParams.get('year_id') || '',
  }
  const [loading, setLoading] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [searchFilter,setSearchFilter] = useState(initialSearchParams);
  const [yearOptionsData, setYearOptionsData] = useState(null);
  const [termsOptionsData,setTermsOptionData] = useState(null)
  const {handlePostData} = usePostData({queryName: 'standardList',url:'getSubjectTeacherStandardList'})

  const handleSearch = (e) => {
    e.preventDefault()
    searchParams.set('term_id',searchFilter.term_id)
    searchParams.set('year_id',searchFilter.year_id)
    setSearchParams(searchParams)
    fetchData()
  }

  const fetchData = () => {
    fetchTableData(searchFilter)
    setFetched(true)
  }

  const fetchOptionsData = () => {
		getList('Year')
			.then(res => {
        let tempId = res.data.filter((i) => i.current_year === "1")
				setYearOptionsData(res.data);
        setSearchFilter({ ...searchFilter, year_id: tempId[0]?.id })
			})
    getList('SchoolTerm')
			.then(res => {
        let data = []
        res.data.forEach((it) => {data.push({...it,value: it.id,name: it.name})})
				setTermsOptionData(data);
			})
	}

  useEffect(() => {
		fetchOptionsData();
    if(searchFilter['term_id'] && searchFilter['year_id']){
      fetchData()
    }
	}, [])

  const handleSetFromData = (e) => {
    e.preventDefault();
    let value = e.target.value
    let data = {
        ...searchFilter,
        [e.target.name]: value
    }
    setSearchFilter(data)
    
  }

  const fetchTableData = (data) => {
    handlePostData.mutate(data)
  }

  return (
    <>
      <Layout>
      <div className='flex items-center space-x-4  '>
          <div>
              <h1 className='heading'>Standard List</h1>
          </div>
          </div>
          <div className='mt-4'>
            <form onSubmit={handleSearch}>
              <div className='grid grid-cols-4 gap-4'>
                <FormElement
                    label={'Year'}
                    name={`year_id`}
                    element="select"
                    optionValue={yearOptionsData}
                    value={searchFilter.year_id}
                    onChange={handleSetFromData}
                />
                <FormElement
                    label={'Term'}
                    name={`term_id`}
                    element="select"
                    optionValue={termsOptionsData}
                    value={searchFilter.term_id}
                    onChange={handleSetFromData}
                />
                <div className="button self-end mb-[1px]">
                  <button className='primaryButton flex items-center disabled:cursor-not-allowed' disabled={loading}>Get Standard List {loading ? 
                    <div className='ml-4'>
                      <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg> 
                    </div>:
                    null}</button>
                </div>
              </div>
            </form>
          </div>
        <div className='mt-4 pb-10'>
        {
          handlePostData.isLoading ? <Spinner /> : (
            fetched &&
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-6">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-left">
                            <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Standard
                            </th>
                            <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-right">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {handlePostData?.data?.data.map((standardData, idx) => {
                            const { name, standard_id } = standardData
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 firstName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {name}
                                    </span>
                                </td>
                                <td className='border-dashed border-t border-gray-200 phoneNumber text-right'>
                                  <div className='px-6 py-2'>
                                    <IconButton onClick={() => {navigate(`/marks-entry/subject?std=${name}&year=${yearOptionsData?.filter((y) => y.id === searchFilter['year_id'])[0]?.name}`); localStorage.setItem("marksEntryQuery",JSON.stringify({standard_id:standard_id, year_id: searchFilter.year_id, term_id: searchFilter.term_id}))}}>
                                        <EditIcon />
                                    </IconButton>
                                  </div>
                                </td>
                            </tr>
                        })}
                        {
                          handlePostData?.data?.data?.length === 0 && (
                            <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                              <td className="border-dashed border-t border-gray-200 text-center py-3" colSpan={3}>No Standard Mapped...</td>
                            </tr>
                          )
                        }
                    </tbody>
                </table>
            </div>
          )
        } 
        </div>
      </Layout>
    </>
  )
}

export default MarksEntry