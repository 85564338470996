import { Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner';
import useGetFeeBook from './useGetFeeBook';
import EditFeeBookList from './EditFeeBookList';
import Errors from '../errors';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditFeeBook({ open, handleClose, feeBookId, selectedFeeBookData }) {
    const { standard, year } = selectedFeeBookData
    const initialState = {
        type: 'SelectedFeeBook',
        fee_book_id: feeBookId
    }
    const { handleGetDataList } = useGetFeeBook(initialState, 'feeBookListing')
    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Fee Book  <span className="font-normal">[{standard + " " + year}]</span></h1>
                            {!handleGetDataList.data?.term[0].is_edit && <p className=' text-red-600 font-semibold text-sm -mt-4'>*This Data Cannot be edited</p>}
                        </div>
                    </div>
                    {handleGetDataList.isLoading
                        ? <Spinner />
                        : handleGetDataList?.status === "error" ? <h1 className='text-center text-2xl text-red-600 mt-5 font-semibold'>{handleGetDataList?.error?.response?.data?.message}</h1>
                            : <EditFeeBookList data={handleGetDataList.data?.term} feeBookId={feeBookId} />}
                </div>
            </Dialog>
        </>
    )
}

export default EditFeeBook