import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { PlusIcon } from '@heroicons/react/solid';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import Pagination from '../../components/paginate';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useFetchList from '../../hooks/useFetchList';
import AddSubject from './AddSubject';
import EditSubject from './EditSubject';
import DeleteItem from '../../components/deleteItem';
import { Alert, Snackbar } from '@mui/material';
import Cookies from 'universal-cookie';
import { decode } from 'base-64';

const cookies = new Cookies();
function Subjects() {
    const initialState = {
        limit: 10,
        pageNo: 0,
        search_query: ''
    }
    let userRole = decode(cookies.get("_role_token"));
    const { handleGetData, paramsObject, setGetListParams } = useFetchList('subjectMasterList', initialState)
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const handleAddData = (e) => {
        setOpenCreateModal(true)
    }
    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (data) => {
        setEditData(data)
        setIsOpenEditModal(true)
    }
    const [dataId, setDataId] = useState(0)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const [isDeletedError, setIsDeletedError] = useState(false)
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setDataId(id)
    }
    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo: val.selected
        }
        setGetListParams(data)
    }
    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            [e.target.name]: value
        }
        setGetListParams(data)
    }


    const handleCloseForm = () => {
        setOpenCreateModal(false)
        setIsOpenEditModal(false)

    }
    return (
        <>
            <Layout>
                <div className="grid grid-cols-1 divide-x">
                    <div className=' space-y-4 pr-4'>
                        <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
                            <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search Subject' />
                            <button className='primaryButton' data-type="Standard" onClick={handleAddData}>
                                <div className='flex items-center space-x-3'>
                                    <PlusIcon className='w-6' />
                                    <span>Add Subject</span>
                                </div>
                            </button>
                        </div>
                        {handleGetData?.isLoading ? <Spinner />
                            : handleGetData?.status === "error" ? <Errors errorObject={handleGetData?.error} inline /> :
                                <>
                                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Sr.no
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Subject Name
                                                    </th>
                                                    <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {handleGetData?.data.data.map((subjectData, idx) => {
                                                    const { subject_name, subject_id } = subjectData
                                                    return <tr key={idx}>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                                {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 firstName">
                                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                {subject_name}
                                                            </span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                            <div className='flex items-center justify-center'>
                                                                <div>
                                                                    <IconButton onClick={() => handleOpenEditModal(subjectData)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </div>
                                                                {
                                                                    userRole !== "teachers" ? (
                                                                        <div>
                                                                            <IconButton onClick={() => handleOpenDeleteModal(subject_id)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : <></>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" my-7">
                                        <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetData?.data.total_count / paramsObject.limit)} pageNo={handleGetData?.data?.page_no} />
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </Layout>
            {openCreateModal && <AddSubject open={openCreateModal} onClose={handleCloseForm} />}
            {isOpenEditModal && <EditSubject singleData={editData} open={isOpenEditModal} onClose={handleCloseForm} />}
            {openDeleteModal && <DeleteItem
                url={`/deleteSubject`}
                queryKey={'subjectMasterList'}
                onDelete={() => { setIsDeletedError(false); setIsDeletedSuccess(true) }}
                onError={() => { setIsDeletedError(true); setIsDeletedSuccess(false) }}
                open={openDeleteModal}
                deleteData={{subject_id: dataId}}
                onClose={() => setOpenDeleteModal(false)}
            />}
            <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Subject Deleted Succesfully
                </Alert>
            </Snackbar>
            <Snackbar open={isDeletedError} autoHideDuration={2000} onClose={() => setIsDeletedError(false)}>
                <Alert onClose={() => setIsDeletedError(false)} severity="error" sx={{ width: '100%' }}>
                    Something went wrong!!
                </Alert>
            </Snackbar>
        </>
    )
}

export default Subjects