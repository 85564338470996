import { Alert, Modal } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import FormElement from '../../components/Form/FormElement'
import { XIcon } from '@heroicons/react/solid'
import usePostData from '../../hooks/usePostData'
import Spinner from '../../components/spinner'


const ChangePassword = ({ open, onClose, teacherData }) => {
  const [password, setPassword] = useState('')
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false)
  const [changePasswordError, setChangePasswordError] = useState(false)
  const { handlePostData: changePassword } = usePostData({
    queryName: '',
    url: 'passwordReset',
    onSuccessCb: () => {
      setChangePasswordError(false)
      setChangePasswordSuccess(true)
      setTimeout(() => {
        setChangePasswordSuccess(false)
        onClose()
      }, 4000)
    },
    onErrorCb: () => {
      setChangePasswordError(true)
      setChangePasswordSuccess(false)
      setTimeout(() => {
        setChangePasswordError(false)
      }, 4000)
    }
  })

  const handleChangePasswordForm = (e) => {
    e.preventDefault()
    changePassword.mutate({
      password,
      teacher_id: teacherData.teacher_id
    })
  }

  const handleSetFromData = (e) => {
    let $this = e.target
    setPassword($this.value)
  }

  return (
    <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className='w-[30rem] bg-white top-[50%] -translate-y-1/2 center-the-div rounded p-6'>
        <div className=' mb-4'>
          <h2 className='text-xl font-bold capitalize'>Change Password</h2>
        </div>
        <form className=' space-y-4' onSubmit={handleChangePasswordForm} autoComplete='off'>
          <FormElement
            key={'password'}
            name='password'
            value={password}
            placeholder="Enter Password"
            label='Password'
            onChange={handleSetFromData}
            type='password'
          />
          <button type='submit' className='primaryButton flex items-center justify-center' >Submit {changePassword.isLoading && <Spinner className='w-6 h-6 mx-0 my-0 ml-2' />}</button>
        </form>
        {changePasswordSuccess && <Alert onClose={() => setChangePasswordSuccess(false)} className='mt-4 capitalize' severity="success" sx={{ width: '100%' }}>Password Updated Successfully.</Alert>}
        {changePasswordError && <Alert onClose={() => setChangePasswordError(false)} className='mt-4 capitalize' severity="error" sx={{ width: '100%' }}>Something went wrong!!</Alert>}
      </div>

    </Modal>
  )
}

export default ChangePassword