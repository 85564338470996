import React, { useEffect, useState } from 'react'
import { Alert, Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TEACHERS_DETAILS } from './teacherInputs';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import usePostData from '../../hooks/usePostData';
import Select from 'react-select';
import ChangePassword from './ChangePassword';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function EditTecher({ open, onClose, singleData, teacherTypeList, teacherTypeData }) {
  const [isChangePassModalOpen, setIsChangePassModalOpen] = useState(false)
  const { handlePostData, error, success, onError, onSuccess } = usePostData({
    queryName: 'teacherList', url: 'updateTeacher', onSuccessCb: () => {
      onClose()
    }
  })
  const [teacherData, setTeacherData] = useState(singleData)
  function isArrayofObjects(value) {
    if (!Array.isArray(value)) {
      return false;
    }
  
    for (let i = 0; i < value.length; i++) {
      if (typeof value[i] !== 'object' || value[i] === null || Array.isArray(value[i])) {
        return false;
      }
    }
  
    return true;
  }
  const handleSubmitTeacher = (e) => {
    e.preventDefault()
    const data = { ...teacherData }
    if(isArrayofObjects(data.teacher_type)){
      data.teacher_type = JSON.stringify(data.teacher_type.map((it) => it.value))
    }
    console.log(data.teacher_type);
    handlePostData.mutate(data)
  }
  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...teacherData,
      [e.target.name]: value
    }
    setTeacherData(data)
  }

  return (
    <>
      <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
        <div className='container_xxl mb-10'>
          <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
            <div>
              <IconButton onClick={onClose}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className='heading'>Edit Teacher</h1>
            </div>
          </div>
          <div className='flex justify-end h-max w-full'>
            <button className='primaryButton mt-4' onClick={() => setIsChangePassModalOpen(true)}>Change Password</button>
          </div>
          {
            handlePostData.isLoading ? <Spinner /> : (
              <div className='form-body py-4'>
                <form className=' space-y-4' onSubmit={handleSubmitTeacher} autoComplete='off'>
                  <div className=' space-y-7'>
                    <div className='presonal_detail--section space-y-4'>
                      <div className=''>
                        <h3 className=' uppercase text-xl font-semibold underline'>TEACHER DETAILS</h3>
                      </div>
                      <div className="grid grid-cols-3 gap-4">
                        {TEACHERS_DETAILS.map((personal_input, idx) => {
                          const { name } = personal_input
                          return <FormElement
                            key={idx}
                            {...personal_input}
                            value={teacherData[name]}
                            onChange={handleSetFormData}
                          />
                        })}
                        <div></div>
                        <div className='flex flex-col'>
                          <label htmlFor="teacherType" className='label'>Teacher Type <span className='mandatory'>*</span></label>
                          <Select
                            isMulti
                            options={teacherTypeList}
                            defaultValue={teacherTypeData}
                            onChange={(item) => setTeacherData({ ...teacherData, teacher_type: item })}
                            required={true}
                            className='input p-0'
                          />
                          {handlePostData?.error?.response?.data?.data?.teacher_type && <small className=" text-red-700 font-semibold mt-1">This Field Cannot Be Empty !</small>}
                        </div>
                        {/* <FormElement value={teacherData['username']} onChange={handleSetFormData} name='username' placeholder='Enter Username' label='Username' />
                    <FormElement value={teacherData['password']} onChange={handleSetFormData} name='password' placeholder='Enter Password' label='Password' /> */}
                      </div>
                    </div>
                    <div className='text-center'>
                      {handlePostData.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                    </div>
                    {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
                    {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Teacher Updated Succesfully</Alert>}
                  </div>
                </form>
              </div>
            )
          }
          <ChangePassword teacherData={singleData} open={isChangePassModalOpen} onClose={() => setIsChangePassModalOpen(false)} />
        </div>
      </Dialog>
    </>
  )
}

export default EditTecher