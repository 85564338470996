import { XIcon } from '@heroicons/react/solid';
import { Alert } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import useCreateParticular from './useCreateParticular';

function AddParticular({ open, handleClose }) {
    const initialState = {
        name: '',
    }
    const [formData, setFormData] = useState(initialState)
    const { setDataToServer, error, onError, success, onSuccess } = useCreateParticular('/createFeesParticular')
    const handleSetFromData = (e) => {
        let value = e.target.value
        let data = {
            ...formData,
            [e.target.name]: value
        }
        setFormData(data)
    }

    const handleSaveFromData = (e) => {
        e.preventDefault()
        setDataToServer.mutate(formData)
    }
    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Add Particular</h2>
                            <XIcon onClick={handleClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                            <FormElement
                                label={'Particular'}
                                element={'input'}
                                placeholder={`Enter Particular`}
                                name={'name'}
                                onChange={handleSetFromData}
                            />
                            {setDataToServer.isLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Save</button>
                                </div>}
                            {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.response?.data.message}</Alert>}
                            {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Particular Added Succesfully</Alert>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddParticular