import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/solid'
import { Dialog, IconButton, Slide } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MapMarksToSubject from './MapMarksToSubject';
import { getList } from '../../api/collections';
import FormElement from '../../components/Form/FormElement';
import usePostData from '../../hooks/usePostData';
import Spinner from '../../components/spinner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
  year_id: '',
  standard_id: ''
}

const EditSubjectMapping = ({ open, onClose, standardId, standard }) => {
  const [loading, setLoading] = useState(false)
  const [searchFilter, setSearchFilter] = useState(initialState);
  const [yearOptionsData, setYearOptionsData] = useState(null);
  const [isOpenMarkSchemeModal, setIsOpenMarkSchemeModal] = useState(false)
  const [editMarksSchemeData, setEditMarksSchemeData] = useState(null)
  const [termsOptionsList, setTermsOptionsList] = useState([])
  const [marksSchemeOptionsList, setMarksSchemeOptionsList] = useState([])
  const { handlePostData } = usePostData({ queryName: 'getStandardWiseSubjectList', url: 'getStandardWiseSubjectList' })

  const handleSetFromData = (e) => {
    e.preventDefault();
    let value = e.target.value
    let data = {
      ...searchFilter,
      [e.target.name]: value
    }
    setSearchFilter(data)
  }

  const fetchOptionsData = () => {
    getList('Year')
      .then(res => {
        setYearOptionsData(res.data);
      })
    getList("SchoolTerm")
      .then(res => {
        let data = []
        res.data?.map((it) => {
          return data.push({
            ...it,
            value: parseInt(it.id)
          })
        })
        setTermsOptionsList(data)
      })
    getList("MarksScheme")
      .then(res => {
        let data = []
        res.data?.map(it => {
          return data.push({
            ...it,
            value: parseInt(it.id)
          })
        })
        setMarksSchemeOptionsList(data)
      })
  }

  const fetchTableData = (data) => {
    handlePostData.mutate(data)
  }

  const handleSearch = (e) => {
    e.preventDefault()
    let data = {
      ...searchFilter,
      standard_id: standardId
    }
    fetchTableData(data)
  }

  useEffect(() => {
    fetchOptionsData();
  }, [])

  const handleOpenMarksSchemeModal = (data) => {
    setIsOpenMarkSchemeModal(true)
    setEditMarksSchemeData(data)
  }

  const handleCloseMarksSchemeModal = () => {
    setIsOpenMarkSchemeModal(false)
    setEditMarksSchemeData(null)
  }

  return (
    <>
      <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
        <div className='container_xxl mb-10'>
          <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
            <div>
              <IconButton onClick={onClose}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className='heading'>Add Subject Mapping <span className='font-normal'>[{standard}]</span></h1>
            </div>
          </div>
          <div className='mt-4'>
            <form onSubmit={handleSearch}>
              <div className='grid grid-cols-4 gap-4'>
                <FormElement
                  label={'Year'}
                  name={`year_id`}
                  element="select"
                  optionValue={yearOptionsData}
                  value={searchFilter.year}
                  onChange={handleSetFromData}
                />
                <div className="button self-end mb-[1px]">
                  <button className='primaryButton flex items-center disabled:cursor-not-allowed' disabled={loading}>Get Subject List {loading ?
                    <div className='ml-4'>
                      <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div> :
                    null}</button>
                </div>
              </div>
            </form>
          </div>
          {
            handlePostData.isLoading ? <Spinner /> : (
              handlePostData.isSuccess &&
              <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-8">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Subject Name
                      </th>
                      <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {handlePostData?.data?.subjectList.map((subjectData, idx) => {
                      const { subject_name, id } = subjectData
                      return <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200 userId">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {idx + 1}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                            {subject_name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                          <div className='flex items-center justify-center'>
                            <div>
                              <IconButton onClick={() => handleOpenMarksSchemeModal(subjectData)}>
                                <EditIcon />
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })
                    }
                    {
                      handlePostData?.data?.subjectList?.length === 0 && (
                        <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                          <td className="border-dashed border-t border-gray-200 text-center py-3" colSpan={3}>No Subjects Mapped...</td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            )
          }

        </div>
      </Dialog>
      {/* Add */}
      {isOpenMarkSchemeModal &&
        <MapMarksToSubject
          open={isOpenMarkSchemeModal}
          onClose={handleCloseMarksSchemeModal}
          data={editMarksSchemeData}
          standardId={standardId}
          year_id={searchFilter.year_id}
          fetchSubjectData={handleSearch}
          termsOptionsList={termsOptionsList}
          marksSchemeOptionsList={marksSchemeOptionsList}
        />}
    </>
  )
}

export default EditSubjectMapping