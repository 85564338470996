import { XIcon } from '@heroicons/react/solid'
import { Alert, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getList } from '../../api/collections'
import FormElement from '../../components/Form/FormElement'
import Spinner from '../../components/spinner'
import useFetchList from '../../hooks/useFetchList'
import usePostData from '../../hooks/usePostData'
import useGetData from '../Standard/useGetData'

const AddDetails = ({ open, onClose, teacherId, selected}) => {
  const standartInitialState = {
    limit: 50,
    pageNo: 0,
    type: 'Standard'
}
const sectionInitialState = {
    limit: 50,
    pageNo: 0,
    type: 'Section'
}
  const initialState = {
    standard_id: "",
    section_id: "",
    year_id: "",
    subject_id: ""
  }
  const [formData, setFormData] = useState(initialState)
  const [yearOptionsData,setYearOptionsData] = useState([])
  const getStandardList = useGetData(standartInitialState, 'getStandardList')
  const getSectionList = useGetData(sectionInitialState, 'getSectionList')
  const { handleGetData } = useFetchList('subjectList', initialState)
  const { handlePostData: handlePostSubject, error:subjectError, success:subjectSuccess, onError: onSubjectError, onSuccess: onSubjectSuccess } = usePostData({ queryName: 'teacherProfileDetails', url: 'teacherCreateSubjectMapping' })
  const { handlePostData: handlePostClass, error, success, onError, onSuccess} = usePostData({ queryName: 'teacherProfileDetails', url: 'teacherCreateClassMapping' })
  const handleSaveFromData = (e) => {
      e.preventDefault()
      if(selected === 'subject'){
        handlePostSubject.mutate({...formData,teacher_id: teacherId})
      }else{
        handlePostClass.mutate({...formData,teacher_id: teacherId,division_id: formData.section_id})
      }
  }

  const fetchOptionsData = () => {
		getList('Year')
			.then(res => {
				setYearOptionsData(res.data);
			})
	}

  useEffect(() => {
		fetchOptionsData();
	}, [])

  return (
    <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className=' w-[40%] bg-white center-the-div rounded'>
        <div className='px-8 divide-y'>
            <div className=''>
                <h2 className='heading capitalize'>Add {selected === "subject" ? "Subject" : "Class"}</h2>
                <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
            </div>
            <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                <div className=' basis-[33.33%]' style={{flex: '1 0 21%'}}>
					      	<label className='label'>Select Year: </label>
					      	<select value={formData.year_id} onChange={(e) => setFormData(prev => ({...prev,year_id: e.target.value}))} name="year_id" className='input'>
					      		<option value="">--Select--</option>
					      		{yearOptionsData?.map((year, idx) => {
					      			return <option key={idx} value={year.id}>{year.name}</option>
					      		})}
					      	</select>
					      </div>
                <div className=' basis-[33.33%]' style={{flex: '1 0 21%'}}>
                    <label htmlFor="standard_id" className='label'>Standard</label>
                    <select value={formData.standard_id} onChange={(e) => setFormData(prev => ({...prev,standard_id: e.target.value}))} name="standard_id" className='input'>
                        <option value="">--Select--</option>
                        {getStandardList?.handleGetDataList?.data?.data.map((standard_id, idx) => {
                            return <option key={idx} value={standard_id.id}>{standard_id.name}</option>
                        })}
                    </select>
                </div>
                {
                  selected === "subject" ? (
                    <div className=' basis-[33.33%]' style={{flex: '1 0 21%'}}>
                      <label htmlFor="subject_id" className='label'>Subject</label>
                      <select value={formData.subject_id} onChange={(e) => setFormData(prev => ({...prev,subject_id: e.target.value}))} name="subject_id" className='input'>
                          <option value="">--Select--</option>
                          {handleGetData?.data?.data.map((sub, idx) => {
                              return <option key={idx} value={sub.subject_id}>{sub.subject_name}</option>
                          })}
                      </select>
                    </div>
                  ):(
                  <div className=' basis-[33.33%]' style={{flex: '1 0 21%'}}>
                      <label htmlFor="section_id" className='label'>Section</label>
                      <select value={formData.section_id} onChange={(e) => setFormData(prev => ({...prev,section_id: e.target.value}))} name="section_id" className='input'>
                          <option value="">--Select--</option>
                          {getSectionList?.handleGetDataList?.data?.data.map((section_id, idx) => {
                              return <option key={idx} value={section_id.id}>{section_id.name}</option>
                          })}
                      </select>
                  </div>
                  )
                }
                {handlePostSubject.isLoading || handlePostClass.isLoading ?
                    <Spinner /> :
                    <div>
                        <button className='primaryButton w-full'>Add</button>
                    </div>}
                {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostClass?.error?.response?.data.message}</Alert>}
                {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Subject Added Succesfully</Alert>}
                {subjectError && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostSubject?.error?.response?.data.message}</Alert>}
                {subjectSuccess && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Subject Added Succesfully</Alert>}
            </form>
        </div>
      </div>
    </Modal>
  )
}

export default AddDetails