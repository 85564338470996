import React, { useState } from 'react'

const PrimaryResultView = ({data}) => {
    let resultData = {
        "data": {
            "subjectDataAcademic": [
                {
                    "subject_id": "1",
                    "subject_name": "English",
                    "isgrade": "0",
                    "scheme_status": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "5",
                            "scheme_name": "Conversation",
                            "marks": "9",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "6",
                            "scheme_name": "Reading",
                            "marks": "9",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "7",
                            "scheme_name": "Phonetics",
                            "marks": "10",
                            "grade": "Excellent",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "8",
                            "scheme_name": "Rhymes",
                            "marks": "8",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "9",
                            "scheme_name": "Writing Work",
                            "marks": "7",
                            "grade": "Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        }
                    ]
                },
                {
                    "subject_id": "5",
                    "subject_name": "Environmental Studies",
                    "isgrade": "0",
                    "scheme_status": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "10",
                            "scheme_name": "Picture Talk",
                            "marks": "8",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "11",
                            "scheme_name": "Personal Hygiene",
                            "marks": "7",
                            "grade": "Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "12",
                            "scheme_name": "Good Manners",
                            "marks": "8",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "13",
                            "scheme_name": "General Knowledge",
                            "marks": "18",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "20"
                        }
                    ]
                },
                {
                    "subject_id": "24",
                    "subject_name": "Number Work",
                    "isgrade": "0",
                    "scheme_status": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "14",
                            "scheme_name": "Recognition of Numbers",
                            "marks": "10",
                            "grade": "Excellent",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "15",
                            "scheme_name": "Written Work",
                            "marks": "10",
                            "grade": "Excellent",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "16",
                            "scheme_name": "Mathematical Operations",
                            "marks": "10",
                            "grade": "Excellent",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "17",
                            "scheme_name": "Oral Work",
                            "marks": "9",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "18",
                            "scheme_name": "Concept and value",
                            "marks": "9",
                            "grade": "Very Good",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        }
                    ]
                },
                {
                    "subject_id": "25",
                    "subject_name": "Drawing",
                    "isgrade": "1",
                    "scheme_status": "0",
                    "grade": "Excellent"
                },
                {
                    "subject_id": "26",
                    "subject_name": "Colouring",
                    "isgrade": "1",
                    "scheme_status": "0",
                    "grade": "Excellent"
                },
                {
                    "subject_id": "27",
                    "subject_name": "Craft",
                    "isgrade": "1",
                    "scheme_status": "0",
                    "grade": "Excellent"
                },
                {
                    "subject_id": "28",
                    "subject_name": "Singing",
                    "isgrade": "1",
                    "scheme_status": "0",
                    "grade": "Excellent"
                },
                {
                    "subject_id": "29",
                    "subject_name": "Games and Free Play",
                    "isgrade": "1",
                    "scheme_status": "0",
                    "grade": "Excellent"
                }
            ],
            "subjectDataPersonal": [
                {
                    "subject_id": "30",
                    "subject_name": "Work Habits",
                    "isgrade": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "21",
                            "scheme_name": "Punctuality",
                            "marks": "10",
                            "grade": "A",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "22",
                            "scheme_name": "Completes Work on Time",
                            "marks": "10",
                            "grade": "A",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "23",
                            "scheme_name": "Work Independently",
                            "marks": "10",
                            "grade": "A",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        }
                    ]
                },
                {
                    "subject_id": "31",
                    "subject_name": "Personal Traits",
                    "isgrade": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "24",
                            "scheme_name": "Discipline",
                            "marks": "10",
                            "grade": "A",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "25",
                            "scheme_name": "Personal Cleanliness",
                            "marks": "10",
                            "grade": "S",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        }
                    ]
                },
                {
                    "subject_id": "32",
                    "subject_name": "Social Traits",
                    "isgrade": "1",
                    "marksScheme": [
                        {
                            "scheme_id": "26",
                            "scheme_name": "Mixed with her Peers",
                            "marks": "10",
                            "grade": "R",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        },
                        {
                            "scheme_id": "27",
                            "scheme_name": "Participates in Activities",
                            "marks": "10",
                            "grade": "A",
                            "pass_status": "Pass",
                            "outoff_marks": "10"
                        }
                    ]
                }
            ],
            "teacher_remarks": "",
            "headmaster_remarks": "",
            "promoted_at": "",
            "school_open_at": ""
        }
    }
    const [primaryResultData, setPrimaryResultData] = useState(data)
    return (
        <div className='mt-4'>
            <div>
                <h1 className='text-3xl text-center font-semibold text-gray-700 pb-4'>Academic</h1>
                <div>
                    {/* {console.log(primaryResultData)} */}
                    <div className='grid grid-cols-3 gap-8'>
                        {primaryResultData.data.subjectDataAcademic.map((academicData, idx) => {
                            const { marksScheme, subject_name,grade } = academicData
                            return <div key={idx}>
                                <div className='bg-[color:var(--primarycolor)] text-white px-5 py-2 rounded-md mb-4 flex items-center justify-between relative z-20'>
                                    <h1 className='font-semibold'>{subject_name}</h1>
                                    <p className='text-sm font-semibold'>{grade}</p>
                                </div>
                                {marksScheme &&
                                    <div className=' ml-8'>
                                        <ul>
                                            <li className=' space-y-4'>
                                                {marksScheme.map((mark, marksIdx) => {
                                                    const {scheme_name,marks,grade,remarks, outoff_marks,pass_status,result_display_type} = mark
                                                    return <div key={marksIdx} className={`flex item-center py-2 px-5 bg-gray-200 sub-subject relative rounded-md justify-between ${pass_status === 'pass' ? 'bg-green-200 text-green-700' : pass_status === 'fail' ?  ' bg-red-200 text-red-700' : 'bg-yellow-200 text-yellow-700'}`}>
                                                        <p>{scheme_name}</p>
                                                        {/* <p className='text-sm font-semibold'>{marks} / {outoff_marks}</p> */}
                                                        {result_display_type.map((resultType,idx) => {
                                                            return <p key={idx} className='text-sm font-semibold'>{mark[resultType]}</p> 
                                                        })}
                                                    </div>
                                                })}
                                            </li>
                                        </ul>
                                    </div>}
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className=' my-4'>
                <h1 className='text-3xl text-center font-semibold text-gray-700 pb-4'>Personal</h1>
                <div>
                    <div className='grid grid-cols-3 gap-8'>
                        {primaryResultData.data.subjectDataPersonal.map((personalData, idx) => {
                            const { marksScheme, subject_name,grade } = personalData
                            return <div key={idx}>
                                <div className='bg-[color:var(--primarycolor)] text-white px-5 py-2 rounded-md mb-4 flex items-center justify-between relative z-20'>
                                    <h1 className='font-semibold'>{subject_name}</h1>
                                    <p className='text-sm font-semibold'>{grade}</p>
                                </div>
                                {marksScheme &&
                                    <div className=' ml-8'>
                                        <ul>
                                            <li className=' space-y-4'>
                                                {marksScheme.map((mark, marksIdx) => {
                                                    const {grade,pass_status,scheme_name} = mark
                                                    return <div key={marksIdx} className={`flex item-center py-2 px-5 bg-gray-200 sub-subject relative rounded-md justify-between ${pass_status === 'pass' ? 'bg-green-200 text-green-700' : ' bg-red-200 text-red-700'}`}>
                                                        <p className=''>{scheme_name}</p>
                                                        <p className=''>{grade}</p>
                                                    </div>
                                                })}
                                            </li>
                                        </ul>
                                    </div>}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrimaryResultView