import { XIcon } from '@heroicons/react/solid';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import FormElement from '../../components/Form/FormElement';
import useAddStandard from './useAddStandard';
import Spinner from '../../components/spinner';
import { useEffect } from 'react';
import { getList } from '../../api/collections';
import { Alert } from '@mui/material';

function AddStandard({ open, handleClose, type }) {
    const initialState = {
        name: '',
        type: '',
        standard_type_id_id: ''
    }
    const [formData, setFormData] = useState(initialState)
    const [standardOptions, setStandardOptions] = useState([]);
    const { setDataToServer, error, success, onError, onSuccess } = useAddStandard('/createStandardAndClass')
    const handleSetFromData = (e) => {
        let value = e.target.value
        let data = {
            ...formData,
            type: type,
            [e.target.name]: value
        }
        setFormData(data)
    }

    const handleSaveFromData = (e) => {
        e.preventDefault()
        setDataToServer.mutate(formData)
    }

    useEffect(() => {
        getList("StandardType")
            .then(res => {
                setStandardOptions(res.data);
            })
    }, []);

    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Add {type}</h2>
                            <XIcon onClick={handleClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                            <FormElement
                                label={type}
                                element={'input'}
                                placeholder={`Enter ${type}`}
                                name={'name'}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                                onChange={handleSetFromData}
                            />
                            <FormElement
                                label={"Select Standard Type"}
                                name={'standard_type_id'}
                                placeholder={'Select Standard Type'}
                                element={'select'}
                                optionValue={standardOptions}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                                onChange={handleSetFromData}
                            />
                            {setDataToServer.isLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Save</button>
                                </div>}
                            {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.response?.data.message}</Alert>}
                            {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Added Succesfully</Alert>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default AddStandard