import { Alert, Modal, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { XIcon, MinusCircleIcon } from '@heroicons/react/solid'
import FormElement from '../../components/Form/FormElement'
import { getList } from '../../api/collections'
import usePostData from '../../hooks/usePostData'
import Spinner from '../../components/spinner'
import DeleteStudent from '../students/DeleteStudent'

const initialData = {
  terms: []
}

const MapMarksToSubject = ({ open, onClose, data, standardId, year_id, fetchSubjectData, termsOptionsList, marksSchemeOptionsList }) => {
  const [formData, setFormData] = useState([])
  const [termList, setTermList] = useState(termsOptionsList)
  const [marksSchemeList, setMarksSchemeList] = useState(marksSchemeOptionsList)
  const [termSelected, setTermSelected] = useState("")
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const { handlePostData, error, success, onError, onSuccess, onSuccessCb } = usePostData({ queryName: 'getStandardWiseSubjectList', url: 'createSubjecManagement', onSuccessCb: () => { } })
  const { handlePostData: handleSchemeDelete } = usePostData({ queryName: 'getStandardWiseSubjectList', url: 'deleteSubjecManagementMarksScheme' })
  const { handlePostData: handleTermDelete } = usePostData({ queryName: 'getStandardWiseSubjectList', url: 'deleteSubjecManagementTerm' })

  useEffect(() => {
    if (data?.terms.length !== 0) {
      let tempData = data.terms?.map((it) => {
        const { term_id, term_name, scheme_data } = it
        return {
          ...it,
          id: term_id,
          scheme_status: true,
          name: term_name,
          marksScheme: scheme_data?.map((ms) => {
            return {
              ...ms,
              id: parseInt(ms.scheme_id),
              name: ms.scheme_name,
              max: ms.outoff_marks,
              min: ms.min_marks,
              isgrade: ms.isgrade,
              marksSchemeSelect: ""
            }
          })
        }
      })
      let termsData = []
      tempData.forEach((it) => {
        let diff = marksSchemeList?.filter(({ value }) => !it?.marksScheme?.some(({ id }) => parseInt(id) === parseInt(value)));
        let temp = { ...it, marksSchemeList: diff }
        termsData.push(temp)
      })
      let tempTermList = [...termsOptionsList]
      termsData.forEach((fd) => {
        let idx = tempTermList.findIndex(tl => parseInt(tl.id) === parseInt(fd.id))
        tempTermList.splice(idx, 1)
      })
      setFormData(termsData)
      setTermList(tempTermList)
    }
  }, [])

  const addTerm = () => {
    if (termSelected === "") return
    let tempFormTermList = [...formData]
    let item = termList.find(e => parseInt(e.value) === parseInt(termSelected))
    tempFormTermList.push({
      id: parseInt(termSelected),
      name: item.name,
      marksSchemeSelect: "",
      marksScheme: [],
      marksSchemeList: [...marksSchemeList]
    })
    setFormData(tempFormTermList)

    let tempSelectTermList = [...termList]
    tempSelectTermList.splice(tempSelectTermList.findIndex(e => e.value === parseInt(termSelected)), 1)
    setTermSelected("")
    setTermList(tempSelectTermList)

  }

  const addMarksScheme = (id) => {
    let temp = [...formData]
    let idx = temp.findIndex(e => parseInt(e.id) === parseInt(id))
    if (temp[idx].marksSchemeSelect === "") return
    let tempMarksSchemeList = [...temp[idx].marksSchemeList]
    let marksSchemeItem = tempMarksSchemeList.find(e => parseInt(e.value) === parseInt(temp[idx].marksSchemeSelect))
    if (!temp[idx].marksScheme) {
      temp[idx].marksScheme = [{
        id: parseInt(temp[idx].marksSchemeSelect),
        name: marksSchemeItem.name,
        max: 0,
        min: 0,
        isgrade: false
      }]
    } else {
      temp[idx].marksScheme.push({
        id: parseInt(temp[idx].marksSchemeSelect),
        name: marksSchemeItem.name,
        max: 0,
        min: 0,
        isgrade: false
      })
    }
    temp[idx].marksSchemeList.splice(tempMarksSchemeList.findIndex(e => parseInt(e.value) === parseInt(temp[idx].marksSchemeSelect)), 1)
    temp[idx].marksSchemeSelect = ""
    setFormData(temp)
  }

  const handleMarksSchemeSelectChange = (value, id) => {
    let temp = [...formData]
    let idx = temp.findIndex(e => parseInt(e.id) === parseInt(id))
    temp[idx].marksSchemeSelect = value
    setFormData(temp)
  }

  const handleMarksSchemeChange = (e, termId, marksId) => {
    e.preventDefault()
    let temp = [...formData]
    let termIdx = temp.findIndex(e => e.id === termId)

    let marksIdx = temp[termIdx].marksScheme.findIndex(e => parseInt(e.id) === parseInt(marksId))
    if (e.target.name === "min") {
      temp[termIdx].marksScheme[marksIdx].min = e.target.value
    } else {
      temp[termIdx].marksScheme[marksIdx].max = e.target.value
    }
    setFormData(temp)
  }

  const handleDeleteTerm = (e, id) => {
    let temp = [...formData]
    let term = temp.find(e => parseInt(e.id) === parseInt(id))
    if (term?.subject_map_id) {
      const deleteTermData = {
        standard_id: standardId,
        subject_id: data.subject_id,
        year_id,
        term_id: term.id
      }
      handleTermDelete.mutate(deleteTermData)
      fetchSubjectData(e)
    }
    let idx = temp.findIndex(e => parseInt(e.id) === parseInt(id))
    temp.splice(idx, 1)
    setFormData(temp)

    let tempTermList = [...termList]
    let value = {
      value: id,
      name: term.name
    }
    tempTermList.push(value)
    setTermSelected("")
    setTermList(tempTermList)
  }

  const handleDeleteMarksScheme = (e, termId, marksId) => {
    let temp = [...formData]
    let termIdx = temp.findIndex(e => parseInt(e.id) === parseInt(termId))
    let marksIdx = temp[termIdx].marksScheme.findIndex(e => parseInt(e.id) === parseInt(marksId))
    let marksItem = temp[termIdx].marksScheme.find(e => parseInt(e.id) === parseInt(marksId))
    if (marksItem?.scheme_id) {
      const deleteMSData = {
        term_id: temp[termIdx].id,
        scheme_id: marksItem.scheme_id,
        standard_id: standardId,
        subject_id: data.subject_id,
        year_id
      }
      handleSchemeDelete.mutate(deleteMSData)
      fetchSubjectData(e)
    }

    temp[termIdx].marksSchemeList.push({ value: marksItem.id, name: marksItem.name })
    temp[termIdx].marksScheme.splice(marksIdx, 1)
    setFormData(temp)
  }

  const handleMarksSchemeGradeChange = (e, termId, marksId) => {
    let temp = [...formData]
    let termIdx = temp.findIndex(e => parseInt(e.id) === parseInt(termId))

    let marksIdx = temp[termIdx].marksScheme.findIndex(e => parseInt(e.id) === parseInt(marksId))
    temp[termIdx].marksScheme[marksIdx].isgrade = e.target.checked
    setFormData(temp)
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let temp = [...formData]
    let arr = temp?.map((it) => {
      return {
        ...it,
        term_id: it.id,
        marksScheme: it.marksScheme?.map((ms) => {
          return {
            ...ms,
            scheme_id: ms.id,
            outoff_marks: ms.max,
            min_marks: ms.min,
          }
        })
      }
    })
    let tempData = {
      terms: JSON.stringify(arr),
      standard_id: standardId,
      subject_id: data.subject_id,
      year_id
    }
    handlePostData.mutate(tempData)
    fetchSubjectData(e)
    onClose()
  }


  const handleOpenDeleteModal = (id) => {
    setIsOpenDeleteModal(true)
    setDeleteData(id)
  }

  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const deletedSuccess = () => {
    setIsDeletedSuccess(true)
  }

  const handleMarksSchemeStatus = (e) => {
    let $this = e.currentTarget
    let index = $this.getAttribute('data-index')
    let formDataCopy = formData
    formDataCopy[index]['scheme_status'] = !formData[index]['scheme_status']
    setFormData([...formDataCopy])
  }

  return (
    <>
      <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className='h-[90%] overflow-y-auto w-[60%] bg-white center-the-div rounded'>
          <div className='px-8 pb-8 divide-y'>
            <div className=''>
              <h2 className='heading capitalize'>Add Marks Scheme</h2>
              <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
            </div>
            <div>
              <form className='overflow-auto' onSubmit={handleFormSubmit}>
                <div className='flex items-end mt-4'>
                  <FormElement
                    label={'Terms'}
                    name={`terms`}
                    element="select"
                    optionValue={termList}
                    value={termSelected}
                    onChange={(e) => setTermSelected(e.target.value)}
                  />
                  <p className='primaryButton flex items-center h-10 ml-4 hover:cursor-pointer' onClick={addTerm}>Add Term</p>
                  {handlePostData.isLoading ?
                    <Spinner /> :
                    <div>
                      <button className='primaryButton w-full ml-6'>Save</button>
                    </div>
                  }
                </div>
                {
                  formData?.length === 0 && (
                    <div className='h-36 w-full flex items-center justify-center '>
                      <p className='text-gray-400'>Add Terms...</p>
                    </div>
                  )
                }
                {formData &&
                  formData?.map((term, idx) => {
                    const { id, name, marksScheme, marksSchemeList, marksSchemeSelect, scheme_status } = term
                    return (
                      <div key={id} className='flex flex-col mt-6 p-4 rounded border-gray-300 border'>
                        <div className='flex justify-between items-center'>
                          <h1 className='label text-lg underline'>{name}</h1>
                          <MinusCircleIcon onClick={(e) => handleDeleteTerm(e, id)} className='w-6 h-6 mb-[-2px] text-red-400 cursor-pointer' />
                        </div>
                        <div className='flex items-end mt-4'>
                          {scheme_status &&
                            <div className='flex items-end mt-4'>
                              <FormElement
                                label={'Marks Scheme'}
                                name={`marks_scheme`}
                                element="select"
                                optionValue={marksSchemeList}
                                value={marksSchemeSelect}
                                onChange={(e) => handleMarksSchemeSelectChange(e.target.value, id)}
                              />
                              <p className='primaryButton flex items-center h-10 ml-4 hover:cursor-pointer' onClick={() => addMarksScheme(id)}>Add</p>
                            </div>}
                          <div className='flex items-center space-x-2 ml-2'>
                            <input type="checkbox" name="scheme_status" checked={scheme_status} data-index={idx} onChange={handleMarksSchemeStatus} />
                            <label className=' font-semibold text-sm'>Show Marks Scheme</label>
                          </div>
                        </div>
                        {
                          marksScheme?.length === 0 && (
                            <div className='h-36 w-full flex items-center justify-center '>
                              <p className='text-gray-400'>Add Marks Scheme...</p>
                            </div>
                          )
                        }
                        {scheme_status && <>
                          {
                            marksScheme?.map((scheme) => {
                              const { id: marksId, name, max, min, isgrade } = scheme
                              return (
                                <div key={marksId} className='flex flex-row items-center mt-2'>
                                  <MinusCircleIcon onClick={(e) => handleDeleteMarksScheme(e, id, marksId)} className='w-6 h-6 mb-[-2px] text-red-400 cursor-pointer' />
                                  <div className='flex flex-1 flex-row items-center justify-between mt-2 pl-4'>
                                    <p className='text-lg '>{name}</p>
                                    <div className='flex w-72'>
                                      <FormElement
                                        label={'Max'}
                                        element={'input'}
                                        placeholder={``}
                                        name={'max'}
                                        onChange={(e) => handleMarksSchemeChange(e, id, marksId)}
                                        value={max}
                                      />
                                      <div className='ml-3'>
                                        <FormElement
                                          label={'min'}
                                          element={'input'}
                                          placeholder={``}
                                          name={'min'}
                                          onChange={(e) => handleMarksSchemeChange(e, id, marksId)}
                                          value={min}
                                        />
                                      </div>
                                      <div className='ml-3 flex flex-col items-center'>
                                        <label className='label whitespace-nowrap'>Show grade</label>
                                        <input type="checkbox" checked={isgrade} className="h-6 w-6 mt-2" onChange={(e) => handleMarksSchemeGradeChange(e, id, marksId)}></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </>}
                      </div>
                    )
                  })
                }
                {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
                {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Marks Mapped Succesfully</Alert>}
              </form>
            </div>
          </div>
        </div>
      </Modal>
      {isOpenDeleteModal && <DeleteStudent open={isOpenDeleteModal} id={deleteData} onClose={() => { }} onDelete={deletedSuccess} url={'deleteMarksScheme'} />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  )
}

export default MapMarksToSubject