import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useGetData } from '../../hooks/useGetData';
import Layout from '../../components/Layout';
import FormElement from '../../components/Form/FormElement';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import { Alert, IconButton, Modal, Pagination, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { downloadReport } from '../report';
import { getList } from '../../api/collections';
import usePostData from '../../hooks/usePostData';
import AddRefund from './AddRefund';
import DeleteItem from '../../components/deleteItem';
import EditEntry from './EditEntry';
import GenerateReport from './GenerateReport';
import Cookies from 'universal-cookie'
import axios from '../../api/axios'

const cookies = new Cookies();
const Ledger = () => {
  const [optionsArray, setOptionsArray] = useState({ yearOptions: [], prefixOptions: [] })
  const [reportLoading, setReportLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    limit: 1000,
    _p: searchParams.get('_p') || 0,
    feebook_no: searchParams.get('feebook_no') || '',
    year_id: searchParams.get('year_id') || '',
    prefix_id: searchParams.get('prefix_id') || ''
  }
  const [resultFilterData, setResultFilterData] = useState(initialState)
  const { handleGetPostList } = useGetData('getPaymentdetails', {
    onSuccess: () => { }
  })

  const { handleGetPostList: createCheque } = useGetData('createChequeBounce', {
    onSuccess: () => {
      let data = {
        ...resultFilterData,
      }
      handleGetPostList.mutate(data)
      setRemark('')
    }
  })

  const handlePageClick = (val) => {
    let data = {
      ...resultFilterData,
      _p: val.selected
    }
    searchParams.set('_p', val.selected)
    setSearchParams(searchParams)
    setResultFilterData(data)
    handleGetPostList.mutate(data)
  }

  const handleSetFromData = (e) => {
    let $this = e.target
    let data = {
      ...resultFilterData,
      [$this.name]: $this.value
    }
    setSearchParams(data)
    setResultFilterData(data)

  }

  const handleFilterSubmit = (e) => {
    e.preventDefault()
    if (e.nativeEvent.submitter.name === 'report') {
      downloadReport(resultFilterData)
        .then((res) => {
          const href = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'report.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setReportLoading(false)
        })
        .catch((err) => {
          setReportLoading(false)
        })
      return
    } else {
      handleGetPostList.mutate({
        ...resultFilterData
      })
    }

  }

  const [isAddLedgerOpen, setIsAddLedgerOpen] = useState(false)
  const handleAddLedger = () => {
    setIsAddLedgerOpen(true)
  }

  const handleAddLedgeClose = () => {
    setIsOpenEditModal(false)
    setIsAddLedgerOpen(false)
    let data = {
      ...resultFilterData,
    }
    handleGetPostList.mutate(data)
  }

  useEffect(() => {
    getList("Year")
      .then(res => {
        let tempId = res.data.filter((i) => i.current_year === "1")
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          yearOptions: res.data
        }));
        setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
      })
    getList("Prefix")
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          prefixOptions: res.data
        }));
      })
  }, []);

  const handleChequeBounce = (data) => {
    createCheque.mutate({
      fee_book_no: searchParams.get('feebook_no'),
      year_id: searchParams.get('year_id'),
      prefix_id: searchParams.get('prefix_id'),
      ...data
    })
  }

  const [editData, setEditData] = useState({})
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const handleOpenEditModal = (data) => {
    setEditData(data)
    setIsOpenEditModal(true)
  }

  const [dataId, setDataId] = useState(0)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)
  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true)
    setDataId(id)
  }


  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const handleReportModal = () => {
    setIsReportModalOpen(true)
  }


  const handleCloseForm = () => {
    setOpenDeleteModal(false)
    setIsOpenEditModal(false)
  }

  const [reportDownloading, setReportDownloading] = useState(false)
  const downloadReportLedger = async (ledgerForm) => {
    let data = {
      year_id: ledgerForm.year_id,
      fee_book_no: ledgerForm.feebook_no
    }
    if (!ledgerForm.year_id || !ledgerForm.feebook_no) return alert('Please Fill Both Year and Fee Book')
    let fd = new FormData()
    let TOKEN = cookies.get("_pn_token");
    for (var key in data) {
      fd.append(key, data[key]);
    }
    const response = await axios({
      method: "POST",
      url: `/generatLedgerReport`,
      responseType: 'blob',
      data: fd,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data;
  }
  const handleSubmitReport = (e) => {
    setReportDownloading(true)
    downloadReportLedger(resultFilterData)
      .then((res) => {
        const href = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'ledger_report.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          alert('No File Found')
        }
      })
      .finally(() => {
        setReportDownloading(false)
      })
  }

  const [remark, setRemark] = useState('')
  const [openRemarkModal, setOpenRemarkModal] = useState(false)
  const [recitdata, setRecitData] = useState()
  const handleOpenModalForChequeBounce = (data) => {
    setOpenRemarkModal(true)
    setRecitData(data)

  }

  const handleSubmitCheque = (e) => {
    e.preventDefault()
    let dataCopy = recitdata
    dataCopy.remark = remark
    handleChequeBounce(dataCopy)
  }

  const handleChangeRemark = (e) => {
    let value = e.target.value
    setRemark(value)
  }

  return (
    <>
      {reportDownloading &&
        <div className=' bg-[#ffffff8c] fixed z-[60] w-full h-full flex items-center justify-center top-0 left-0'>
          <Spinner />
        </div>}
      <Layout>
        <div className='flex justify-between items-end'>
          <form onSubmit={handleFilterSubmit} className='flex'>
            <div className='ml-4'></div>
            <FormElement
              className='w-40'
              label={"Select Year"}
              name={'year_id'}
              element={'select'}
              min={resultFilterData.year_id}
              required={true}
              optionValue={optionsArray.yearOptions}
              value={resultFilterData.year_id}
              errorMessage='Please Select Year!'
              onChange={handleSetFromData}
            />
            <div className='ml-4'></div>
            <FormElement
              className='w-40'
              label={"Enter Feebook"}
              name={'feebook_no'}
              element={'input'}
              type={'text'}
              required={true}
              placeholder={'Enter Feebook'}
              value={resultFilterData.feebook_no}
              errorMessage='Please Enter FeeBook!'
              onChange={handleSetFromData}
            />

            <div className='flex items-end justify-between ml-4'>
              <button className='primaryButton '>Fetch Ledger</button>
            </div>
          </form>
          <button type='button' onClick={handleSubmitReport} className='primaryButton ml-4' name='report'>Generate Report</button>
        </div>
        <div className='mt-6  '>
          {handleGetPostList.isLoading
            ? <Spinner />
            : handleGetPostList?.data?.data?.transaction?.length === 0 || !handleGetPostList?.data?.data?.transaction ? <div className='h-52 w-full'>
              <p className='w-full text-center'>No Transactions Found...</p>
            </div> : <>
              <div className='flex justify-end mb-6'>
                <button onClick={handleAddLedger} type='button' className='h-max primaryButton'>Add Refund</button>

              </div>
              <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Standard
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        QUARTER
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Payment Date
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Payment Amount
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Payment Mode
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Payment Status
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {handleGetPostList?.data?.data?.transaction.map((receiptData, idx) => {
                      const { standard_name, quarter, payment_date, payment_type, payment_mode, amount, receipt_id, payment_status } = receiptData
                      return <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200 userId">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {idx + 1}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                            {standard_name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                            {quarter}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                            {payment_date}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                            {amount}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm ">
                            {payment_mode}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm ">
                            {payment_status.replace('_',' ').toUpperCase()}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 phoneNumber flex justify-end">
                          {
                            (payment_type === 'normal' && payment_mode === 'cheque' && payment_status === 'paid') ? <button onClick={() => handleOpenModalForChequeBounce(receiptData)} type='button' className='h-max primaryButton'>Cheque Bounce</button> : <></>
                          }
                          <div>
                            <IconButton onClick={() => handleOpenEditModal(receiptData)}>
                              <EditIcon />
                            </IconButton>

                          </div>
                          <div>
                            <IconButton onClick={() => handleOpenDeleteModal(receipt_id)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
              <div className=" my-7">
                <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetPostList?.data?.total_count / resultFilterData.limit)} pageNo={handleGetPostList?.data?.page_no} />
              </div>
            </>
          }
        </div>
      </Layout>


      <Modal open={openRemarkModal} onClose={() => setOpenRemarkModal(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className='max-w-[40%] m-auto p-6 mb-10 mt-20 bg-white'>
          <form className=" space-y-4" onSubmit={handleSubmitCheque} autoComplete='off'>
            <div>
              <label htmlFor="email" className=" block text-xl roboto">Enter Remark</label>
              <input type="text"
                className="border-solid border-2 border-gray-400 py-2 px-2 rounded w-full mt-2 placeholder:italic placeholder:text-sm"
                name="remark" placeholder="Enter Remark" value={remark} onChange={handleChangeRemark}></input>
            </div>
            {createCheque.isLoading ? <Spinner /> : <div className="button">
              <button type="submit" className=" w-full py-2 bg-blue-500 text-white hover:bg-blue-600 rounded roboto">Submit</button>
            </div>}
            <button onClick={() => setOpenRemarkModal(false)} className='border border-gray-700 w-full py-2 rounded roboto'>Close</button>
          </form>
        </div>
      </Modal>
      {isReportModalOpen && <GenerateReport open={isReportModalOpen} handleClose={() => setIsReportModalOpen(false)} />}
      {isAddLedgerOpen && <AddRefund data={resultFilterData} open={isAddLedgerOpen} handleClose={handleAddLedgeClose} />}
      {isOpenEditModal && <EditEntry data={editData} open={isOpenEditModal} handleClose={handleAddLedgeClose} />}
      {openDeleteModal && <DeleteItem
        url={`/deleteReceiptEntry`}
        queryKey={'subjectMasterList'}
        onDelete={() => { setIsDeletedError(false); setIsDeletedSuccess(true) }}
        onError={() => { setIsDeletedError(true); setIsDeletedSuccess(false) }}
        open={openDeleteModal}
        deleteData={{ receipt_id: dataId }}
        onClose={() => {
          setOpenDeleteModal(false)
          let data = {
            ...resultFilterData,
          }
          handleGetPostList.mutate(data)
        }}
      />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Receipt Entry Deleted Succesfully
        </Alert>
      </Snackbar>
      <Snackbar open={isDeletedError} autoHideDuration={2000} onClose={() => setIsDeletedError(false)}>
        <Alert onClose={() => setIsDeletedError(false)} severity="error" sx={{ width: '100%' }}>
          Something went wrong!!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Ledger