import { Alert, IconButton, Modal } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import FormElement from '../../components/Form/FormElement'
import { useEffect } from 'react'
import { getList } from '../../api/collections'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Cookies from 'universal-cookie'
import axios from '../../api/axios'
import Spinner from '../../components/spinner'

export const REPORT_DETAILS = [
  {
    name: 'from_date',
    placeholder: 'Enter From Date',
    label: 'From Date',
    type: 'date',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'to_date',
    placeholder: 'Enter To Date',
    label: 'To Date',
    type: 'date',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'fee_book_no',
    placeholder: 'Enter Fee Book Number',
    label: 'Fee Book Number',
    type: 'text',
    element: 'input',
    required: true,
    errorMessage: 'This Field Cannot Be Empty !',
  },
]

const cookies = new Cookies();
export const downloadReport = async (data) => {
  let fd = new FormData()
  let TOKEN = cookies.get("_pn_token");
  for (var key in data) {
    fd.append(key, data[key]);
  }
  const response = await axios({
    method: "POST",
    url: `/generatLedgerReport`,
    responseType: 'blob',
    data: fd,
    headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
  })
  return response.data;
}

const GenerateReport = ({ open, handleClose }) => {
  const [reportData, setReportData] = useState({
    from_date: '',
    to_date: '',
    year_id: ''
  })
  const [loading, setLoading] = useState(false)
  const [optionsArray, setOptionsArray] = useState({ yearOptions: [] })
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    getList("Year")
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          yearOptions: res.data
        }));
      })

  }, []);

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
      ...reportData,
      [e.target.name]: value
    }
    setReportData(data)
  }

  const handleSubmitReport = (e) => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    const data = {
      ...reportData,
    }
    setLoading(true)
    downloadReport(data)
      .then((res) => {
        const href = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'ledger_report.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false)
        setSuccess(true)
      })
      .catch((err) => {
        setError(true)
        setLoading(false)
      })
  }


  return (
    <Modal disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className='w-[30rem] m-auto pt-0 p-6 mb-10 mt-20 bg-white'>
        <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
          <div>
            <IconButton onClick={handleClose}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className='heading'>Download Report</h1>
          </div>
        </div>
        <div className='pt-8'>
          <form className=' space-y-4' onSubmit={handleSubmitReport} autoComplete='off'>
            <div className=' space-y-7'>
              <div className='presonal_detail--section space-y-4'>
                <div className="grid grid-cols-1 gap-4">
                  {
                    console.log(optionsArray.yearOptions)
                  }
                  {REPORT_DETAILS.map((personal_input, idx) => {
                    const { name } = personal_input
                    return <FormElement
                      key={idx}
                      {...personal_input}
                      value={reportData[name]}
                      onChange={handleSetFormData}
                      optionsValue={
                        optionsArray.yearOptions
                      }
                    />
                  })}
                  <FormElement
                    label={"Select Year"}
                    name={'year_id'}
                    element={'select'}
                    required={true}
                    value={reportData.year_id}
                    optionValue={optionsArray.yearOptions}
                    errorMessage='Please Select Year!'
                    onChange={handleSetFormData}
                  />
                </div>
              </div>
              <div className='text-center w-full'>
                {loading ? <Spinner /> : <button className='w-full btn4'>Download</button>}
              </div>
              {error && <Alert onClose={() => setError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}> No data Found</Alert>}
              {success && <Alert onClose={() => setSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Report Generated</Alert>}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default GenerateReport