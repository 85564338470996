import React, { useEffect, useState } from 'react'
import { Alert, Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TEACHERS_DETAILS } from './teacherInputs';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import usePostData from '../../hooks/usePostData';
import { useGetData } from '../../hooks/useGetData';
import { getList } from '../../api/collections';
import Select from 'react-select';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function AddTeacher({ open, onClose }) {
    const initialState = {
        name: '',
        qualification: '',
        email: '',
        phone: '',
        address: '',
        state: '',
        city: '',
        pincode: '',
        username: '',
        password: '',
        teacher_type: []
    }
    const [teacherData, setTeacherData] = useState(initialState)
    const [teacherTypeList, setTeacherTypeList] = useState([])
    const { handlePostData, error, success, onError, onSuccess } = usePostData({ queryName: 'teacherList', url: 'createTeacher' })
    const handleSubmitTeacher = (e) => {
        e.preventDefault()
        const data = { ...teacherData }
        data.teacher_type = JSON.stringify(data.teacher_type.map((it) => it.value))
        handlePostData.mutate(data)
    }
    const handleSetFormData = (e) => {
        let value = e.target.value
        let data = {
            ...teacherData,
            [e.target.name]: value
        }
        setTeacherData(data)
    }

    const fetchOptionsData = () => {
        getList('TeacherType')
            .then(res => {
                let tempTerm = []
                res.data?.map((it) => {
                    return tempTerm.push({
                        value: it.id,
                        label: it.type_name
                    })
                })
                setTeacherTypeList(tempTerm);
            })
    }

    useEffect(() => {
        handlePostData.isSuccess && onClose()
    }, [handlePostData.isSuccess])

    useEffect(() => {
        fetchOptionsData();
    }, [])

    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={onClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Add Teacher</h1>
                        </div>
                    </div>
                    <div className='form-body py-8'>
                        <form className=' space-y-4' onSubmit={handleSubmitTeacher} autoComplete='off'>
                            <div className=' space-y-7'>
                                <div className='presonal_detail--section space-y-4'>
                                    <div className=''>
                                        <h3 className=' uppercase text-xl font-semibold underline'>TEACHER DETAILS</h3>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        {TEACHERS_DETAILS.map((personal_input, idx) => {
                                            const { name } = personal_input
                                            return <FormElement
                                                key={idx}
                                                {...personal_input}
                                                // optionValue={name === 'class_std' ? options.standardArray : name === 'quota' ? options.quotaArray : options.divisionArray}
                                                value={teacherData[name]}
                                                onChange={handleSetFormData}
                                            />
                                        })}
                                        <div></div>
                                        <div className='flex flex-col'>
                                            <label htmlFor="teacherType" className='label'>Teacher Type <span className='mandatory'>*</span></label>
                                            <Select
                                                isMulti
                                                options={teacherTypeList}
                                                onChange={(item) => setTeacherData({ ...teacherData, teacher_type: item })}
                                                required={true}
                                                className='input p-0'
                                            />
                                            {handlePostData?.error?.response?.data?.data?.teacher_type && <small className=" text-red-700 font-semibold mt-1">This Field Cannot Be Empty !</small>}
                                        </div>
                                        <FormElement value={teacherData['username']} onChange={handleSetFormData} name='username' placeholder='Enter Username' label='Username' required={true} />
                                        <FormElement value={teacherData['password']} onChange={handleSetFormData} name='password' placeholder='Enter Password' label='Password' required={true} />
                                    </div>
                                </div>
                                <div className='text-center'>
                                    {handlePostData.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                                </div>
                                {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
                                {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Teacher Added Succesfully</Alert>}
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddTeacher