import React, { useEffect, useState } from 'react'
import { Alert, Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FEE_USER_DETAILS } from './FeeUserInputs';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import usePostData from '../../hooks/usePostData';
import { getList } from '../../api/collections';
import Select from 'react-select';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AddFeeUser = ({ open, onClose }) => {
  const initialState = {
    name: '',
    qualification: '',
    email: '',
    phone: '',
    address: '',
    state: '',
    city: '',
    pincode: '',
    username: '',
    password: '',
}
const [teacherData, setTeacherData] = useState(initialState)
const { handlePostData, error, success, onError, onSuccess } = usePostData({ queryName: 'staffList', url: 'createStaff' })
const handleSubmitTeacher = (e) => {
    e.preventDefault()
    const data = { ...teacherData }
    handlePostData.mutate(data)
}
const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
        ...teacherData,
        [e.target.name]: value
    }
    setTeacherData(data)
}


useEffect(() => {
    handlePostData.isSuccess && onClose()
}, [handlePostData.isSuccess])

  return (
    <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
      <div className='container_xxl mb-10'>
        <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
          <div>
            <IconButton onClick={onClose}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className='heading'>Add Fee Staff</h1>
          </div>
        </div>
        <div className='form-body py-8'>
          <form className=' space-y-4' onSubmit={handleSubmitTeacher} autoComplete='off'>
            <div className=' space-y-7'>
              <div className='presonal_detail--section space-y-4'>
                <div className=''>
                  <h3 className=' uppercase text-xl font-semibold underline'>Staff DETAILS</h3>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  {FEE_USER_DETAILS.map((personal_input, idx) => {
                    const { name } = personal_input
                    return <FormElement
                      key={idx}
                      {...personal_input}
                      value={teacherData[name]}
                      onChange={handleSetFormData}
                    />
                  })}
                  <FormElement value={teacherData['username']} onChange={handleSetFormData} name='username' placeholder='Enter Username' label='Username' required={true} />
                  <FormElement value={teacherData['password']} onChange={handleSetFormData} name='password' placeholder='Enter Password' label='Password' required={true} />
                </div>
              </div>
              <div className='text-center'>
                {handlePostData.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
              </div>
              {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
              {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>User Added Succesfully</Alert>}
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  )
}

export default AddFeeUser