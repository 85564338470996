import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import axios from "../../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();


function useEditStudent() {
    const queryClient = useQueryClient()
    const [error, onError] = useState()
    const [success, onSuccess] = useState()
    const editPersonalDetail = useMutation((data) => handleEditPersonalDetail(data), {
        onError: (err) => {
            onError(true)
        },
        onSuccess: () => {
            onSuccess(true)
            queryClient.invalidateQueries('getStudentList')
        }
    })
    const handleEditPersonalDetail = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            if (!getFormData[key]) {
                fd.append(key, '');
            } else {
                fd.append(key, getFormData[key]);
            }
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/updateStudent`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    const editAdmissionDetail = useMutation((data) => handleEditAdmissionDetail(data), {
        onError: (err) => {
            onError(true)
        },
        onSuccess: () => {
            onSuccess(true)
        }
    })
    const handleEditAdmissionDetail = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/updateFeeDetail`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    const editPreviousDetail = useMutation((data) => handleEditPreviousDetail(data), {
        onError: (err) => {
            onError(true)
        },
        onSuccess: () => {
            onSuccess(true)
        }
    })
    const handleEditPreviousDetail = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/updateFeeDetail`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { error, success, onError, onSuccess, editPersonalDetail, editAdmissionDetail, editPreviousDetail }
}

export default useEditStudent