const IS_REQUIRED = true
export const FEE_USER_DETAILS = [
    {
        name: 'name',
        placeholder: 'Enter Staff Full Name',
        label: 'Staff Full Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'email',
        placeholder: 'Enter Email',
        label: 'Email',
        type: 'email',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'Enter Valid Email!',
    },
    {
        name: 'qualification',
        placeholder: 'Enter qualification',
        label: 'User qualification',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'phone',
        placeholder: 'Enter Phone Number',
        label: 'Phone Number',
        type: 'number',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'address',
        placeholder: 'Enter Address',
        label: 'Address',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'state',
        placeholder: 'Enter State',
        label: 'State',
        type: 'text',
        element: 'input',
        optionValue: [],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'city',
        placeholder: 'Enter City',
        label: 'City',
        type: 'text',
        element: 'input',
        optionValue: [],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'pincode',
        placeholder: 'Enter pincode',
        label: 'Pincode',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
]