import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import useSetStudent from './useCreateStudent';
import Spinner from '../../components/spinner';
import { PREVIOUS_SCHOOLS_DETAILS, STUDENT_ADMISSION_DETAILS, STUDENT_PERSONAL_DETAILS } from './studentInputs';
import FormElement from '../../components/Form/FormElement';
import { useEffect } from 'react';
import { getList } from '../../api/collections';
import { useGetData } from '../../hooks/useGetData';
import usePostData from '../../hooks/usePostData';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddStudent({ open, handleClose, options }) {
    const initialState = {
        first_name: '',
        middle_name: '',
        last_name: '',
        guardian_name: '',
        mother_name: '',
        cast: '',
        sub_cast: '',
        address: '',
        city: '',
        pincode: '',
        office_no: '',
        home_no: '',
        mobile_no: '',
        birth_date: '',
        place_of_birth: '',
        religion: '',
        regi_no: '',
        fee_book_no: '',
        is_new_admission: 'Yes',
        monthly_fee_disc: '',
        term_fee_disc: '',
        security_deposite: '',
        previous_school_name: '',
        sch_lc_no: '',
        sch_lc_date: '',
        reason: '',
        conduct: '',
        progress: '',
        remark: '',
        class_std: '1',
        is_previous_school_detail: false,
        division: '',
        details_concession: '',
        computer_fee_disc: '',
        student_admission_fee: '',
        quota: '',
        house: '',
        mother_tongue: '',
        aadhar_card: '',
        student_uid: '',
        join_year: '',
        roll_no: '',
        gender:'female'
    }
    const [studentData, setStudentData] = useState(initialState)
    const [feebookId, setFeeBookId] = useState(null)

    const { handleGetPostList } = useGetData('generateMidYearFeeBook', {
        onSuccess: (res) => {
            setFeeBookId(res.data)
            let data = {
                ...studentData,
                fee_book_no: res.data
            }
            setStudentData(data)
        }
    })

    const handleGenerateFeebookNo = () => {
        if (studentData['join_year'] && studentData['class_std']) {
            // generate feebook => generateMidYearFeeBook
            handleGetPostList.mutate({
                join_year: studentData['join_year'],
                class_std: studentData['class_std']
            })
        }
    }

    const handleSetFormData = (e) => {
        let name = e.target.name
        let value = e.target.value
        let data;
        if ((name === 'join_year' || name === 'division' || name === 'class_std') && !feebookId) {
            data = {
                ...studentData,
                fee_book_no: '',
                roll_no: '',
            }
            setFeeBookId('')
            setMessage('')
            setErrMessage('')
            if (studentData['join_year'] && studentData['division'] && studentData['class_std']) {
                // generate feebook => generateMidYearFeeBook
                handleGetPostList.mutate({
                    join_year: studentData['join_year'],
                    division: studentData['division'],
                    class_std: studentData['class_std']
                })
            }
        }
        data = {
            ...studentData,
            [e.target.name]: value
        }
        setStudentData(data)
    }
    const handleCheckPreviousSchool = (e) => {
        let status = e.target.checked;
        let data = {
            ...studentData,
            is_previous_school_detail: status
        }
        setStudentData(data)
    }
    const { setDataToServer, success, onSuccess, error, onError } = useSetStudent()
    const handleSubmitStudent = (e) => {
        e.preventDefault()
        setDataToServer.mutate(studentData)
    }

    useEffect(() => {
        setStudentData({
            ...studentData,
            quota: options?.quotaArray[0]?.id,
        })
    }, [])

    const [isAllowedToType, setIsAllowedToType] = useState(false)
    const generateRollNumber = useGetData('generateRollNumber', {
        onSuccess: (res) => {
            console.log(res.data)
            if (res.data === null || !res.data) {
                alert(res.message)
                setIsAllowedToType(true)
                return
            } else {
                let data = {
                    ...studentData,
                    roll_no: res.data
                }
                setStudentData(data)
            }
        }
    })

    const generatePreFix = useGetData('getPrefix', {
        onSuccess: (res) => {
            console.log(res.data)
        }
    })

    useEffect(() => {
        let data = {
            standard_id: studentData.class_std
        }
        generatePreFix.handleGetPostList.mutate(data)
    }, [studentData.class_std])



    const handleGenerateRollNo = () => {
        if (studentData['join_year'] && studentData['class_std']) {
            // generate feebook => generateMidYearFeeBook
            generateRollNumber.handleGetPostList.mutate({
                join_year: studentData['join_year'],
                class_std: studentData['class_std']
            })
        }
    }

    const [feeBookType, setFeeBookType] = useState()
    const handleChangeFeeBookInputType = (e) => {
        setFeeBookType(e.target.value)
        setMessage('')
        setErrMessage('')
    }
    const [message, setMessage] = useState('')
    const [errMessage, setErrMessage] = useState('')
    const { handlePostData } = usePostData({
        url: 'feebookNumberAllowed',
        onSuccessCb: (res) => {
            setErrMessage('')
            setMessage(res?.message)
        },
        onErrorCb: (err) => {
            let erroreMsg = err?.response?.data?.message;
            if (erroreMsg === 'Fee Book Number already being used') {
                setErrMessage(erroreMsg)
            }
            setMessage('')
        }
    })
    const handleSetFeeBookNumberManually = (e) => {
        setMessage('')
        setErrMessage('')
        let fee_book_no = e.target.value
        let data = {
            fee_book_no,
            year_id: studentData['join_year'],
            standard_id: studentData['class_std']
        }
        let fd = {
            ...studentData,
            [e.target.name]: fee_book_no
        }
        if (fee_book_no === '') return
        setFeeBookId(fee_book_no)
        handleSetFormData(e)
        handlePostData.mutate(data)
        setStudentData(fd)
    }

    if (!open) return null
    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Add Student</h1>
                        </div>
                    </div>
                    <div className='form-body py-8'>
                        <form className=' space-y-4' onSubmit={handleSubmitStudent} autoComplete='off'>
                            <div className=' space-y-7'>
                                <div className='presonal_detail--section space-y-4'>
                                    <div className=''>
                                        <h3 className=' uppercase text-xl font-semibold underline'>PERSONAL DETAILS</h3>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4">
                                        {STUDENT_PERSONAL_DETAILS.map((personal_input, idx) => {
                                            const { name } = personal_input
                                            return <FormElement
                                                key={idx}
                                                {...personal_input}
                                                optionValue={name === 'class_std' ? options.standardArray : name === 'quota' ? options.quotaArray : name === 'join_year' ? options.yearArray : name === 'division' ? options.divisionArray : personal_input.optionValue}
                                                value={studentData[name]}
                                                onChange={handleSetFormData}
                                            />
                                        })}
                                        <div className='flex flex-col '>

                                            <label className='label'>Roll No.
                                                {!studentData['join_year'] || !studentData['class_std'] ?
                                                    <span className='text-red-600 text-xs'> ( Please Enter join year, and class / standard ) </span> : <></>
                                                }
                                            </label>

                                            <div className='flex flex-row'>
                                                <input className='w-[10rem] input' onChange={handleSetFormData} name='roll_no' disabled={!isAllowedToType} value={studentData['roll_no']} />
                                                <button
                                                    disabled={!studentData['join_year'] || !studentData['class_std'] || generateRollNumber.handleGetPostList.isLoading}
                                                    onClick={handleGenerateRollNo}
                                                    type='button'
                                                    className='w-full whitespace-nowrap bg-[color:var(--color5)] h-[2.6rem] rounded-md py-2 px-3 ml-6 text-white disabled:cursor-not-allowed' >
                                                    <div className='flex items-center justify-center space-x-2'>
                                                        <span>Fetch Roll No</span>
                                                        {generateRollNumber.handleGetPostList.isLoading &&
                                                            <svg className={'animate-spin  h-5 w-5 text-white'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>}
                                                    </div>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='presonal_detail--section space-y-4'>
                                    <div className=''>
                                        <h3 className=' uppercase text-xl font-semibold underline'>Admission details</h3>
                                    </div>
                                    <div className="grid grid-cols-2 gap-4">
                                        {STUDENT_ADMISSION_DETAILS.map((personal_input, idx) => {
                                            const { name, hasAutoGenerate } = personal_input
                                            return !hasAutoGenerate
                                                ? <div className=' mt-4'>

                                                    <FormElement
                                                        key={idx}
                                                        {...personal_input}
                                                        value={studentData[name]}
                                                        onChange={handleSetFormData}
                                                    />
                                                </div>
                                                : <>
                                                    <div>
                                                        <div className='flex items-center space-x-4'>
                                                            <div>
                                                                <input onChange={handleChangeFeeBookInputType} checked={feeBookType === 'manual' && true} type="radio" id="age1" name="feeBookType" value="manual" />
                                                                <label className='ml-2 font-semibold text-xl' for="age1">Manual</label>
                                                            </div>
                                                            <div>
                                                                <input onChange={handleChangeFeeBookInputType} checked={feeBookType === 'auto' && true} type="radio" id="age2" name="feeBookType" value="auto" />
                                                                <label className='ml-2 font-semibold text-xl' for="age2">Auto</label>
                                                            </div>
                                                        </div>
                                                        {feeBookType === 'auto'
                                                            ? <div className='flex items-end mt-4 space-x-4'>
                                                                <div className=' flex-[10%]'>
                                                                    <p className='input'>
                                                                        {generatePreFix.handleGetPostList.data?.data}
                                                                    </p>
                                                                </div>
                                                                <div className=' flex-[100%]'>
                                                                    <FormElement
                                                                        key={idx}
                                                                        info={!studentData['join_year'] || !studentData['class_std'] ? '( Please Enter join year, and class / standard )' : ''}
                                                                        {...personal_input}
                                                                        value={feebookId}
                                                                        onChange={handleSetFormData}
                                                                    />
                                                                </div>
                                                                <button
                                                                    disabled={!studentData['join_year'] || !studentData['class_std'] || handleGetPostList.isLoading}
                                                                    onClick={handleGenerateFeebookNo}
                                                                    type='button'
                                                                    className='w-full whitespace-nowrap bg-[color:var(--color5)] h-[2.6rem] rounded-md py-2 px-3 ml-6 text-white disabled:cursor-not-allowed' >
                                                                    <div className='flex items-center justify-center space-x-2'>
                                                                        <span>
                                                                            Fetch Feebook No
                                                                        </span>
                                                                        {handleGetPostList.isLoading &&
                                                                            <svg className={'animate-spin  h-5 w-5 text-white'} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                            </svg>}
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            : feeBookType === 'manual'
                                                                ? <div className='mt-4'>
                                                                    <div className='flex items-end space-x-5'>
                                                                    <div className=' flex-[5%]'>
                                                                    <p className='input'>
                                                                        {generatePreFix.handleGetPostList.data?.data}
                                                                    </p>
                                                                </div>
                                                                        <div className='flex-[100%]'>
                                                                            <FormElement
                                                                                key={idx}
                                                                                info={!studentData['join_year'] || !studentData['class_std'] ? '( Please Enter join year, and class / standard )' : ''}
                                                                                {...personal_input}
                                                                                value={feebookId}
                                                                                disabled={!studentData['join_year'] || !studentData['class_std'] ? true : false}
                                                                                onChange={handleSetFeeBookNumberManually}
                                                                            />
                                                                        </div>
                                                                        {handlePostData.isLoading && <Spinner />}
                                                                    </div>
                                                                    <p className=' text-green-700 font-semibold'>{message}</p>
                                                                    <p className=' text-red-700 font-semibold'>{errMessage}</p>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </>
                                        })}

                                    </div>
                                </div>
                                <div className='admission_detail--section space-y-4' id='previous_detail--section'>
                                    <div className=''>
                                        <h3 className=' uppercase text-xl font-semibold underline'>
                                            Previous School DETAILS
                                        </h3>
                                    </div>
                                    <div className=' space-x-2'>
                                        <label htmlFor="Previous School Name" className='label'>Is Previous School Details</label>
                                        <input type="checkbox" onChange={handleCheckPreviousSchool} placeholder='Enter Previous School Name' name='previous_school_name' />
                                    </div>
                                    {studentData.is_previous_school_detail &&
                                        <div className="grid grid-cols-3 gap-4">
                                            {PREVIOUS_SCHOOLS_DETAILS.map((personal_input, idx) => {
                                                const { name } = personal_input
                                                return <FormElement
                                                    key={idx}
                                                    {...personal_input}
                                                    value={studentData[name]}
                                                    onChange={handleSetFormData}
                                                />
                                            })}
                                        </div>
                                    }
                                </div>
                                <div className='text-center'>
                                    {setDataToServer.isLoading ? <Spinner /> : <button className='btn4 disabled:cursor-not-allowed disabled:opacity-50' disabled={errMessage ? true : false}>Save</button>}
                                </div>
                                {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.response?.data.message}, Please try again later</Alert>}
                                {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Student Added Succesfully</Alert>}
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddStudent