import { useQuery } from "@tanstack/react-query";
import axios from "../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function useFetchData(url) {
    const handleGetData = useQuery([url], () => getUsers(), {
        refetchOnWindowFocus: false
    })
    const getUsers = async () => {
        let TOKEN = cookies.get('_pn_token');
        const response = await axios({
            method: "GET",
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { handleGetData }
}

export default useFetchData
