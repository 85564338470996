import React, { useEffect, useState } from 'react'
import { getList } from '../../api/collections'
import FormElement from '../../components/Form/FormElement'
import Layout from '../../components/Layout'
import Spinner from '../../components/spinner'
import { useGetData } from '../../hooks/useGetData'
import { EyeIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom'
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/paginate'

function FeeBookGeneration() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const initalState = {
        year_id: searchParams.get('_y') || '',
        pageNo: searchParams.get('_p') || 0,
        limit: 10
    }
    const [downloadFilterData, setDownloadFilterData] = useState(initalState)
    const handleSetFromData = (e) => {
        let $this = e.target
        let data = {
            ...downloadFilterData,
            [$this.name]: $this.value
        }
        searchParams.set('_y', $this.value)
        setSearchParams(searchParams)
        const optionsPath = {
            pathname: '/fee-book-generation',
            search: `?_y=${$this.value}`,
        };
        navigate(optionsPath, { replace: true });
        setDownloadFilterData(data)
    }
    const options = {
        standardArray: [],
        yearArray: [],
    }
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Year"]
        typeList.forEach((type) => {
            fetchOptionData(type)
        })
    }, [])
    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setOptionsArray({
                        ...optionsArray,
                        yearArray: res.data
                    });
                    setDownloadFilterData({ ...downloadFilterData, year_id: tempId[0]?.id })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    const { handleGetPostList,getFormData, setGetFormData } = useGetData('getStandardFeeBookList', {
        onSuccess: () => { }
    })
    const handleSaveFromData = (e) => {
        e.preventDefault()
        handleGetPostList.mutate(downloadFilterData)
    }

    useEffect(() => {
        let year_id = searchParams.get('_y')
        if (year_id) {
            handleGetPostList.mutate(downloadFilterData)
        }
    }, [downloadFilterData.year_id])

    const generateFeeBook = useGetData('generateStandardFeeReceipt', {
        onSuccess: () => {
            handleGetPostList.mutate(downloadFilterData)
            setStdId('')
        }
    })
    const [stdId, setStdId] = useState('')
    const handleGenerateFeeBook = (e) => {
        let standard_id = e.target.getAttribute('data-standardid')
        setStdId(standard_id)
        let data = {
            standard_id,
            year_id: downloadFilterData.year_id
        }
        generateFeeBook.handleGetPostList.mutate(data)
    }

    const handlePageClick = (val) => {
        let data = {
            ...downloadFilterData,
            pageNo: val.selected
        }
        searchParams.set("_p",val.selected)
        setSearchParams(searchParams)
        // setGetFormData(data)
        handleGetPostList.mutate(data)
    }

    useEffect(() => {
        let year_id = searchParams.get('_y')
        if (year_id) {
            
        }
    },[])

    return (
        <Layout>
            <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                <div className='grid grid-cols-3 gap-x-4 items-end'>
                    <FormElement
                        label={"Year"}
                        name={'year_id'}
                        placeholder={'Select Standard Type'}
                        element={'select'}
                        required={true}
                        value={downloadFilterData.year_id}
                        optionValue={optionsArray.yearArray}
                        errorMessage='This Field Cannot Be Empty !'
                        onChange={handleSetFromData}
                    />
                    <div>
                        <button className='primaryButton w-full'>Fetch Standards</button>
                    </div>
                </div>
            </form>
            <div>
                {handleGetPostList.isLoading
                    ? <Spinner />
                    : handleGetPostList.isSuccess && <>
                        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                <thead>
                                    <tr className="text-left">
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                            Sr.no
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Standard
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Year
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Progress
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {handleGetPostList?.data?.data.map((studentData, idx) => {
                                        const { year_name, standard_name, status, standard_id, standard_fee_book_id } = studentData
                                        return <tr key={idx}>
                                            <td className="border-dashed border-t border-gray-200 userId">
                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                    {/* {(getFormData.limit * (parseInt(getFormData.pageNo) + 1)) - (getFormData.limit - (idx + 1))} */}
                                                    {idx + 1}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                    {standard_name}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                    {year_name}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName text-center">
                                                {status === 'In-Progress' &&
                                                    <span className={`px-3 py-2 rounded-full text-center text-xs font-semibold inprogress_report`}>
                                                        {status}
                                                    </span>}
                                                {status === 'Completed' &&
                                                    <span className={`px-3 py-2 rounded-full text-center text-xs font-semibold generated_report`}>
                                                        {status}
                                                    </span>}
                                                {/* {status === 'Not Generated' &&
                                                    standard_id === stdId
                                                    ? <Spinner className={'w-6 h-6'} />
                                                    : <button onClick={handleGenerateFeeBook} data-standardid={standard_id} className='primaryButton rounded-full text-xs font-semibold'>
                                                        Generate
                                                    </button>
                                                } */}
                                                {standard_id === stdId 
                                                    ? <Spinner className={'w-6 h-6 my-0'} />
                                                    : status === 'Not Generated' && <button onClick={handleGenerateFeeBook} data-standardid={standard_id} className='primaryButton rounded-full text-xs font-semibold'>
                                                        Generate
                                                    </button>
                                                }
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                <div className='flex items-center justify-center'>
                                                    <div>
                                                        {/* <IconButton onClick={() => handleOpenEditModal(standard_id)}> */}
                                                        <Link 
                                                            to={`/standard-fee-book?_y=${downloadFilterData.year_id}&_s=${standard_id}&std_name=${standard_name}&year=${optionsArray['yearArray'].filter((item) => item.id === downloadFilterData['year_id'])[0]?.name}`} 
                                                        >
                                                            <EyeIcon className='w-6 h-6 text-gray-700 cursor-pointer' />
                                                        </Link>
                                                        {/* </IconButton> */}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className=" my-7">
                            <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetPostList?.data?.total_count / downloadFilterData?.limit)} pageNo={handleGetPostList?.data?.page_no} />
                        </div>
                    </>
                }
            </div>
        </Layout>
    )
}

export default FeeBookGeneration