import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function usePostData({ queryName, url,onSuccessCb,onErrorCb }) {
    const queryClient = useQueryClient()
    const [getFormData, setGetFormData] = useState('')
    const [error, onError] = useState(false)
    const [success, onSuccess] = useState(false)
    let handlePostData = useMutation((data) => postsData(data), {
        onError: (err) => {
            onError(true)
            setTimeout(() => {
                onError(false)
            }, 2500)
            if(onErrorCb){
                onErrorCb(err)
            }
        },
        onSuccess: (res) => {
            {queryName && queryClient.invalidateQueries(queryName)}
            onSuccess(true)
            setTimeout(() => {
                onSuccess(false)
            }, 2500)
            if(onSuccessCb){
                onSuccessCb(res)
            }
        }
    })
    const postsData = async (getFormData) => {
        let TOKEN = cookies.get('_pn_token');
        let fd = new FormData()
        for (var key in getFormData) {
            if(Array.isArray(getFormData[key])){
                fd.append(key, JSON.stringify(getFormData[key]));
            }else{
                fd.append(key, getFormData[key]);
            }
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { getFormData, setGetFormData, handlePostData, error, success, onError, onSuccess }
}

export default usePostData