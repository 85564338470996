import { Alert, Button, Dialog, Slide, Snackbar } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useEffect, useState } from 'react'
import Spinner from '../../components/spinner';

import { getList } from '../../api/collections';
import usePostData from '../../hooks/usePostData';
import { useGetData } from '../../hooks/useGetData';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const EditReceipts = ({ open, handleClose, data, setSuccessMessageAlert, name, yearId }) => {
	const [yearFilter, setYearFilter] = useState(yearId);
	const [yearOptionsData, setYearOptionsData] = useState(null);
	const [termData, setTermData] = useState();
	const [selectedTab, setSelectedTab] = useState(1)
	const { handleGetPostList } = useGetData('getFeeBookDetails', { onSuccess: () => { }, queryName: 'getFeeBookDetails' })
	const { handlePostData: setReceiptCollection, error, success, onError, onSuccess } = usePostData({ queryName: 'getReceiptCollectionStudentList', url: 'CreateAndUpdateReceiptCollection' })

	const handleSelectTab = (idx) => {
		setSelectedTab(idx)
	}

	const handleFormSubmit = (e, termId, totalAmt, feeBookId, idx) => {
		e.preventDefault()
		const formData = {
			term_id: termId,
			total: totalAmt,
			year_id: yearId,
			student_id: data.student_id,
			standard_id: data.class_std,
			payment_status: termData[idx].payment_status,
			payment_date: termData[idx].payment_date,
			payment_mode: termData[idx].payment_mode,
			remarks: termData[idx].remarks,
			fee_book_id: feeBookId
		};
		setReceiptCollection.mutate(formData)
	}

	const handleChange = (e, termId) => {
		const termDataIdx = termData[termId];
		const updatedTermDataIdx = { ...termDataIdx, [e.target.name]: e.target.value }
		const newTermData = [...termData];
		newTermData[termId] = updatedTermDataIdx;
		setTermData(newTermData);
	}

	const fetchOptionsData = () => {
		getList('Year')
			.then(res => {
				setYearOptionsData(res.data);
				setYearFilter(res.data[0]?.id);
			})
	}

	const filterData = () => {
		if (yearFilter === '') return
		handleGetPostList.mutate({
			year_id: yearFilter,
			standard_id: data.class_std,
			student_id: data.student_id
		})
	}

	useEffect(() => {
		fetchOptionsData();
	}, [])

	useEffect(() => {
		if (!success) return
		setSuccessMessageAlert(true)
		handleClose(false)
	}, [success])

	useEffect(() => {
		filterData();
	}, [])

	useEffect(() => {
		setTermData(handleGetPostList.data?.term);
	}, [handleGetPostList.isSuccess])

	return (
		<>
			<Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
				<div className='container_xxl mb-10'>
					<div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
						<div>
							<IconButton onClick={handleClose}>
								<ArrowBackIcon />
							</IconButton>
						</div>
						<div>
							<h1 className='heading'>Edit Receipts <span className='fort-normal'>[{name}]</span></h1>
						</div>
					</div>
					{/* <div className='flex mt-4 justify-center mb-4 items-center'>
						<h1 className='label mb-0 mr-2'>Select Year: </h1>
						<select value={yearFilter} onChange={(e) => setYearFilter(e.target.value)} name="year" className='input w-max px-4'>
							<option value="">--Select--</option>
							{yearOptionsData?.map((year, idx) => {
								return <option key={idx} value={year.id}>{year.name}</option>
							})}
						</select>
					</div> */}
					{
						yearFilter !== '' && (
							handleGetPostList.isLoading ? <Spinner /> : (
								<>
									<ul className='flex items-center justify-center space-x-4 mt-4'>
										{termData?.map((item, idx) => {
											const { term_name } = item
											return <li key={idx} onClick={() => handleSelectTab(idx + 1)} className={`editStudent-tab  ${idx + 1 === selectedTab && 'active'}`}>{term_name}</li>
										})}
									</ul>
									<div className='tab-container my-10'>
										{
											termData?.map((data, idx) => {
												console.log({data});
												const { particulars_data, term_id, total_amount, payment_date, payment_status, remarks, fee_book_id, payment_mode } = data
												return <div key={idx} className={`tab-content presonalDetails divide-y ${selectedTab === idx + 1 && 'active'}`}>
													{particulars_data?.map((item, itemId) => {
														const { particular_name, amount } = item
														return <div key={itemId} data-termid={term_id} data-termidx={idx} data-particularidx={itemId}>
															<div className='flex items-center py-4'>
																<div className='flex-1 text-xl capitalize ml-2'>{particular_name}</div>
																<div className='input w-[7rem]'>{parseFloat(amount).toFixed(2)}</div>
															</div>
														</div>
													})
													}
													<div className='flex items-center py-4 border-t mt-4 border-black'>
														<div className='flex-1 text-xl capitalize ml-2'>Total</div>
														<div>
															{total_amount}
														</div>
													</div>
													<form className='mt-4 border-none' data-termid={data.term_id} onSubmit={(e) => handleFormSubmit(e, term_id, total_amount, fee_book_id, idx)}>
														<div className='border-2 p-4'>
															<div className='grid grid-cols-2 gap-y-4 gap-x-8 py-4'>
																<div className='flex flex-1  items-center'>
																	<div className='flex-1 text-xl capitalize ml-2'>Paid/Unpaid</div>
																	<div>
																		<select name="payment_status" className='input' value={payment_status} onChange={(e) => handleChange(e, idx)} required={true}>
																			<option value="">--Select--</option>
																			<option value="paid">Paid</option>
																			<option value="unpaid">Unpaid</option>
																		</select>
																	</div>
																</div>
																<div className='flex flex-1 items-center'>
																	<div className='flex-1 text-xl capitalize ml-2'>Payment Mode</div>
																	<div>
																		<select name="payment_mode" className='input' value={payment_mode} onChange={(e) => handleChange(e, idx)} required={true}>
																			<option value="">--Select--</option>
																			<option value="cash">Cash</option>
																			<option value="cheque">Cheque</option>
																			<option value="neft">NEFT</option>
																		</select>
																	</div>
																</div>
																<div className='flex flex-1'>
																	<div className='flex flex-1 items-center py-4 border-black'>
																		<div className='flex-1 text-xl capitalize ml-2'>Payment Date</div>
																		<div className='w-[10rem]'>
																			<input type="date" name="payment_date" value={payment_date} onChange={(e) => handleChange(e, idx)} required={true} className="input" />
																		</div>
																	</div>
																</div>
															</div>
															<div className='flex flex-col items-start py-4 border-black'>
																<div className='flex-1 text-xl capitalize ml-2'>Remarks</div>
																<textarea name='remarks' type="text" value={remarks} onChange={(e) => handleChange(e, idx)} placeholder='Enter Remark' className='input ml-2 mt-3' />
															</div>
															{setReceiptCollection.isLoading ? <Spinner /> : <button className='primaryButton w-max mt-4 ml-2'>Submit</button>}

														</div>
														{error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setReceiptCollection?.error?.response?.data.message}, Please try again later</Alert>}
													</form>
												</div>
											})}
									</div>
								</>
							)
						)
					}
				</div>
			</Dialog>
		</>
	)
}

export default EditReceipts




