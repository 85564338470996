import { Modal } from '@mui/material'
import { XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import FormElement from '../../components/Form/FormElement'


const IS_REQUIRED = true

const MARKS_INPUTS = [
  {
    name: 'marks',
    placeholder: 'Enter Marks',
    label: 'Marks',
    type: 'text',
    element: 'input',
    required: IS_REQUIRED,
    errorMessage: 'This Field Cannot Be Empty !',
  },
  {
    name: 'Remarks',
    placeholder: 'Enter Marks',
    label: 'Remarks',
    type: 'text',
    element: 'textarea',
    required: IS_REQUIRED,
    errorMessage: 'This Field Cannot Be Empty !',
  },
]


const MarksEntryModal = ({ open, onClose, studentId}) => {

  const initialState = {
    marks: '',
    grade: '',
    remarks: '',
    result: '1'
  }
  const [marksData,setMarksData] = useState(initialState)

  const handleSetFormData = (e) => {
    let value = e.target.value
    let data = {
        ...marksData,
        [e.target.name]: value
    }
    setMarksData(data)
}

  const handleSaveFromData = (e) => {
    e.preventDefault()
    
    }


  return (
    <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className=' w-[40%] bg-white center-the-div rounded'>
        <div className='px-8 divide-y'>
            <div className=''>
                <h2 className='heading capitalize'>Add Marks </h2>
                <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
            </div>
            <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
              
                {MARKS_INPUTS.map((personal_input, idx) => {
                    const { name } = personal_input
                    return <FormElement
                        key={idx}
                        {...personal_input}
                        value={marksData[name]}
                        onChange={handleSetFormData}
                    />
                })}
              
            </form>
          </div>
        </div>
      </Modal>
  )
}

export default MarksEntryModal