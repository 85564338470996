import { PlusIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../components/Layout'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddMarksScheme from './AddMarksScheme';
import EditMarksScheme from './EditMarksScheme';
import useFetchList from '../../hooks/useFetchList';
import Spinner from '../../components/spinner';
import { useQueryClient } from '@tanstack/react-query';
import { Alert, Snackbar } from '@mui/material';
import DeleteStudent from '../students/DeleteStudent';
import Pagination from '../../components/paginate';

const initialState = {
  limit: 10,
  pageNo: 0,
}

const MarksScheme = () => {
  const queryClient = useQueryClient()
  const { handleGetData, setGetListParams, paramsObject } = useFetchList("listMarksScheme", initialState)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [editData, setEditData] = useState(false)
  const [deleteData, setDeleteData] = useState(null)

  const handleAddData = () => {
    setIsOpenCreateModal(true)
  }

  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
      ...paramsObject,
      [e.target.name]: value
    }
    setGetListParams(data)
  }


  const handleOpenEditModal = (id) => {
    let data = handleGetData.data.data.find(x => x.id === id)
    setEditData(data)
    setIsOpenEditModal(true);
  }

  const handleOpenDeleteModal = (id) => {
    setIsOpenDeleteModal(true)
    setDeleteData(id)
  }

  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const deletedSuccess = () => {
    handleCloseForm()
    queryClient.invalidateQueries('listMarksScheme')
    setIsDeletedSuccess(true)
  }

  const handleCloseForm = () => {
    setIsOpenCreateModal(false)
    setIsOpenEditModal(false)
    setIsOpenDeleteModal(false)
  }
  const handlePageClick = (val) => {
    let data = {
        ...paramsObject,
        pageNo: val.selected
    }
    setGetListParams(data)
}

  return (
    <>
      <Layout>
        <div className="grid grid-cols-1 divide-x">
          <div className=' space-y-4 pr-4'>
            <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
              <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search Marks Scheme' />
              <button className='primaryButton' data-type="Standard" onClick={handleAddData}>
                <div className='flex items-center space-x-3'>
                  <PlusIcon className='w-6' />
                  <span>Add MarksScheme</span>
                </div>
              </button>
            </div>
            {
              handleGetData.isLoading ? <Spinner /> : (
                <>
                  <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                      <thead>
                        <tr className="text-left">
                          <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                            Sr.no
                          </th>
                          <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Term Name
                          </th>
                          <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {handleGetData?.data.data.map((studentData, idx) => {
                          const { name, id } = studentData
                          return <tr key={idx}>
                            <td className="border-dashed border-t border-gray-200 userId">
                              <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                {idx + 1}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 firstName">
                              <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                {name}
                              </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 phoneNumber">
                              <div className='flex items-center justify-center'>
                                <div>
                                  <IconButton onClick={() => handleOpenEditModal(id)}>
                                    <EditIcon />
                                  </IconButton>
                                </div>
                                <div>
                                  <IconButton onClick={() => handleOpenDeleteModal(id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  </div>
                    <div className=" my-7">
                      <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetData?.data?.total_count / paramsObject.limit)} pageNo={handleGetData?.data?.page_no} />
                    </div>
                </>
              )
            }

          </div>
        </div>
      </Layout>
      {isOpenCreateModal && <AddMarksScheme open={isOpenCreateModal} onClose={handleCloseForm} />}
      {isOpenEditModal && <EditMarksScheme singleData={editData} open={isOpenEditModal} onClose={handleCloseForm} />}
      {isOpenDeleteModal && <DeleteStudent open={isOpenDeleteModal} id={deleteData} onClose={handleCloseForm} onDelete={deletedSuccess} url={'deleteMarksScheme'} />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Marks Scheme Deleted Succesfully
        </Alert>
      </Snackbar>
    </>
  )
}

export default MarksScheme