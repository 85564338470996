import axios from '../../api/axios'

const IS_REQUIRED = true
const getList = async () => {
    const response = await axios({
        method: "GET",
        url: `/getStandardClassDropdownList`,
    })
    return response.data;
}

const getStandardList = (type = 'standards') => {
    let list = [
        { name: 'Grade 1', value: '1' },
        { name: 'Grade 2', value: '2' },
        { name: 'Grade 3', value: '3' },
    ]

    return list
}

const genderList = [
    { value: 'male', name: 'Male' },
    { value: 'female', name: 'Female' }
]

export const STUDENT_PERSONAL_DETAILS = [
    {
        name: 'first_name',
        placeholder: 'Enter Student First Name',
        label: 'Student First Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'middle_name',
        placeholder: 'Enter Student Middle Name',
        label: 'Student Middle Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'last_name',
        placeholder: 'Enter Student Last Name',
        label: 'Student Last Name',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'mother_name',
        placeholder: "Enter Mother's Name",
        label: `Mother's Name`,
        type: 'text',
        element: 'input',
        errorMessage: 'This Field Cannot Be Empty !',
        required: IS_REQUIRED
    },
    // {
    //     name: 'guardian_name',
    //     placeholder: 'Enter Parent / Guardian Name',
    //     label: 'Parent / Guardian Name',
    //     type: 'text',
    //     element: 'input',
    //     errorMessage: 'This Field Cannot Be Empty !',
    //     required: IS_REQUIRED
    // },
    {
        name: 'address',
        placeholder: 'Enter Address',
        label: 'Address',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'city',
        placeholder: 'Enter City',
        label: 'City',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'pincode',
        placeholder: 'Enter Pincode',
        label: 'Pincode',
        type: 'tel',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'home_no',
        placeholder: "Enter Mother's Number",
        label: "Mother's Number",
        type: 'tel',
        element: 'input',
        required: false
    },
    {
        name: 'office_no',
        placeholder: "Enter Father's Number",
        label: "Father's Number",
        type: 'tel',
        element: 'input',
        required: false
    },
    {
        name: 'mobile_no',
        placeholder: 'Enter Mobile Number',
        label: 'Mobile Number',
        type: 'tel',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'birth_date',
        placeholder: 'Enter Birth Date',
        label: 'Birth Date',
        type: 'date',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'gender',
        placeholder: 'Enter Birth Date',
        label: 'Gender',
        type: 'select',
        element: 'select',
        required: IS_REQUIRED,
        optionValue: genderList,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'place_of_birth',
        placeholder: 'Enter Place of Birth',
        label: 'Place of Birth',
        type: 'text',
        element: 'input',
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'religion',
        placeholder: 'Enter Religion',
        label: 'Religion',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'caste',
        placeholder: 'Enter Caste',
        label: 'Caste',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'sub_caste',
        placeholder: 'Enter Sub-Caste',
        label: 'Sub-Caste',
        type: 'text',
        element: 'input',
        required: false,
    },
    {
        name: 'class_std',
        placeholder: 'Enter Class / Std',
        label: 'Class / Std',
        type: 'text',
        element: 'select',
        optionValue: [],
        required: IS_REQUIRED,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'division',
        placeholder: 'Enter Division',
        label: 'Division',
        type: 'text',
        element: 'select',
        required: false,
        optionValue: [],
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'regi_no',
        placeholder: 'Enter Registration Number',
        label: 'General Registration Number',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'quota',
        placeholder: 'Enter Division',
        label: 'Quota',
        type: 'text',
        element: 'select',
        required: false,
        optionValue: [],
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'house',
        placeholder: 'Enter House',
        label: 'Enter House',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'mother_tongue',
        placeholder: 'Enter Mother Tongue',
        label: 'Mother Tongue',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'aadhar_card',
        placeholder: 'Enter Aadhar Card',
        label: 'Aadhar Card',
        type: 'tel',
        element: 'input',
        required: false,
        maxLength: "12"
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'student_uid',
        placeholder: 'Enter Student Uid',
        label: 'Student UID',
        type: 'text',
        element: 'input',
        required: false,
        // errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'join_year',
        placeholder: 'Enter Join Year',
        label: 'Join Year',
        type: 'text',
        element: 'select',
        required: true,
        optionValue: [],
        errorMessage: 'This Field Cannot Be Empty !',
    },
    // {
    //     name: 'student_status',
    //     placeholder: 'Student Status',
    //     label: 'Student Status',
    //     type: 'text',
    //     element: 'select',
    //     required: IS_REQUIRED,
    //     optionValue: [
    //         { name: 'Active', value: '1' },
    //         { name: 'Left', value: '2' },
    //     ],
    //     errorMessage: 'This Field Cannot Be Empty !',
    // },
]
export const STUDENT_ADMISSION_DETAILS = [
    {
        name: 'fee_book_no',
        placeholder: 'Enter Student Fee Book Number',
        label: 'Student Fee Book Number',
        type: 'text',
        element: 'input',
        required: false,
        required: IS_REQUIRED,
        disabled: true,
        errorMessage: 'This Field Cannot Be Empty !',
        hasAutoGenerate: true
    },
    {
        name: 'admission_fee',
        placeholder: 'Enter Admission Fee',
        label: 'Admission Fee',
        type: 'number',
        element: 'input',
        required: false,
        hasAutoGenerate: false
    },
    {
        name: 'details_concession ',
        placeholder: 'Enter Concession Details',
        label: 'Fee Concession Details',
        type: 'text',
        element: 'textarea',
        required: false,
        hasAutoGenerate: false
    },
]
export const PREVIOUS_SCHOOLS_DETAILS = [
    {
        name: 'previous_school_name',
        placeholder: 'Enter Previous School Name',
        label: 'Previous School Name',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'sch_lc_no',
        placeholder: 'Enter Previous School L.C Number',
        label: 'Previous School L.C Number',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'sch_lc_date',
        placeholder: 'Enter Previous School Leaving Date',
        label: 'Previous School Leaving Date',
        type: 'date',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'reason',
        placeholder: 'Enter Reason',
        label: 'Reason',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'progress',
        placeholder: 'Enter Progress',
        label: 'Progress',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'conduct',
        placeholder: 'Enter Conduct',
        label: 'Conduct',
        type: 'text',
        element: 'input',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    },
    {
        name: 'remark',
        placeholder: 'Enter Remark',
        label: 'Remark',
        type: 'text',
        element: 'textarea',
        required: false,
        errorMessage: 'This Field Cannot Be Empty !',
    }
]


