import { Alert, Dialog, Slide } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner';
import useGetFilterData from './useGetFilterData';
import FeeBook from './FeeBook';
import { getList } from '../../api/collections';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const year = [
    { value: '1', name: '2022-2023' }
]
function CreateFeeBook({ open, handleClose }) {
    const initialDate = {
        class: '',
        quota: '1',
        year: new Date()
    }
    const { setDataToServer, success, onSuccess, error, onError } = useGetFilterData()
    const [filterData, setFilterData] = useState(initialDate)
    const handleFilterChange = (e) => {
        let value = e.target.value
        let data = {
            ...filterData,
            [e.target.name]: value
        }
        setFilterData(data)
    }
    const handleFilterFees = (e) => {
        e.preventDefault()
        onError(false)
        setDataToServer.mutate(filterData)
    }
    const options = {
        standardArray: [],
        yearArray: [],
        quotaArray: [],
    }
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Standard", "Year", "Quota"]
        typeList.forEach((type) => {
            fetchOptionData(type)
        })
    }, [])

    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Standard") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        standardArray: res.data
                    }));
                } else if (type === "Year") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                } else if (type === "Quota") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        quotaArray: res.data
                    }));
                    setFilterData({
                        ...filterData,
                        quota: res.data[0].id
                    })
                }

            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>New Fee Book</h1>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <form onSubmit={handleFilterFees}>
                            <div className=' flex items-end justify-center gap-x-8'>
                                <div className=' basis-[33.33%]'>
                                    <label htmlFor="email" className='label'>Standard</label>
                                    <select value={filterData.class} onChange={handleFilterChange} name="class" className='input'>
                                        <option value="">--Select--</option>
                                        {optionsArray.standardArray?.map((grade, idx) => {
                                            return <option key={idx} value={grade.id}>{grade.name}</option>
                                        })}
                                    </select>
                                </div>
                                {/* <div className=' basis-[33.33%]'>
                                    <label htmlFor="email" className='label'>Quota</label>
                                    <select value={filterData.quota} onChange={handleFilterChange} name="quota" className='input'>
                                        {optionsArray.quotaArray?.map((quota, idx) => {
                                            return <option key={idx} value={quota.id}>{quota.name}</option>
                                        })}
                                    </select>
                                </div> */}
                                <div className=' basis-[33.33%]'>
                                    <label htmlFor="Year" className='label'>Year</label>
                                    <select value={filterData.year} onChange={handleFilterChange} name="year" className='input'>
                                        <option value="">--Select--</option>
                                        {optionsArray.yearArray?.map((year, idx) => {
                                            return <option key={idx} value={year.id}>{year.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="button">
                                    <button className='primaryButton'>Add</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {setDataToServer.isLoading ? <Spinner /> :
                        <div className='mt-10'><FeeBook data={setDataToServer.data} filterData={filterData} /> </div>}
                    {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.response?.data.message}, Please try again later</Alert>}
                </div>
            </Dialog>

        </>
    )
}

export default CreateFeeBook