import { Alert, Dialog, Slide } from '@mui/material'
import React, { useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useFetchList from '../../hooks/useFetchList';
import FormElement from '../../components/Form/FormElement';
import usePostData from '../../hooks/usePostData';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import PrimaryResultView from './PrimaryResultView';
import HighSchoolResultView from './HighSchoolResultView';
import { MAX_LENGTH_FOR_TEXTAREA } from '../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const progrees = [
    { value: 'Excellent', name: 'Excellent' },
    { value: 'Very Good', name: 'Very Good' },
    { value: 'Good', name: 'Good' },
    { value: 'Satisfactory', name: 'Satisfactory' },
    { value: 'Not Satisfactory', name: 'Not Satisfactory' },
]
function ResultDialog({ open, onClose, filterData, finalSem, terms }) {
    const initialResultData = {
        remark: '',
        attendence: '',
        conduct: '',
        progress: '',
        isPromoted: '',
        school_open_at: localStorage.getItem('school_open_at') || ''
    }
    const [resultData, setResultData] = useState(initialResultData)
    const { handlePostData, error, onError, success, onSuccess } = usePostData({
        queryName: 'ResultGenerationStudentList',
        url: 'createResultGeneration',
        onSuccessCb: () => {
            alert('Result Updated Successfully')
        }
    })
    const { handlePostData: dontPromoteNextYear } = usePostData({
        queryName: 'ResultGenerationStudentList',
        url: 'updateStudentDetails',
        onSuccessCb: () => {
            setDontPromoteSuccess(true)
            setDontPromoteError(false)
            setTimeout(() => {
                setDontPromoteSuccess(false)
                // window.location.reload()
            }, 3000)
        },
        onErrorCb: () => {
            setDontPromoteError(true)
            setDontPromoteSuccess(false)
            setTimeout(() => {
                setDontPromoteError(false)
            }, 3000)
        }
    })
    const { handleGetData } = useFetchList('ResultGenerationStudentMarks', filterData)

    useEffect(() => {
        if (handleGetData.isSuccess) {
            const tempData = {
                ...resultData,
                ...handleGetData?.data?.data,
            }
            const schoolReopenDate = tempData['school_open_at'] || (localStorage.getItem('school_open_at') || '')
            setResultData({
                ...tempData,
                school_open_at: schoolReopenDate
            })
        }
    }, [handleGetData.isSuccess])

    const handleSetFromData = (e) => {
        let $this = e.target
        let name = $this.name
        let value = $this.value
        if (name === 'attendence') {
            if (+value > +resultData?.total_days) {
                let data = {
                    ...resultData,
                    attendence: ''
                }
                setResultData(data)
                return
            }
        }
        let data = {
            ...resultData,
            [name]: value
        }
        if (name === 'school_open_at') {
            localStorage.setItem('school_open_at', value)
        }
        setResultData(data)
    }

    const handleSaveResultDetail = (e) => {
        e.preventDefault()
        let lengthOfRemark = resultData?.remarks?.length
        let lengthOfHeadMissRemark = resultData?.headmaster_remarks?.length || 0
        if(lengthOfRemark >= MAX_LENGTH_FOR_TEXTAREA || lengthOfHeadMissRemark >= MAX_LENGTH_FOR_TEXTAREA){
            return alert('Character Limit exceeded')
        }
        let data = {
            ...filterData,
            ...resultData,
            total_marks: handleGetData.data?.data?.Total_Marks,
            percentage: handleGetData.data?.data?.Percentage,
            grade: handleGetData.data?.data?.Avrage_Grade
        }
        handlePostData.mutate(data)
    }

    const [dontPromoteSuccess, setDontPromoteSuccess] = useState(false)
    const [dontPromoteError, setDontPromoteError] = useState(false)
    const handleDontPromote = () => {
        dontPromoteNextYear.mutate({
            ...filterData
        })
    }


    const handleChangeUserStatus = usePostData({ queryName: '', url: 'updateStudentDetails' })
    const [reapeter, setReapeter] = useState('')
    const handleCheckIfReapeater = (e) => {
        setReapeter(e.target.value)
        let data = {
            ...filterData,
            type: e.target.value
        }
        handleChangeUserStatus.handlePostData.mutate(data)
    }

    return (
        <>
            <Dialog disableEscapeKeyDown fullScreen fullWidth={true} open={open} onClose={onClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={onClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div className='flex items-center space-x-5'>
                            <h1 className='heading'>Student Result</h1>
                        </div>
                    </div>
                    <div className=' my-8'>
                        <h1 className='font-semibold text-lg'>Student Name:
                            <span className='font-normal'>{filterData?.student_name}</span>
                            {handleGetData.data?.student_promoted && <span className=' text-orange-700 bg-orange-100 rounded-full px-4 py-1 ml-3 font-semibold'>Promoted</span>}
                        </h1>
                        <p className='font-semibold text-lg'>Standard: <span className='font-normal'>{filterData?.standard_name}</span></p>
                        {console.log(filterData)}
                        <p className='font-semibold text-lg capitalize'>Term: <span className='font-normal'>{
                            terms.find(x => x.id === filterData.term_id)?.name
                        }</span></p>
                    </div>
                    {handleGetData.data?.standard_type_id === '1'
                        ? <PrimaryResultView data={handleGetData.data} />
                        : filterData.standard_name === 'IX' || filterData.standard_name === 'X'
                            ? <>
                                {
                                    handleGetData.isLoading
                                        ? <Spinner />
                                        : <>
                                            <HighSchoolResultView overviewData={handleGetData.data?.data} subjectData={handleGetData.data?.data?.subjectData} />
                                        </>
                                }
                            </>
                            : <>
                                {handleGetData.isLoading
                                    ? <Spinner />
                                    :
                                    <>
                                        <div className='mt-5'>
                                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                                    <thead>
                                                        <tr className="text-center">
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Sr.no
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Subject
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Marks
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Out Of
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                Grade
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                Remarks
                                                            </th>
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                Status
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {handleGetData.data?.data?.subjectData.map((subjectsList, idx) => {
                                                            const { subject_name, remarks, grade, marks, outoff_marks, pass_status } = subjectsList
                                                            return <tr key={idx}>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {idx + 1}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-lg font-semibold">
                                                                        {subject_name}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {marks}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {outoff_marks}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {grade}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                        {remarks || '---'}
                                                                    </span>
                                                                </td>
                                                                <td className="border-dashed border-t border-gray-200 lastName text-center">
                                                                    {pass_status === 'pass'
                                                                        ? <span className="text-green-700 bg-green-200 px-6 py-2 font-semibold text-center text-sm rounded-full uppercase">
                                                                            {pass_status}
                                                                        </span>
                                                                        : <span className="text-red-700 bg-red-200 px-6 py-2 font-semibold text-center text-sm rounded-full uppercase">
                                                                            {pass_status}
                                                                        </span>}
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr className='bg-gray-100 font-semibold tet-lg'>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                                                    Total
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-lg font-semibold">
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                                                    {handleGetData.data?.data?.Total_Marks}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                                                    {handleGetData.data?.data?.Total_Outoff}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                                                    {handleGetData.data?.data?.Avrage_Grade}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                                                    Precentage : {handleGetData.data?.data?.Percentage}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName text-center">
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </>}
                            </>
                    }
                    <div>
                        <form onSubmit={handleSaveResultDetail}>
                            <div>
                                <FormElement
                                    label={"Teacher Remark"}
                                    name={'remarks'}
                                    element={'textarea'}
                                    disabled={
                                        handleGetData.data?.student_promoted
                                    }
                                    value={resultData?.remarks}
                                    onChange={handleSetFromData}
                                    placeholder={'Enter Remark Here...'}
                                />
                                <div className={`text-xs font-semibold ${resultData?.remarks?.length >= MAX_LENGTH_FOR_TEXTAREA ? 'text-red-600' : ''}`}>
                                    Character Count: {resultData?.remarks?.length}/{MAX_LENGTH_FOR_TEXTAREA}
                                </div>
                                {handleGetData.data?.standard_type_id === '1' &&
                                    <>
                                        <FormElement
                                            label={"Head Mistress's Remark"}
                                            name={'headmaster_remarks'}
                                            element={'textarea'}
                                            value={resultData?.headmaster_remarks}
                                            disabled={
                                                handleGetData.data?.student_promoted
                                            }
                                            maxLength={MAX_LENGTH_FOR_TEXTAREA}
                                            onChange={handleSetFromData}
                                            placeholder={'Enter Remark Here...'}
                                        />
                                        <div className={`text-xs font-semibold ${resultData?.headmaster_remarks?.length >= MAX_LENGTH_FOR_TEXTAREA ? 'text-red-600' : ''}`}>
                                            Character Count: {resultData?.headmaster_remarks?.length}/{MAX_LENGTH_FOR_TEXTAREA}
                                        </div>
                                    </>
                                }

                                {
                                    <div className='grid grid-cols-3 gap-4 my-4'>
                                        <FormElement
                                            label={"Conduct"}
                                            name={'conduct'}
                                            element={'select'}
                                            value={resultData?.conduct}
                                            onChange={handleSetFromData}
                                            placeholder={'Enter Conduct Here...'}
                                            disabled={handleGetData.data?.student_promoted}
                                            optionValue={progrees}
                                        />
                                        <FormElement
                                            label={"Progress"}
                                            name={'progress'}
                                            element={'select'}
                                            value={resultData?.progress}
                                            onChange={handleSetFromData}
                                            placeholder={'Enter Progress Here...'}
                                            disabled={handleGetData.data?.student_promoted}
                                            optionValue={progrees}
                                        />
                                        <div className='flex items-center space-x-4 flex-1'>
                                            <div className='w-full'>
                                                <FormElement
                                                    label={"Result Status"}
                                                    name={'result_status'}
                                                    element={'select'}
                                                    value={resultData?.result_status}
                                                    disabled={handleGetData.data?.student_promoted}
                                                    type={'select'}
                                                    onChange={handleSetFromData}
                                                    optionValue={[
                                                        {
                                                            name: 'Promoted',
                                                            value: 'promoted'
                                                        },
                                                        {
                                                            name: 'Promoted with Condonation',
                                                            value: 'promoted with condonation'
                                                        },
                                                        {
                                                            name: 'Detained',
                                                            value: 'detained'
                                                        },
                                                    ]}
                                                    placeholder={'Enter Progress Here...'}
                                                />
                                            </div>
                                            {resultData?.result_status === 'detained' &&
                                                <div className=''>
                                                    <FormElement
                                                        label={"Student Status"}
                                                        name={'result_status'}
                                                        element={'select'}
                                                        value={reapeter}
                                                        disabled={handleGetData.data?.student_promoted}
                                                        type={'select'}
                                                        onChange={handleCheckIfReapeater}
                                                        optionValue={[
                                                            {
                                                                name: 'Repeat',
                                                                value: 'repeat'
                                                            },
                                                            {
                                                                name: 'Left',
                                                                value: 'left'
                                                            },
                                                        ]}
                                                    />
                                                </div>}
                                        </div>
                                    </div>}
                                <div className='grid grid-cols-3 gap-4'>
                                    {finalSem.includes(parseInt(filterData.term_id)) ?
                                        <FormElement
                                            label={"Reopen"}
                                            name={'school_open_at'}
                                            element={'input'}
                                            value={resultData?.school_open_at}
                                            disabled={handleGetData.data?.student_promoted}
                                            type={'date'}
                                            onChange={handleSetFromData}
                                            placeholder={'Enter Progress Here...'}
                                        /> : <></>
                                    }
                                    <div className='flex items-end'>
                                        <div className='w-16'>
                                            <FormElement
                                                label={"Attendence"}
                                                name={'attendence'}
                                                element={'input'}
                                                value={resultData?.attendence}
                                                disabled={handleGetData.data?.student_promoted}
                                                type={'number'}
                                                onChange={handleSetFromData}
                                                placeholder={''}
                                            />
                                        </div>
                                        <p className='ml-2 text-lg mb-[6px]'> / {resultData?.total_days}  </p>
                                    </div>
                                </div>
                            </div>
                            {!handleGetData.data?.student_promoted &&
                                <div className=' text-center mt-8'>
                                    {handlePostData.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
                                </div>}
                            {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}, Please try again later</Alert>}
                            {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Student Added Succesfully</Alert>}
                        </form>
                    </div>
                </div>

            </Dialog>
        </>
    )
}

export default ResultDialog