import React from 'react'
import { getList } from '../../api/collections';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FormElement from '../../components/Form/FormElement';
import usePostData from '../../hooks/usePostData';
import { Alert, Snackbar } from '@mui/material';
import Spinner from '../../components/spinner';
import Cookies from 'universal-cookie';
import { decode } from 'base-64'


const yearChangeOption = [
  {
    name: 'Feebook Structure',
    value: 'feebook'
  },
  {
    name: 'Subject Management',
    value: 'subject_management'
  },
  {
    name: 'Teacher Subject Map',
    value: 'teacher_subject_map'
  }
]
const yearChangeOptionResult = [
  {
    name: 'Subject Management',
    value: 'subject_management'
  },
  {
    name: 'Teacher Subject Map',
    value: 'teacher_subject_map'
  }
]
const yearChangeOptionForFees = [
  {
    name: 'Feebook Structure',
    value: 'feebook'
  },
]

const cookies = new Cookies
const YearChange = () => {
  const userRole = decode(cookies.get("_role_token"));
  const userSubRole = cookies.get("_sub_role");
  // dataTransferToNextYear
  const [searchParams, setSearchParams] = useSearchParams();
  const [resultFilterData, setResultFilterData] = useState({
    from_year_id: '' || searchParams.get('from_year_id'),
    to_year_id: '' || searchParams.get('to_year_id'),
    option: '' || searchParams.get('option')
  })
  const [isTransferSuccess, setIsTransferSuccess] = useState(false)
  const [isTransferError, setIsTransferError] = useState(false)

  const handleTransferSuccess = () => {
    setIsTransferSuccess(true)
    setIsTransferError(false)
    setTimeout(() => {
      setIsTransferSuccess(false)
    }, 4000)
  }

  const handleTransferError = () => {
    setIsTransferError(true)
    setIsTransferSuccess(false)
    setTimeout(() => {
      setIsTransferError(false)
    }, 4000)
  }

  const { handlePostData } = usePostData({
    queryName: 'teacherList',
    url: 'dataTransferToNextYear',
    onSuccessCb: handleTransferSuccess,
    onErrorCb: handleTransferError
  })
  const [optionsArray, setOptionsArray] = useState({ yearArray: [] })


  const fetchOptionData = (type) => {
    getList(type)
      .then(res => {
        if (type === "Year") {
          // let tempId = res.data.filter((i) => i.current_year === "1")
          setOptionsArray(prevState => ({
            ...prevState,
            yearArray: res.data
          }));
          // setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    const typeList = ["Year"]

    typeList.forEach((type) => {
      fetchOptionData(type)
    })

  }, [])

  const handleFilterFromData = (e) => {
    e.preventDefault()
    handlePostData.mutate({
      ...resultFilterData
    })
  }


  const handleSetFromData = (e) => {
    let $this = e.target
    let data = {
      ...resultFilterData,
      [$this.name]: $this.value
    }
    searchParams.set($this.name, $this.value)
    setSearchParams(searchParams)
    setResultFilterData(data)
  }

  return (
    <>
      <div>
        <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
          <div className='grid grid-cols-5 gap-x-4 items-end'>
            <FormElement
              label={"Select From Year"}
              name={'from_year_id'}
              element={'select'}
              required={true}
              value={resultFilterData.from_year_id}
              optionValue={optionsArray.yearArray}
              errorMessage='Please Select To Year!'
              onChange={handleSetFromData}
            />
            <FormElement
              label={"Select To Year"}
              name={'to_year_id'}
              element={'select'}
              required={true}
              value={resultFilterData.to_year_id}
              optionValue={optionsArray.yearArray}
              errorMessage='Please Select To Year!'
              onChange={handleSetFromData}
            />
            {userSubRole.includes("Computer Teacher")
              ? <FormElement
                label={'Option'}
                name={`option`}
                element="select"
                optionValue={yearChangeOptionResult}
                value={userRole === 'fees' ? 'feebook' : resultFilterData.option}
                onChange={handleSetFromData}
              />
              : <FormElement
                label={'Option'}
                name={`option`}
                element="select"
                optionValue={userRole === 'fees' ? yearChangeOptionForFees : userRole === 'result' ? yearChangeOptionResult : yearChangeOption}
                value={userRole === 'fees' ? 'feebook' : resultFilterData.option}
                onChange={handleSetFromData}
              />}
            <div className='flex'>
              <button className='flex items-center justify-center primaryButton w-full'>Transfer Data {handlePostData.isLoading && <Spinner className='w-6 h-6 mx-0 my-0 ml-2' />}</button>
            </div>
          </div>
        </form>
      </div>
      <Snackbar open={isTransferSuccess} autoHideDuration={2000} onClose={() => setIsTransferSuccess(false)}>
        <Alert onClose={() => setIsTransferSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Data Transferred Succesfully.
        </Alert>
      </Snackbar>
      <Snackbar open={isTransferError} autoHideDuration={2000} onClose={() => setIsTransferError(false)}>
        <Alert onClose={() => setIsTransferError(false)} severity="error" sx={{ width: '100%' }}>
          Something went wrong!!
        </Alert>
      </Snackbar>
    </>
  )
}

export default YearChange