import React from 'react'
import Layout from '../../components/Layout'
import { ChevronLeftIcon, DownloadIcon } from '@heroicons/react/solid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import useFetchList from '../../hooks/useFetchList';
import Spinner from '../../components/spinner';
import Pagination from '../../components/paginate';

function StandardFeeBook() {
    const navigate = useNavigate();
    const [searchParams,setSearchParams] = useSearchParams();
    let initialData = {
        standard_id: searchParams.get('_s') || '',
        year_id: searchParams.get('_y') || '',
        page_no: searchParams.get('_p') || 0,
        limit: 10,
    }
    const { handleGetData, paramsObject, setGetListParams } = useFetchList('getStudentFeeBookList', initialData)

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            page_no: val.selected
        }
        searchParams.set('_p', val.selected)
        setSearchParams(searchParams)
        setGetListParams(data)
        
    }

    return (
        <Layout>
            <div className='flex items-center justify-between'>
                <div>
                    <button onClick={() => navigate(-1,{ replace: true })} className='flex items-center hover:underline mt-4'>
                        <ChevronLeftIcon className='w-6 h-6' />
                        <span className='font-semibold'>Back</span>
                    </button>
                </div>
                <div>
                    {handleGetData?.data?.download_all_media && 
                    <a href={handleGetData?.data?.download_all_media} rel="noopener noreferrer" download target="_blank" className='primaryButton'>Download All</a> }
                </div>
            </div>
            <div className='my-6 flex flex-row'>
                  <p className='font-semibold'>Standard: <span className='font-normal'>{searchParams.get('std_name')}</span></p>
                  <p className='font-semibold ml-4'>Year: <span className='font-normal'>{searchParams.get('year')}</span></p>
            </div>
            <div className='mt-4'>
                {handleGetData.isLoading
                    ? <Spinner />
                    : <>
                        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                <thead>
                                    <tr className="text-left">
                                        {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                            Sr.no
                                        </th> */}
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Student Name
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Feebook Number
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Roll Number
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Quota
                                        </th>
                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {handleGetData?.data?.data.map((studentData, idx) => {
                                        const { student_name, quota_name, status, media_path, roll_no,fee_book_no } = studentData
                                        return <tr key={idx}>
                                            {/* <td className="border-dashed border-t border-gray-200 userId">
                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm"> */}
                                                    {/* {(getFormData.limit * (parseInt(getFormData.pageNo) + 1)) - (getFormData.limit - (idx + 1))} */}
                                                    {/* {idx + 1}
                                                </span>
                                            </td> */}
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                    {student_name}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                    {fee_book_no}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                    {roll_no}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                    {quota_name}
                                                </span>
                                            </td>
                                            <td className="border-dashed border-t border-gray-200 lastName flex justify-center items-center py-2">
                                                {status === 'In-Progress' && <span className="px-3 py-2 rounded-full text-center text-xs font-semibold inprogress_report">
                                                    {status}
                                                </span>}
                                                {/* {status === 'Completed' && <button onClick={() => navigate(-1)} className='flex items-center primaryButton space-x-2'>
                                                    <DownloadIcon className='w-6 h-6' />
                                                    <span className='font-semibold text-sm'>Download</span>
                                                </button>} */}
                                                {status === 'Completed' && <a href={media_path} download target="_blank" className='flex justify-center w-max items-center primaryButton space-x-2'>
                                                    <DownloadIcon className='w-6 h-6' />
                                                    <span className='font-semibold text-sm'>Download</span>
                                                </a>}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className=" my-7">
                            <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetData?.data.total_count / paramsObject.limit)} pageNo={handleGetData?.data?.page_no} />
                        </div>
                    </>
                }
            </div>
        </Layout>
    )
}

export default StandardFeeBook