import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ChevronLeftIcon, PlusIcon } from '@heroicons/react/solid'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../components/Layout'
import AddDetails from './AddDetails'
import EditDetails from './EditDetails';
import EditTecher from './EditTecher';
import { useGetData } from '../../hooks/useGetData'
import Spinner from '../../components/spinner';
import useFetchList from '../../hooks/useFetchList';
import DeleteItem from '../../components/deleteItem';
import { Alert, Snackbar } from '@mui/material';
import { getList } from '../../api/collections';
import { useSearchParams } from 'react-router-dom';
const initialState = {
  standard_id: "2",
  section_id: "2",
  year_id: "2",
  subject_id: "1"
}

const TeacherProfile = () => {
  const [teacherDetailsLoading, setTeacherDetailsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams()
  const initialFilterState = {
    id:id,
    year_id: searchParams.get('year_id') || ''
  }
  const navigate = useNavigate()
  const [tableSelected, setTableSelected] = useState("subject")
  const [isAddDetailModalOpen, setIsAddDetailModalOpen] = useState(false)
  const [isEditDetailModalOpen, setIsEditDetailModalOpen] = useState(false)
  const [editData, setEditData] = useState(initialState)
  const [dataId, setDataId] = useState(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  // const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)
  const [editTeacherData, setEditTeacherData] = useState({})
  const [isOpenEditTeacherModal, setIsOpenEditTeacherModal] = useState(false)
  // teacher type for edit teacher
  const [teacherTypeData, setTeacherTypeData] = useState([])
  const [teacherTypeList, setTeacherTypeList] = useState([])
  const [isFetchingTeacherType, setIsLoadingTeacherType] = useState(false)

  const { handleGetData, setGetListParams, paramsObject } = useFetchList('teacherProfileDetails', initialFilterState, (res) => {
    setIsLoadingTeacherType(true)
    getList('TeacherType')
      .then(teacherRes => {
        let tempTerm = []
        teacherRes.data?.map((it) => {
          return tempTerm.push({
            value: it.id,
            label: it.type_name
          })
        })

        // Check already selected teacher type 
        let data = res?.data[0].teacher_type
        let tempTypeData = data?.map((it) => { return { value: it, label: tempTerm.find(e => e.value === it)?.label } })
        setTeacherTypeData(tempTypeData)
        setTeacherTypeList(tempTerm);
        setTeacherDetailsLoading(false)
      })
      .finally(() => {
        setIsLoadingTeacherType(false)
      })
  })
  const handleOpenDeleteModal = (e, data, type) => {
    setOpenDeleteModal(true)
    setDataId({
      ...data,
      teacher_id: id,
    })
  }
  const handleOpenEditTeacherModal = () => {
    setEditTeacherData(handleGetData?.data?.data[0])
    setIsOpenEditTeacherModal(true)
  }

  const addClass = () => {
    setIsAddDetailModalOpen(true);
  }

  const addSubject = () => {
    setIsAddDetailModalOpen(true);
  }

  const handleEditClass = (e, data) => {
    e.stopPropagation();
    let element = e.currentTarget
    let action = element.getAttribute('data-action')
    if (action === 'edit') {
      setEditData(data)
    } else {
    }
    setIsEditDetailModalOpen(true)
  }

  const handleEditSubject = (e, data) => {
    e.stopPropagation();
    let element = e.currentTarget
    let action = element.getAttribute('data-action')
    if (action === 'edit') {
      setEditData(data)
    } else {
    }
    setIsEditDetailModalOpen(true)
  }

  const handleClose = () => {
    setIsAddDetailModalOpen(false);
    setIsEditDetailModalOpen(false);
    setIsOpenEditTeacherModal(false)
    setEditData(null)
  }
  const [yearOptionsData, setYearOptionsData] = useState([])
  const fetchOptionsData = () => {
    getList('Year')
      .then(res => {
        setYearOptionsData(res.data);
      })
  }
  useEffect(() => {
    fetchOptionsData();
  }, [])


  const handleFilterYear = (value) => {
    let data = {
      ...paramsObject,
      year_id: value
    }
    setSearchParams(data)
    setGetListParams(data)
  }

  return (
    <>
      <Layout>
        <div className='flex w-max mb-6 items-center mt-8 hover:underline underline-offset-2 hover:cursor-pointer' onClick={() => navigate('/teachers')}>
          <ChevronLeftIcon className='w-5' />
          <p className='text-lg ml-2'>Teachers</p>
        </div>
        {
          handleGetData.isLoading ? (
            <Spinner />
          ) : (
            <>
              <div className='flex justify-between mt-8'>
                <h1 className='text-2xl font-bold'>{handleGetData.data?.data[0].name}</h1>
                <button className='primaryButton disabled:cursor-not-allowed' disabled={teacherDetailsLoading} onClick={handleOpenEditTeacherModal}>
                  <div className='flex items-center space-x-3'>
                    <span>Edit</span>
                  </div>
                </button>
              </div>
              <div className='grid overflow-hidden grid-cols-1 lg:grid-cols-2 auto-rows-auto mb-8 gap-3 gap-x-6 mt-8'>
                <div className='flex justify-between'>
                  <p className='label'>Email: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].email}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>Phone: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].phone}</p>
                </div>

                <div className='flex justify-between'>
                  <p className='label'>Qualification: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].qualification}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>Address: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].address}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>City: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].city}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>Pincode: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].pincode}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>State: </p>
                  <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0].state}</p>
                </div>
                <div className='flex justify-between'>
                  <p className='label'>Teacher Type: </p>
                  {isFetchingTeacherType
                    ? <p className='label'>Loading...</p>
                    : <p className='text-sm text-gray-600 text-right'>{handleGetData.data?.data[0]?.teacher_type?.map((type, idx) => {
                      let teacherVal = teacherTypeList?.find(x => x.value === type)
                      return <span key={idx}>{teacherVal?.label}, </span>
                    })}</p>}
                </div>
              </div>
              <div>
                {!handleGetData.data?.data[0]?.teacher_type ? '' : 
                <>
                 <div className=''>
                  {handleGetData.data?.data[0]?.teacher_type?.map((type, idx) => {
                    return type === '3' ? null
                      : type === '2' ? <p onClick={() => setTableSelected('subject')} className={`py-2 px-3 ${tableSelected === 'subject' ? 'bg-[color:var(--primarycolor)] text-white' : 'bg-gray-200 text-black'} mr-3 rounded-full hover:cursor-pointer inline-block `}>Subject</p>
                        : type === '1' ? <p onClick={() => setTableSelected('class')} className={`py-2 px-3   ${tableSelected === 'class' ? 'bg-[color:var(--primarycolor)] text-white' : 'bg-gray-200 text-black'} mr-3 rounded-full hover:cursor-pointer inline-block `}>Class</p>
                          : null
                  })}

                  {handleGetData.data?.data[0]?.teacher_type[0] === '3'
                    ? null
                    : <div className='mt-10'>
                      <div className='flex items-end justify-between'>
                        <div className=' basis-[33.33%]'>
                          <label className='label'>Select Year: </label>
                          <select name="year_id" className='input' value={paramsObject.year_id} onChange={(e) => handleFilterYear(e.target.value)}>
                            <option value="">--Select--</option>
                            {yearOptionsData?.map((year, idx) => {
                              return <option key={idx} value={year.id}>{year.name}</option>
                            })}
                          </select>
                        </div>
                        {tableSelected === "subject" ? (
                          <button className='primaryButton' onClick={addSubject}>
                            <div className='flex items-center space-x-3'>
                              <PlusIcon className='w-6' />
                              <span>Add</span>
                            </div>
                          </button>
                        ) : (
                          <button className='primaryButton' onClick={addClass}>
                            <div className='flex items-center space-x-3'>
                              <PlusIcon className='w-6' />
                              <span>Add</span>
                            </div>
                          </button>
                        )}

                      </div>
                      {
                        tableSelected === "subject" ? (
                          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative my-6">
                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                              <thead>
                                <tr className="text-left">
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Sr.no
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Subject Name
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Standard
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Year
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {handleGetData.data?.subject_data.map((subjectData, idx) => {
                                  const { subject_id, subject_name, standard_name, year_name } = subjectData
                                  return <tr key={idx} className="hover:bg-gray-50 hover:cursor-pointer">
                                    <td className="border-dashed border-t border-gray-200 userId">
                                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {idx + 1}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {subject_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {standard_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {year_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 p">
                                      <div className='flex items-center justify-center'>
                                        <div data-action='edit' onClick={(e) => handleEditSubject(e, subjectData)}>
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </div>
                                        <div data-action='delete' onClick={(e) => handleOpenDeleteModal(e, subjectData, 'subject-delete')}>
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative my-6">
                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                              <thead>
                                <tr className="text-left">
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                    Sr.no
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Class Name
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Standard
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Year
                                  </th>
                                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {handleGetData.data?.class_data.map((classData, idx) => {
                                  const { section_name, standard_name, year_name } = classData
                                  return <tr key={idx} className="hover:bg-gray-50 hover:cursor-pointer">
                                    <td className="border-dashed border-t border-gray-200 userId">
                                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                        {idx + 1}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {section_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {standard_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 firstName">
                                      <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {year_name}
                                      </span>
                                    </td>
                                    <td className="border-dashed border-t border-gray-200 p">
                                      <div className='flex items-center justify-center'>
                                        <div data-action='edit' onClick={(e) => handleEditClass(e, classData)}>
                                          <IconButton>
                                            <EditIcon />
                                          </IconButton>
                                        </div>
                                        <div data-action='delete' onClick={(e) => handleOpenDeleteModal(e, classData, 'class-delete')}>
                                          <IconButton>
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                })}
                              </tbody>
                            </table>
                          </div>
                        )
                      }
                    </div>}
                </div>
                </>
                }

              </div>
            </>
          )
        }
      </Layout>
      {isAddDetailModalOpen && <AddDetails open={isAddDetailModalOpen} onClose={handleClose} teacherId={id} selected={tableSelected} />}
      {isOpenEditTeacherModal && <EditTecher singleData={editTeacherData} open={isOpenEditTeacherModal} onClose={handleClose} teacherTypeData={teacherTypeData} teacherTypeList={teacherTypeList} />}
      {openDeleteModal && <DeleteItem
        url={`${tableSelected === "subject" ? '/teacherDeleteSubjectMapping' : '/teacherDeleteClassMapping'}`}
        queryKey={'subjectList'}
        onDelete={() => setIsDeletedSuccess(true)} open={openDeleteModal}
        deleteData={{ ...dataId }}
        onError={() => { setIsDeletedError(true); setIsDeletedSuccess(false) }}
        onClose={() => setOpenDeleteModal(false)}
      />}
      {isEditDetailModalOpen && <EditDetails open={isEditDetailModalOpen} onClose={handleClose} teacherId={id} selected={tableSelected} editData={editData} />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          {`${tableSelected === "subject" ? 'Subject' : 'Class'}`} Deleted Succesfully
        </Alert>
      </Snackbar>
      <Snackbar open={isDeletedError} autoHideDuration={2000} onClose={() => setIsDeletedError(false)}>
        <Alert onClose={() => setIsDeletedError(false)} severity="error" sx={{ width: '100%' }}>
          Something went wrong!!
        </Alert>
      </Snackbar>
    </>
  )
}

export default TeacherProfile