import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const useGetData = (url, { onSuccess, queryName }) => {
    const queryClient = useQueryClient()
    const [getFormData, setGetFormData] = useState('')
    let handleGetPostList = useMutation((data) => fetchPosts(data), {
        onError: (err) => { },
        onSuccess: (res) => {
            queryClient.invalidateQueries(queryName)
            if (onSuccess) {
                onSuccess(res)
            }
        },
    })
    const fetchPosts = async (getFormData) => {
        let TOKEN = cookies.get('_pn_token');
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { getFormData, setGetFormData, handleGetPostList }
}

export { useGetData } 