import React, { useState } from 'react'
import Layout from '../../components/Layout'
import YearChange from './YearChange'
import YearMaster from './YearMaster'

const defaultTabs = [
  {
    name: "Year Change",
    tab: 0,
  },
  {
    name: "Year Master",
    tab: 1
  },
]

const Year = () => {
  const [tabs, setTabs] = useState(0)
  return (
    <Layout>
      <div className='flex flex-row space-x-2 h-max border-b'>
        {
          defaultTabs.map((t) => {
            const { name, tab } = t
            return (
              <div className={`p-3 font-semibold text-lg cursor-pointer ${tabs === tab ? 'text-[color:var(--primarycolor)] border-b-2 border-[color:var(--primarycolor)]' : ''}`} onClick={() => setTabs(tab)}>
                {name}
              </div>
            )
          })
        }
      </div>
      <div className='mt-4'>
        {
          tabs === 0 ? (
            <YearChange />
          ) : (
            <YearMaster />
          )
        }
      </div>

    </Layout>
  )
}

export default Year