import React, { useState } from 'react'
import Layout from '../../components/Layout'
import { PlusIcon } from '@heroicons/react/solid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddStandard from './AddStandard';
import EditStandard from './EditStandard';
import useGetData from './useGetData'
import DeleteStandard from './DeleteStandard';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import Pagination from '../../components/paginate';
import { useSearchParams } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function StandardAndSection() {
    const [searchParams, setSearchParams] = useSearchParams();
    const standartInitialState = {
        limit: 10,
        pageNo: searchParams.get('_std_p') || 0,
        type: 'Standard'
    }
    const sectionInitialState = {
        limit: 1,
        pageNo: searchParams.get('_sec_p') || 0,
        type: 'Section'
    }
    const [name, setName] = useState('');
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const [type, setType] = useState('')
    const [dataId, setDataId] = useState('')
    const [singleData, setSingleData] = useState({})
    const handleAddData = (e) => {
        e.stopPropagation();
        let formType = e.currentTarget.getAttribute('data-type')
        setType(formType)
        setOpenCreateModal(true)
    }
    const getStandardList = useGetData(standartInitialState, 'getStandardList')
    const getSectionList = useGetData(sectionInitialState, 'getSectionList')

    const handleEditModal = (e, name) => {
        e.stopPropagation();
        let element = e.currentTarget
        let formType = element.getAttribute('data-type')
        let id = element.getAttribute('data-id')
        let action = element.getAttribute('data-action')

        setType(formType)
        setDataId(id)
        if (action === 'edit') {
            if (formType === 'Standard') {
                let data = getStandardList.handleGetDataList.data?.data?.find(x => x.id === id)
                setSingleData(data)
            } else {
                let data = getStandardList.handleGetDataList.data?.data?.find(x => x.id === id)
                setSingleData(data)
            }
            setOpenEditModal(true)
            setName(name);
        } else {
            setOpenDeleteModal(true)
        }
    }




    const handlePageStandardClick = (val) => {
        let data = {
            ...getStandardList.getFormData,
            pageNo: val.selected
        }
        searchParams.set("_std_p",val.selected)
        setSearchParams(searchParams)
        getStandardList.setGetFormData(data)

    }
    const handlePageSectionClick = (val) => {
        let data = {
            ...getSectionList.getFormData,
            pageNo: val.selected
        }
        searchParams.set("_sec_p",val.selected)
        setSearchParams(searchParams)
        getSectionList.setGetFormData(data)

    }
    return (
        <>
            <Layout>
                <div className="grid grid-cols-2 divide-x">
                    <div className=' space-y-4 pr-4'>
                        <div className=' text-right'>
                            <button className='primaryButton' data-type="Standard" onClick={handleAddData}>
                                <div className='flex items-center space-x-3'>
                                    <PlusIcon className='w-6' />
                                    <span>Add Standard</span>
                                </div>
                            </button>
                        </div>
                        {getStandardList?.handleGetDataList?.isLoading ? <Spinner />
                            : getStandardList?.handleGetDataList?.status === "error" ? <Errors errorObject={getStandardList?.handleGetDataList?.error} inline /> :
                                <>
                                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Sr.no
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Standard
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getStandardList?.handleGetDataList?.data?.data.map((standardList, idx) => {
                                                    const { name, id } = standardList
                                                    return <tr key={idx}>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{(getStandardList.getFormData.limit * (parseInt(getStandardList.getFormData.pageNo) + 1)) - (getStandardList.getFormData.limit - (idx + 1))}</span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{name}</span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                            <div className='flex items-center justify-center'>
                                                                <div data-type="Standard" data-action='edit' data-id={id} onClick={(e) => handleEditModal(e, name)}>
                                                                    <IconButton>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </div>
                                                                <div data-type="Standard" data-action='delete' data-id={id} onClick={handleEditModal}>
                                                                    <IconButton>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" my-7">
                                        <Pagination onPageChange={handlePageStandardClick} pageCount={Math.ceil(getStandardList.handleGetDataList?.data.total_count / getStandardList.getFormData.limit)} pageNo={getStandardList.handleGetDataList?.data?.page_no }/>
                                    </div>
                                </>
                        }
                    </div>
                    <div className=' space-y-4 pl-4'>
                        <div className=' text-right'>
                            <button className='primaryButton' data-type="Section" onClick={handleAddData}>
                                <div className='flex items-center space-x-3'>
                                    <PlusIcon className='w-6' />
                                    <span>Add Section</span>
                                </div>
                            </button>
                        </div>
                        {getSectionList?.handleGetDataList?.isLoading ? <Spinner />
                            : getSectionList?.handleGetDataList?.status === "error" ? <Errors errorObject={getSectionList?.handleGetDataList?.error} inline /> :
                                <>
                                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                        <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                            <thead>
                                                <tr className="text-left">
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Sr.no
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                        Section
                                                    </th>
                                                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getSectionList?.handleGetDataList?.data?.data.map((standardList, idx) => {
                                                    const { name, id } = standardList
                                                    return <tr key={idx}>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{idx + 1}</span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                            <span className="text-gray-700 px-6 py-3 flex items-center text-sm">{name}</span>
                                                        </td>
                                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                            <div className='flex items-center justify-center'>
                                                                <div data-type="Section" data-action='edit' data-id={id} onClick={handleEditModal}>
                                                                    <IconButton>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </div>
                                                                <div data-type="Section" data-action='delete' data-id={id} onClick={handleEditModal}>
                                                                    <IconButton>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className=" my-7">
                                        <Pagination onPageChange={handlePageSectionClick} pageCount={Math.ceil(getSectionList.handleGetDataList?.data.total_count / getSectionList.getFormData.limit)} pageNo={getSectionList.handleGetDataList?.data?.page_no}/>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </Layout>
            {openCreateModal && <AddStandard type={type} open={openCreateModal} handleClose={() => setOpenCreateModal(false)} />}
            {openEditModal && <EditStandard singleData={singleData} type={type} id={dataId} open={openEditModal} name={name} handleClose={() => setOpenEditModal(false)} />}
            {openDeleteModal && <DeleteStandard open={openDeleteModal} type={type} onDelete={() => setIsDeletedSuccess(true)} id={dataId} onClose={() => setOpenDeleteModal(false)} />}
            <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {type} Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default StandardAndSection