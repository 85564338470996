import { XIcon } from '@heroicons/react/solid';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import FormElement from '../../components/Form/FormElement';
import Spinner from '../../components/spinner';
import { useQueryClient, useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import axios from '../../api/axios';
const cookies = new Cookies();

function DownloadFees({ open, handleClose,options,student_id }) {
    const queryClient = useQueryClient()
    const initialData = {
        student_id:student_id,
        year_id:''
    }
    const [formData,setFormData] = useState(initialData)
    const handleSetFromData = (e) => {
        let data = {
            ...formData,
            year_id: e.target.value
        }
        setFormData(data)
    }
    const handleSaveFromData = (e) => {
        e.preventDefault()
        reportDownload.mutate(formData)
    }

    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: (data) => {
            const href = URL.createObjectURL(new Blob([data.data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = 'student_fee_reciept.pdf';
            a.click();
        },
        onError: () => {
        }
    })

    const downloadReports = async (data) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in data) {
            fd.append(key, data[key]);
        }
        let res = await axios(
            {
                method: "POST",
                url: `/generateFeeReceipt`,
                responseType: 'blob',
                data: fd,
                headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : ''}
            })
        return res
    };
    

    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Download Fees</h2>
                            <XIcon onClick={handleClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                            <FormElement 
                            label={'Select Year'}
                            name={'year'} 
                            element='select' 
                            required 
                            value={formData.year}
                            optionValue={options.yearArray}
                            errorMessage='This Field Cannot Be Empty !' 
                            onChange={handleSetFromData}
                            />
                            {reportDownload.isLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Download</button>
                                </div>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DownloadFees