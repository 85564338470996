import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner'
import useCreateFeeBook from './useCreateFeeBook'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function FeeBook({ data, filterData }) {
  const initialData = {
    ...filterData,
    term_id: '',
    particulars_data: []
  }
  const [feeData, setFeeData] = useState(initialData)
  const [selectedTab, setSelectedTab] = useState(1)
  const { setDataToServer, error, onError, success, onSuccess } = useCreateFeeBook('/createFeesBook')
  const handleSelectTab = (idx, termId) => {
    setSelectedTab(idx)
    let data = {
      ...feeData,
      term_id: termId
    }
    setFeeData(data)
  }
  const handleSaveFeesBook = (e) => {
    let arrData = []
    e.preventDefault()
    let $this = e.target
    for (let i = 0; i < $this.elements.length; i++) {
      if ($this.elements[i].matches('input[type="checkbox"]')) {
        if ($this.elements[i].checked) {
          let checkedInput = $this.elements[i]
          let uniqueId = checkedInput.getAttribute('data-inputid')
          arrData.push({
            particular_id: checkedInput.getAttribute('data-id'),
            amount: document.getElementById(uniqueId).value
          })
        }
      }
    }
    let data = {
      ...feeData,
      particulars_data: JSON.stringify(arrData)
    }
    setDataToServer.mutate(data)
  }
  useEffect(() => {
    let temp = {
      ...feeData,
      term_id: data?.term[0].id
    }
    setFeeData(temp)
  },[])
  return (
    <>
      <ul className='flex items-center justify-center space-x-4 mt-4'>
        {data?.term.map((item, idx) => {
          const { name, id } = item
          return <li onClick={() => handleSelectTab(idx + 1, id)} key={idx} className={`editStudent-tab  ${idx + 1 === selectedTab && 'active'}`}>{name}</li>
        })}
      </ul>
      <div className='tab-container my-10'>
        {data?.term.map((termData, idx) => {
          return <div key={idx} className={`tab-content presonalDetails divide-y ${selectedTab === idx + 1 && 'active'}`}>
            <form data-termid={termData.id} onSubmit={handleSaveFeesBook} className='divide-y'>
              {data?.particulars.map((item, itemId) => {
                const { id, name } = item
                const uniqueId = id + itemId + idx
                return <div key={itemId} className='flex items-center py-4'>
                  <input type="checkbox" data-inputid={uniqueId} data-id={id} />
                  <div className='flex-1 text-xl capitalize ml-2'>{name}</div>
                  <div>
                    <input id={uniqueId} type="text" placeholder='Enter Amount' className='input' />
                  </div>
                </div>
              })}
              <div className='text-center pt-10'>
                {setDataToServer.isLoading ? <Spinner /> : <button className='btn4'>Save</button>}
              </div>
            </form>
          </div>
        })}
      </div>
      <Snackbar open={success} autoHideDuration={3000} onClose={() => onSuccess(false)}>
        <Alert severity="success" onClose={() => onSuccess(false)} sx={{ width: '100%' }}>
          Fees Structure Added Succesfully
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={3000} onClose={() => onError(false)}>
        <Alert severity="error" onClose={() => onError(false)} sx={{ width: '100%' }}>
          Oops! There Was An Error While Creating Fees Structure, Please Try again
        </Alert>
      </Snackbar>
    </>
  )
}

export default FeeBook