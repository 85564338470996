import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Pagination from '../../components/paginate';
import IconButton from '@mui/material/IconButton';
import Spinner from '../../components/spinner'
import useFetchList from '../../hooks/useFetchList'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/solid'
import { Alert, Snackbar } from '@mui/material'
import Errors from '../errors'
import DeleteIcon from '@mui/icons-material/Delete';
import AddFeeUser from './AddFeeUser';
import EditIcon from '@mui/icons-material/Edit';
import EditTecher from '../Teachers/EditTecher';
import EditFeeUser from './EditFeeUser';
import DeleteItem from '../../components/deleteItem';

const FeeUserManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialState = {
    limit: 10,
    pageNo: searchParams.get('_p') || 0,
    searchQuery: '',
  }
  const navigate = useNavigate()
  const [openAddModal, setOpenAddModal] = useState(false)
  const handleOpenAddStudent = () => {
    setOpenAddModal(true)
  }
  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)
  const { handleGetData, paramsObject, setGetListParams } = useFetchList('staffList', initialState)
  const handleModalClose = () => {
    setOpenAddModal(false)
    setOpenDeleteModal(false)
    setIsOpenEditModal(false)
    setEditData({})
  }
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      pageNo: val.selected
    }
    setSearchParams({ ...searchParams, '_p': val.selected })
    setGetListParams(data)
  }
  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
      ...paramsObject,
      [e.target.name]: value
    }
    setGetListParams(data)
  }

  const [editData, setEditData] = useState({})
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const handleOpenEditModal = (id) => {
    let data = handleGetData.data.data.find(x => x.staff_id === id)
    setEditData(data)
    setIsOpenEditModal(true)
  }
  const [dataId, setDataId] = useState(0)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleOpenDeleteModal = (e, id) => {
    e.stopPropagation();
    setOpenDeleteModal(true)
    setDataId(id)
  }
  return (
    <Layout>
      <div className=''>
        <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
          <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search Fees Staff' />
          <button className='primaryButton' onClick={handleOpenAddStudent}>
            <div className='flex items-center space-x-3'>
              <PlusIcon className='w-6' />
              <span>Add Fee Staff</span>
            </div>
          </button>
        </div>
        {handleGetData?.isLoading || handleGetData.isFetching ? <Spinner />
          : handleGetData?.status === "error" ? <Errors errorObject={handleGetData?.error} inline /> :
            <>
              <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Fee Staff Name
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Email
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Phone Number
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Qualification
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {handleGetData?.data.data.map((studentData, idx) => {
                      const { name, qualification, email, phone, staff_id } = studentData
                      return <tr key={idx} className="hover:bg-gray-50 hover:cursor-pointer">
                        <td className="border-dashed border-t border-gray-200 userId">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {(paramsObject.limit * (paramsObject.pageNo + 1)) - (paramsObject.limit - (idx + 1))}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                            {name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                            {email}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                            {phone}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 lastName">
                          <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                            {qualification}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                          <div className='flex items-center justify-center'>
                            <div>
                              <IconButton onClick={() => handleOpenEditModal(staff_id)}>
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div>
                              <IconButton onClick={(e) => handleOpenDeleteModal(e, staff_id)}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>
              <div className=" my-7">
                <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetData?.data.total_count / paramsObject.limit)} pageNo={handleGetData?.data?.page_no} />
              </div>
            </>
        }
      </div>
      {openAddModal && <AddFeeUser open={openAddModal} onClose={handleModalClose} />}
      {isOpenEditModal && <EditFeeUser singleData={editData} open={isOpenEditModal} onClose={handleModalClose} />}
      {openDeleteModal && <DeleteItem
        url={`/deleteStaff`}
        onDelete={() => setIsDeletedSuccess(true)}
        onError={() => setIsDeletedError(true)}
        queryKey={'staffList'}
        open={openDeleteModal}
        deleteData={{ staff_id: dataId }}
        onClose={() => setOpenDeleteModal(false)}
      />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          User Deleted Succesfully
        </Alert>
      </Snackbar>
      <Snackbar open={isDeletedError} autoHideDuration={3000} onClose={() => setIsDeletedError(false)}>
        <Alert onClose={() => setIsDeletedError(false)} severity="error" sx={{ width: '100%' }}>
          Something went wrong!!
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default FeeUserManagement