import axios from './axios'
import Cookies from "universal-cookie";
const cookies = new Cookies(); 

export const getList = async(type = 'Section',id,screen) => {
    let fd = new FormData()
    let TOKEN = cookies.get("_pn_token");
    if(screen){
        fd.append('screen_name',screen)
    }
    fd.append('type',type)
    if(type === 'Term'){
        if(id) fd.append('standard_id',id)
    }else{
        if(id) fd.append('standardTypeId',id)
    }
    const response = await axios({ 
        method: "POST", 
        url: `/getStandardClassDropdownList`,
        data: fd,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data;
}