import { useState } from "react"

function FormElement(props) {
    const { label, element = 'input', onChange, className, errorMessage, optionValue, info = '', ...inputProps } = props
    const [focused, setFocused] = useState(false)
    const handleFocused = () => {
        setFocused(true)
    }
    const elements = () => {
        switch (element) {
            case 'input':
                return <input {...inputProps} className='input' focused={focused.toString()} onChange={onChange} onBlur={handleFocused} />
            case 'select':
                return <select {...inputProps} className='input' focused={focused.toString()} onChange={onChange} onBlur={handleFocused}>
                    <option value="" selected>--Select--</option>
                    {optionValue?.map((item, idx) => {
                        return <option key={idx} value={item.value || item.id}>{item.name}</option>
                    })}
                </select>
            case 'datalist':
                return <>
                    <input className='input' list="remarks" name="remarks" id="remarks" />
                    <datalist {...inputProps} focused={focused.toString()} id="remarks" onChange={onChange} onBlur={handleFocused}>
                        {optionValue?.map((item, idx) => {
                            return <option key={idx} value={item.value || item.id}>{item.name}</option>
                        })}
                    </datalist>
                </>
            case 'textarea':
                return <textarea className='input min-h-[15rem]' onChange={onChange} focused={focused.toString()} onBlur={handleFocused} {...inputProps}></textarea>
            default:
                break;
        }
    }
    return (
        <div className={className}>
            {label && <label htmlFor={label} className='label'>{label}</label>}
            {props.required && <span className='mandatory'>*</span>}
            {info && <p className='text-red-600 text-xs font-semibold'>{info}</p>}
            {elements()}
            <small className=" text-red-700 font-semibold errorMessage mt-1">{errorMessage}</small>
        </div>
    )
}

export default FormElement