import { useQuery } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function useFetchList(url, initialData, onSuccess = () => { }) {
    const [paramsObject, setGetListParams] = useState(initialData)
    const handleGetData = useQuery([url, paramsObject], () => getUsers(paramsObject), {
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (res) => {
            onSuccess?.(res)
        }
    })
    const getUsers = async (paramsObject) => {
        let TOKEN = cookies.get('_pn_token');
        let fd = new FormData()
        for (var key in paramsObject) {
            fd.append(key, paramsObject[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { paramsObject, setGetListParams, handleGetData }
}

export default useFetchList