import { XIcon } from '@heroicons/react/solid'
import { Alert, Modal } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import FormElement from '../../components/Form/FormElement'
import Spinner from '../../components/spinner'
import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from '../../api/axios'
import Cookies from "universal-cookie";
import useFetchList from '../../hooks/useFetchList'
import { useSearchParams } from 'react-router-dom'
const cookies = new Cookies();


function DownloadResult({ open, onClose, filterData }) {
    const [searchParams, setSearchParams] = useSearchParams();
    let filterFormData = {
        ...filterData,
        report_type: '',
        subject_id: ''
    }
    const initialState = {
        limit: 10,
        pageNo: 0,
        search_query: '',
        standard_id: searchParams.get('standard_id')
    }
    const [downloadFilterFormData, setDownloadFilterFormData] = useState(filterFormData)
    // New api for getting the subject list according to the year_id, standard_id would be given..
    const { handleGetData, paramsObject, setGetListParams } = useFetchList('subjectList', filterData)
    let options = [
        { value: 'gradesheet', name: 'Grade Sheet' },
        // { value: 'greensheet', name: 'Green Sheet' },
        { value: 'marksStdWiseExam', name: 'Standard Wise Subject List' },
        { value: 'subjectWiseReport', name: 'Individual Subject Wise Report' },
    ]
    const handleSetFromData = (e) => {
        let data = {
            ...downloadFilterFormData,
            [e.target.name]: e.target.value
        }
        setDownloadFilterFormData(data)
    }

    const [noSafe, setNotSafe] = useState(filterData['term_id'] === "" && filterData['standard_id'] === '' && filterData['year_id'] === '')
    const handleSaveFromData = (e) => {
        e.preventDefault()
        reportDownload.mutate(downloadFilterFormData)
    }

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: (data) => {
            setSuccess(true)
            const href = URL.createObjectURL(new Blob([data.data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = 'student_result.pdf';
            a.click();
        },
        onError: () => {
            setError(true)
        }
    })

    const downloadReports = async (data) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in data) {
            fd.append(key, data[key]);
        }
        let res = await axios(
            {
                method: "POST",
                url: `/getResultReport`,
                responseType: 'blob',
                data: fd,
                headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '' }
            })
        return res
    };
    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Download Result Report</h2>
                            <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                            <FormElement
                                label={'Select Result Type'}
                                element={'select'}
                                name={'report_type'}
                                optionValue={options}
                                value={downloadFilterFormData.report_type}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                                onChange={handleSetFromData}
                            />
                            {
                                downloadFilterFormData['report_type'] === 'subjectWiseReport' && (
                                    <FormElement
                                        label={'Select Subject Type'}
                                        element={'select'}
                                        name={'subject_id'}
                                        optionValue={!handleGetData.isSuccess ? [] : handleGetData?.data.data.map((it) => { return { name: it.subject_name, value: it.subject_id } })}
                                        value={downloadFilterFormData.subject_id}
                                        required={true}
                                        errorMessage='This Field Cannot Be Empty !'
                                        onChange={handleSetFromData}
                                    />
                                )
                            }
                            {noSafe &&
                                <>
                                    <div className=' text-center'>
                                        <small className='font-semibold text-red-700'>Please Select Standard, Year and Term</small>
                                    </div>
                                </>
                            }
                            {reportDownload.isLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full disabled:cursor-not-allowed' disabled={noSafe}>Download Result Report</button>
                                </div>}
                            {error && <Alert onClose={() => setError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>Not Able To Download The Result Please Try Again Later</Alert>}
                            {success && <Alert onClose={() => setSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Result Download Succesfully</Alert>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default DownloadResult