import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import DeleteIcon from '@mui/icons-material/Delete';
import { PlusIcon } from '@heroicons/react/solid';
import AddStudent from './AddStudent';
import useGetStudent from './useGetStudent';
import Spinner from '../../components/spinner';
import Pagination from '../../components/paginate';
import DeleteStudent from './DeleteStudent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useQueryClient } from "@tanstack/react-query";
import EditStudent from './EditStudent';
import Errors from '../errors';
import { getList } from '../../api/collections';
import DownloadFees from './DownloadFees';
import { useSearchParams } from 'react-router-dom';
import { decode } from 'base-64';
import Cookies from 'universal-cookie';
import FormElement from '../../components/Form/FormElement';
import usePostData from '../../hooks/usePostData';
import axios from '../../api/axios';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const cookies = new Cookies();
const downloadReport = async (data) => {
    let fd = new FormData()
    let TOKEN = cookies.get("_pn_token");
    for (var key in data) {
        fd.append(key, data[key]);
    }
    const response = await axios({
        method: "POST",
        url: `/getStudentMasterReport`,
        responseType: 'blob',
        data: fd,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data;
}
const downloadReportExcel = async (data) => {
    let fd = new FormData()
    let TOKEN = cookies.get("_pn_token");
    for (var key in data) {
        fd.append(key, data[key]);
    }
    const response = await axios({
        method: "POST",
        url: `/getStuddentMasterReportExcel`,
        responseType: 'blob',
        data: fd,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data;
}

function Student() {
    const queryClient = useQueryClient()
    let userRole = decode(cookies.get("_role_token"));
    const [reportLoading, setReportLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const initialState = {
        limit: 10,
        pageNo: searchParams.get('_p') || 0,
        searchQuery: '',
        class: '',
        division: '',
        year_id: searchParams.get('year_id') || '',
        standard_id: searchParams.get('standard_id') || ''
    }
    const [resultFilterData, setResultFilterData] = useState({ year_id: '' })
    const [openAddModal, setOpenAddModal] = useState(false)
    const [student_id, setStudent_id] = useState('')
    const { getFormData, setGetFormData, handleGetStudentList } = useGetStudent(initialState)
    const handleOpenAddStudent = () => {
        setOpenAddModal(true)
    }
    const handleModalClose = () => {
        setOpenAddModal(false)
        setOpenDeleteModal(false)
        setIsOpenEditModal(false)
        setOpenFeeModal(false)
        setEditData({})
    }

    const handlePageClick = (val) => {
        let data = {
            ...getFormData,
            pageNo: val.selected
        }
        setSearchParams({ ...searchParams, '_p': val.selected })
        setGetFormData(data)

    }
    const [dataId, setDataId] = useState(0)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setDataId(id)
    }

    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const deletedSuccess = () => {
        handleModalClose()
        queryClient.invalidateQueries('getStudentList')
        setIsDeletedSuccess(true)
    }

    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (id) => {
        setIsOpenEditModal(true)
        let data = handleGetStudentList.data.data.find(x => x.student_id === id)
        setEditData(data)
    }

    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...getFormData,
            [e.target.name]: value
        }
        setGetFormData(data)
    }
    const options = {
        standardArray: [],
        divisionArray: [],
        yearArray: [],
        quotaArray: [],
    }
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Standard", "Section", "Year", "Quota"]

        typeList.forEach((type) => {
            fetchOptionData(type)
        })

    }, [])

    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Standard") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        standardArray: res.data
                    }));
                    setResultFilterData({ ...resultFilterData, standard_id: searchParams.get('standard_id') })
                } else if (type === "Section") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        divisionArray: res.data
                    }));
                } else if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                    setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
                } else if (type === "Quota") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        quotaArray: res.data
                    }));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const [isGenerating, setIsGenerating] = useState(false)
    const generateFeeRecit = async (data) => {
        let fd = new FormData()
        let TOKEN = cookies.get("_pn_token");
        for (var key in data) {
            fd.append(key, data[key]);
        }
        const response = await axios({
            method: "POST",
            url: `/generateStudentFeeReceipt`,
            responseType: 'blob',
            data: fd,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data;
    }
    const [openFeeModal, setOpenFeeModal] = useState(false)
    const handleOpenFeesModal = (id, year_id, standard_id) => {
        // console.log(getFormData)
        if (!getFormData.year_id || !getFormData.standard_id) return alert(`Please Select Year and Standard`)
        setIsGenerating(true)
        let data = {
            student_id: id,
            year_id: getFormData.year_id,
            standard_id: getFormData.standard_id
        }
        generateFeeRecit(data)
            .then((res) => {
                // setOpenFeeModal(true)
                // alert('Feebook Generated Successfully')
                // console.log(res)
                const href = window.URL.createObjectURL(res);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'student_feebook.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setReportLoading(false)
            })
            .finally(() => {
                setIsGenerating(false)
            })
        // setStudent_id(id)
    }

    const handleSetFromData = (e) => {
        let $this = e.target
        let data = {
            ...resultFilterData,
            [$this.name]: $this.value
        }
        searchParams.set($this.name, $this.value)
        setSearchParams(searchParams)
        setResultFilterData(data)
    }

    // const { handlePostData: generateReport } = usePostData({ queryName: '', url: 'getStuddentMasterReport' })

    const handleFilterFromData = (e) => {
        e.preventDefault()
        let data = {
            ...getFormData,
            ...resultFilterData,
            pageNo: 0
        }
        setGetFormData(data)
        setSearchParams(data)
        if (e.nativeEvent.submitter.name === 'report') {
            setReportLoading(true)
            downloadReport(data)
                .then((res) => {
                    const href = window.URL.createObjectURL(res);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'student_report.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setReportLoading(false)
                })
                .catch((err) => {
                    setReportLoading(false)
                })
        }else if(e.nativeEvent.submitter.name === 'reportExcel'){
            setReportLoading(true)
            downloadReportExcel(data)
                .then((res) => {
                    const href = window.URL.createObjectURL(res);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'student_report.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setReportLoading(false)
                })
                .catch((err) => {
                    setReportLoading(false)
                })
        }
    }
    return (
        <>
            {isGenerating && <div className='fixed w-full h-full z-[9999] bg-[#ffffff73] flex items-center justify-center'><Spinner /></div>}
            <Layout>
                <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
                    <div className='flex flex-row gap-x-4 items-end'>
                        <FormElement
                            label={"Select Year"}
                            name={'year_id'}
                            element={'select'}
                            required={true}
                            value={resultFilterData.year_id}
                            optionValue={optionsArray.yearArray}
                            errorMessage='Please Select Year!'
                            onChange={handleSetFromData}
                        />
                        <FormElement
                            label={"Select Standard"}
                            name={'standard_id'}
                            element={'select'}
                            required={true}
                            value={resultFilterData.standard_id}
                            optionValue={[{ id: 'All', name: 'All' }, ...optionsArray.standardArray]}
                            errorMessage='Please Select Year!'
                            onChange={handleSetFromData}
                        />
                        <div className='flex flex-1 justify-between'>
                            <button className='primaryButton' name='fetch'>Fetch Data</button>
                            <div className='flex items-center space-x-3'>
                                {(userRole !== 'teachers' && userRole !== 'fees') &&
                                <button className='flex flex-row primaryButton' name='report'>
                                    {reportLoading ? <Spinner className='w-5 h-5 mx-0 my-0 ml-2' /> : 'Generate Report PDF'}
                                </button> }
                                <button className='flex flex-row primaryButton' name='reportExcel'>
                                    {reportLoading ? <Spinner className='w-5 h-5 mx-0 my-0 ml-2' /> : 'Generate Report Excel'}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className=''>
                    <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
                        <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search Student By First Name, Middle Name, Last Name and Fee Book...' />
                        {
                            userRole !== 'teachers'   ? (
                                <button className='primaryButton' onClick={handleOpenAddStudent}>
                                    <div className='flex items-center space-x-3'>
                                        <PlusIcon className='w-6' />
                                        <span>Add Student</span>
                                    </div>
                                </button>
                            ) : <></>
                        }
                    </div>
                    {handleGetStudentList?.isLoading ? <Spinner />
                        : handleGetStudentList?.status === "error" ? <Errors errorObject={handleGetStudentList?.error} inline /> :
                            <>
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Student Name
                                                </th>
                                                {userRole === 'fees'
                                                    ? null
                                                    : <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Roll Number
                                                    </th>}
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Grade/Std.
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Division
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Fee Book No.
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Is New Admission
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Download Fee Receipt
                                                </th>
                                                {
                                                    userRole !== "teachers" ? <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                        Action
                                                    </th> : <></>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {handleGetStudentList?.data.data.map((studentData, idx) => {
                                                const { student_name, roll_no, division_name, standard_name, student_status, is_new_admission, student_id, fee_book_no,left_status } = studentData
                                                return <tr key={idx}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {(getFormData.limit * (parseInt(getFormData.pageNo) + 1)) - (getFormData.limit - (idx + 1))}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 block text-left text-sm">
                                                            {student_name}
                                                            {
                                                                left_status === '1' && (
                                                                    <span className='text-xs text-yellow-500 w-max ml-2 py-[0.125rem] px-[0.25rem] rounded-full bg-yellow-100 fontbold'>Left</span>
                                                                )
                                                            }
                                                        </span>
                                                    </td>
                                                    {userRole === 'fees'
                                                        ? null
                                                        : <td className="border-dashed border-t border-gray-200 lastName">
                                                            <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                {roll_no}
                                                            </span>
                                                        </td>}
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {standard_name}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                            {division_name}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                            {fee_book_no}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm capitalize">
                                                            {is_new_admission}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center justify-center py-2'>
                                                            <button className='primaryButton' onClick={() => handleOpenFeesModal(student_id, resultFilterData.year_id, resultFilterData.standard_id)}>
                                                                <div className='flex items-center space-x-3'>
                                                                    <FileDownloadOutlinedIcon className='w-6' />
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    {
                                                        userRole !== "teachers" ? (
                                                            <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                                <div className='flex items-center justify-center'>
                                                                    <div>
                                                                        <IconButton onClick={() => handleOpenEditModal(student_id)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                    <div>
                                                                        <IconButton onClick={() => handleOpenDeleteModal(student_id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        ) : <></>
                                                    }
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className=" my-7">
                                    <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetStudentList?.data.total_count / getFormData.limit)} pageNo={handleGetStudentList?.data?.page_no} />
                                </div>
                            </>
                    }
                </div>
            </Layout>
            {openFeeModal && <DownloadFees student_id={student_id} options={optionsArray} open={openFeeModal} handleClose={handleModalClose} />}
            {openAddModal && <AddStudent options={optionsArray} open={openAddModal} handleClose={handleModalClose} />}
            {isOpenEditModal && <EditStudent year_id={resultFilterData.year_id} options={optionsArray} open={isOpenEditModal} handleClose={handleModalClose} data={editData} />}
            {openDeleteModal && <DeleteStudent year_id={resultFilterData.year_id} open={openDeleteModal} id={dataId} onClose={handleModalClose} onDelete={deletedSuccess} url={'deleteStudent'} />}
            <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Student Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default Student