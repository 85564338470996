import { XIcon } from '@heroicons/react/solid'
import { Alert, Checkbox, FormControlLabel, Modal } from '@mui/material'
import React, { useState } from 'react'
import FormElement from '../../components/Form/FormElement'
import Spinner from '../../components/spinner'
import usePostData from '../../hooks/usePostData'

function AddTerm({ open, onClose }) {
    const initialState = {
        name: '',
        ispromote: ''
    }
    const [formData, setFormData] = useState(initialState)
    const { handlePostData, error, success, onError, onSuccess } = usePostData({ queryName: 'termList', url: 'createTerm' })
    const handleSetFromData = (e) => {
        let value = e.target.value
        let data = {
            ...formData,
            [e.target.name]: value
        }
        setFormData(data)
    }
    const handleSaveFromData = (e) => {
        e.preventDefault()
        handlePostData.mutate({...formData,ispromote: formData['ispromote'] ? "1" : "0"})
    }
    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Add Term</h2>
                            <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                            <FormElement
                                label='Term'
                                element={'input'}
                                placeholder={`Enter Term`}
                                name={'name'}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                                onChange={handleSetFromData}
                            />
                            {/* <FormElement
                                label='Term'
                                element={'input'}
                                placeholder={`Enter Term`}
                                name={'ispromote'}
                                required={true}
                                errorMessage='This Field Cannot Be Empty !'
                                onChange={handleSetFromData}
                            /> */}
                            <FormControlLabel
                                control={<Checkbox checked={formData['ispromote']} onChange={(e) => setFormData({...formData, ispromote: e.target.checked})}/>}
                                label={"Promotion"}
                                labelPlacement="end"
                            />
                            {handlePostData.isLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Save</button>
                                </div>}
                            {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
                            {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Subject Added Succesfully</Alert>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddTerm