import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Login from './pages/auth/Login';
import Dashboard from './pages/dasboard';
import Student from './pages/students';
import ProtectedRoutes from './routes/ProtectedRoutes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import StandardAndSection from './pages/Standard';
import Fees from './pages/fees';
import Particulars from './pages/particulars';
import "react-datepicker/dist/react-datepicker.css";
import FeeBookGeneration from './pages/feeBook';
import StandardFeeBook from './pages/feeBook/StandardFeeBook';
import Teacher from './pages/Teachers';
import Subjects from './pages/subjects';
import Receipts from './pages/receipts';
import Report from './pages/report';
import { createContext, useState } from 'react';
import Terms from './pages/terms';
import MarksScheme from './pages/marksScheme';
import StandardManagement from './pages/standardManagement';
import TeacherProfile from './pages/Teachers/TeacherProfile';
import MarksEntry from './pages/marksEntry';
import MarksEntrySubject from './pages/marksEntry/MarksEntrySubject';
import Results from './pages/results';
import MarksEntryStudentList from './pages/marksEntry/MarksEntryStudentList';
import MarksEntryStudentMarks from './pages/marksEntry/MarksEntryStudentMarks';
import Attendance from './pages/attendance';
import RollNumbers from './pages/rollNumbers';
import YearMaster from './pages/Year/YearMaster';
import Year from './pages/Year';
import { Info } from '@mui/icons-material';
import Ledger from './pages/Ledger';
import FeeUserManagement from './pages/FeeUserManagement';
import SchoolReopen from './pages/schoolReopen';
import { ADMIN } from './config';
import FeeDate from './pages/fees/FeeDate';

const queryClient = new QueryClient()
function App() {
  return (
    <>
    {/* <div className='hpanel'>

      <img src='https://www.thehpanel.com/images/h-panel-logo.png' alt="hpanel_logo" />
      <h1 className=' text-xl font-semibold mt-2'>Server bandwidth has been exhausted! Contact Admin</h1>
    </div> */}
      {/* <div className='flex justify-center items-center fixed text-sm py-1 font-semibold top-0 z text-center w-full bg-red-50 text-red-600 z-[10000]'>
        <Info className='w-3 h-3 mr-2' />
        <h1 >This is a Testing Environment.</h1>
      </div> */}
      <div className='relative'>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Routes>
              <Route path='/' exact element={<Login />} />
              <Route path='/dashboard' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees', 'result']}><Dashboard /></ProtectedRoutes>} />
              <Route path='/students' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers', 'class_teacher', 'fees', 'result']}><Student /></ProtectedRoutes>} />
              <Route path='/teachers' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers', 'class_teacher', 'result']}><Teacher /></ProtectedRoutes>} />
              <Route path='/teachers/:id' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers', 'class_teacher', 'result']}><TeacherProfile /></ProtectedRoutes>} />
              <Route path='/standard-and-section' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees', 'result']}><StandardAndSection /></ProtectedRoutes>} />
              <Route path='/fees' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><Fees /></ProtectedRoutes>} />
              <Route path='/fees-payment-date' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><FeeDate /></ProtectedRoutes>} />
              <Route path='/fee-user-management' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><FeeUserManagement /></ProtectedRoutes>} />
              <Route path='/particulars' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><Particulars /></ProtectedRoutes>} />
              <Route path='/fee-book-generation' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><FeeBookGeneration /></ProtectedRoutes>} />
              <Route path='/standard-fee-book' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><StandardFeeBook /></ProtectedRoutes>} />
              <Route path='/subjects' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers', 'class_teacher', 'result']}><Subjects /></ProtectedRoutes>} />
              <Route path='/receipts' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><Receipts /></ProtectedRoutes>} />
              <Route path='/roll-numbers' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees', 'result','teachers']}><RollNumbers /></ProtectedRoutes>} />
              <Route path='/report' exact element={<ProtectedRoutes accessTo={[ADMIN, 'fees']}><Report /></ProtectedRoutes>} />
              <Route path='/terms' exact element={<ProtectedRoutes accessTo={[ADMIN, 'result']}><Terms /></ProtectedRoutes>} />
              <Route path='/attendance' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers','result']}><Attendance /></ProtectedRoutes>} />
              <Route path='/marks-scheme' exact element={<ProtectedRoutes accessTo={[ADMIN, 'result']}><MarksScheme /></ProtectedRoutes>} />
              <Route path='/standard-management' exact element={<ProtectedRoutes accessTo={[ADMIN, 'result']}><StandardManagement /></ProtectedRoutes>} />
              <Route path='/marks-entry' exact element={<ProtectedRoutes accessTo={[ADMIN,'teachers', 'class_teacher']}><MarksEntry /></ProtectedRoutes>} />
              <Route path='/marks-entry/subject' exact element={<ProtectedRoutes accessTo={[ADMIN,'teachers', 'class_teacher']}><MarksEntrySubject /></ProtectedRoutes>} />
              <Route path='/results' exact element={<ProtectedRoutes accessTo={[ADMIN, 'result', 'teachers']}><Results /></ProtectedRoutes>} />
              <Route path='/marks-entry/students' exact element={<ProtectedRoutes accessTo={[ADMIN,'teachers', 'class_teacher']}><MarksEntryStudentList /></ProtectedRoutes>} />
              <Route path='/marks-entry/students/marks' exact element={<ProtectedRoutes accessTo={[ADMIN,'teachers', 'class_teacher']}><MarksEntryStudentMarks /></ProtectedRoutes>} />
              <Route path='/year' exact element={<ProtectedRoutes accessTo={[ADMIN, 'teachers', 'fees']}><Year /></ProtectedRoutes>} />
              <Route path='/ledger' exact element={<ProtectedRoutes accessTo={[ADMIN,'fees']}><Ledger /></ProtectedRoutes>} />
              <Route path='/school-reopen' exact element={<ProtectedRoutes accessTo={[ADMIN,'result','teachers', 'class_teacher']}><SchoolReopen /></ProtectedRoutes>} />
            </Routes>
          </Router>
        </QueryClientProvider>

      </div>
    </>
  );
}

export default App;
