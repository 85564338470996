import React from 'react'

function Widgets({ title, count, icon }) {
    return (
        <div className='bg-white p-4 rounded-md text-gray-700 shadow'>
            <p className='text-xl font-semibold text-gray-700'>{title}</p>
            <div className='flex items-center justify-between mt-8'>
                <h1 className='text-4xl font-semibold'>{count}</h1>
                <div className='w-8 h-8'>
                    {icon}
                </div>
            </div>
        </div>
    )
}

export default Widgets