import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getList } from '../../api/collections'
import Layout from '../../components/Layout'
import Cookies from "universal-cookie";
import axios from '../../api/axios';
import Spinner from '../../components/spinner';

const initialState = {
  standard_type: '',
  standard_id: '',
  from_date: new Date(),
  till_date: new Date(),
  term_id: [],
  payment_status: 'paid',
  report_type: '',
  year_id: '1'
}

const optionsState = {
  standardTypeOptions: [],
  standardOptions: [],
  termOptions: [],
  yearOptions: [],
}

const cookies = new Cookies();
export const downloadReport = async (data) => {
  let fd = new FormData()
  let TOKEN = cookies.get("_pn_token");
  for (var key in data) {
    fd.append(key, data[key]);
  }
  const response = await axios({
    method: "POST",
    url: `/getStudentReport`,
    responseType: 'blob',
    data: fd,
    headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
  })
  return response.data;
}

const Report = () => {

  const [filterData, setFilterData] = useState(initialState);
  const [optionsArray, setOptionsArray] = useState(optionsState)
  const [loading, setLoading] = useState(false)

  const handleTermChange = (value) => {
    if (value) {
      const temp = []
      value.map((it) => {
        return temp.push(it.value)
      });
      setFilterData(prevState => ({
        ...prevState,
        term_id: temp
      }))
    }
  }

  const handleYearChange = (e) => {
    // if(value){
    //   const temp = []
    //   value.map((it) => {
    //     return temp.push(it.value)
    //   });
    setFilterData(prevState => ({
      ...prevState,
      year_id: e.target.value
    }))
    // }
  }

  const handleStandardTypeChange = (e) => {
    setFilterData(prevState => ({
      ...prevState,
      standard_type: e.target.value
    }));
    setOptionsArray(prevOptions => ({
      ...prevOptions,
      standardOptions: [],
    }));
    getList("Standard", e.target.value)
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          standardOptions: res.data
        }));
      })
  }

  const handleStandardIdChange = (e) => {
    setFilterData(prevState => ({
      ...prevState,
      standard_id: e.target.value
    }));
    setOptionsArray(prevOptions => ({
      ...prevOptions,
      termOptions: [],
    }));
    getList("Term", e.target.value)
      .then(res => {
        let tempTerm = []
        res.data?.map((it) => {
          return tempTerm.push({
            value: it.id,
            label: it.name
          })
        })
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          termOptions: tempTerm
        }));
      })
  }

  const handleGenerateForm = (e) => {
    e.preventDefault();
    const data = {
      ...filterData,
      term_id: JSON.stringify(filterData.term_id)
    }
    setLoading(true)
    downloadReport(data)
      .then((res) => {
        const href = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filterData.payment_status === 'paid' ? ` ${filterData.report_type}.pdf` : 'unpaid_list.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if(err.response.status){
          alert('No data found')
          return
        }
        alert('Something went wrong please try again later')
      })
  }

  useEffect(() => {
    getList("StandardType", '', 'REPORT')
      .then(res => {
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          standardTypeOptions: res.data
        }));
      })
  }, []);

  useEffect(() => {
    getList("Year")
      .then(res => {
        let tempId = res.data.filter((i) => i.current_year === "1")
        setOptionsArray(prevOptions => ({
          ...prevOptions,
          yearOptions: res.data
        }));
        setFilterData({ ...filterData, year_id: tempId[0]?.id })
      })
  }, []);

  const [isStdTypeLoading,setStdTypeLoading] = useState(false)
  const handleChangePaymentStatus = (e) => {
    let value = e.target.value
    let name = e.target.name
    let cashValue = optionsArray.standardTypeOptions
    if (value === 'unpaid') {
      let dataa = cashValue.filter(x => x.id !== '4')
      setOptionsArray(prev => ({ ...prev, standardTypeOptions: dataa }))
    } else {
      setStdTypeLoading(true)
      getList("StandardType", '', 'REPORT')
        .then(res => {
          setOptionsArray(prevOptions => ({
            ...prevOptions,
            standardTypeOptions: res.data
          }));
        })
        .finally(() => {
          setStdTypeLoading(false)
        })
    }
    setFilterData(prev => ({ ...prev, [name]: value }))
  }

  return (
    <Layout>
      {isStdTypeLoading && 
      <div className='fixed w-full h-full top-0 left-0 bg-[#ffffff6e] flex items-center justify-center z-50'>
        <Spinner/>
      </div>}
      <div className='mt-4'>
        <h1 className='heading'>Generate Report</h1>
        <form onSubmit={handleGenerateForm}>
          <div className='grid grid-cols-4 gap-4'>
            <div className=' basis-[33.33%]'>
              <label htmlFor="Year" className='label'>Year</label>
              <select value={filterData.year_id} onChange={handleYearChange} name="year_id" className='input' required={true}>
                <option value="">--Select--</option>
                {optionsArray.yearOptions?.map((year, idx) => {
                  return <option key={idx} value={year.id}>{year.name}</option>
                })}
              </select>
            </div>
            <div className=' basis-[33.33%]' style={{ flex: '1 0 21%' }}>
              <label htmlFor="standardType" className='label'>Standard Type</label>
              <select value={filterData.standard_type} onChange={handleStandardTypeChange} name="standard_type" className='input' required={true}>
                <option value="">--Select--</option>
                {optionsArray.standardTypeOptions?.map((std, idx) => {
                  return <option key={idx} value={std.id}>{std.name}</option>
                })}
              </select>
            </div>
            <div className=' basis-[33.33%]' style={{ flex: '1 0 21%' }}>
              <label htmlFor="standard_id" className='label'>Standard</label>
              <select value={filterData.standard_id} onChange={handleStandardIdChange} name="standard_id" className='input' disabled={filterData.standard_type === "4" || filterData.standard_type === "" ? true : false}>
                <option value="">--Select--</option>
                {optionsArray.standardOptions?.map((standard_id, idx) => {
                  return <option key={idx} value={standard_id.id}>{standard_id.name}</option>
                })}
              </select>
            </div>
            <div className='basis-[33.33%]' style={{ flex: '1 0 21%' }}>
              <label htmlFor="standardType" className='label'>Term</label>
              <Select
                isMulti
                options={optionsArray.termOptions}
                onChange={handleTermChange}
                isDisabled={filterData.standard_id === "" ? true : false}
              />
            </div>
            <div>
              <label htmlFor="payment_status" className='label'>Payment Status</label>
              {/* (e) => setFilterData(prev => ({ ...prev, payment_status: e.target.value })) */}
              <select name="payment_status" className='input' value={filterData.payment_status} onChange={handleChangePaymentStatus} required={true}>
                <option value="">--Select--</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
              </select>
            </div>
            <div>
              <label htmlFor="report_type" className='label'>Report Type</label>
              <select name="report_type" className='input' value={filterData.report_type} onChange={(e) => setFilterData(prev => ({ ...prev, report_type: e.target.value }))} required={true}>
                <option value="">--Select--</option>
                {filterData.standard_type === '4' ? '' :
                  <>
                    <option value="standard_wise_collection">Standard Wise Collection</option>
                  </>
                }
                <option value="student_wise_collection">Student Wise Collection</option>
                {filterData.payment_status !== 'unpaid' && <option value="date_wise_collection">Date Wise Collection</option>}
              </select>
            </div>
            <div className=' basis-[33.33%]' style={{ flex: '1 0 21%' }}>
              <label htmlFor="standardType" className='label'>From Date</label>
              <input type="date" disabled={filterData.payment_status === 'unpaid'} name="payment_date" value={filterData.from_date} onChange={(e) => setFilterData(prev => ({ ...prev, from_date: e.target.value }))} required={true} className="input py-[0.425rem]" />
            </div>
            <div className=' basis-[33.33%]' style={{ flex: '1 0 21%' }}>
              <label htmlFor="standardType" className='label'>Till Date</label>
              <input type="date" disabled={filterData.payment_status === 'unpaid'} name="payment_date" value={filterData.till_date} onChange={(e) => setFilterData(prev => ({ ...prev, till_date: e.target.value }))} required={true} className="input py-[0.425rem]" />
            </div>

            <div className="button self-end mb-[1px]">
              <button className='primaryButton flex items-center disabled:cursor-not-allowed' disabled={loading}>Generate Report {loading ?
                <div className='ml-4'>
                  <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </div> :
                null}</button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default Report