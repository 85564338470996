import React from 'react'

const HighSchoolResultView = ({ subjectData,overviewData }) => {
    const { main_subject, graded_subject } = subjectData
    return (
        <div className='mt-5'>
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-center">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Subject
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                <div className=' space-y-2'>
                                    <p className=' space-x-4'>
                                        <span>Max Marks</span>
                                    </p>
                                    <p className=' space-x-4'><span>Written</span>  <span>Int Evl</span></p>
                                </div>
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                <div className=' space-y-2'>
                                    <p className=' space-x-4'>
                                        <span>Max Obtained</span>
                                    </p>
                                    <p className=' space-x-4'><span>Written</span>  <span>Int Evl</span></p>
                                </div>
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                <span>Total</span>
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                <span>Grade</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {main_subject?.map((subjectsList, idx) => {
                            const { subject_name, written_marks, written_outoff_marks, sub_subject, total_marks, grade } = subjectsList
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className={`text-gray-700 px-6 py-3 block text-center text-lg ${sub_subject ? ' font-normal' : 'font-semibold'} `}>
                                        {subject_name}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <div className='flex items-center justify-center'>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {written_outoff_marks || '----'}
                                        </span>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {subjectsList['i/e_outoff_marks'] || '----'}
                                        </span>
                                    </div>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <div className='flex items-center justify-center'>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {written_marks || '----'}
                                        </span>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {subjectsList['i/e_marks'] || '----'}
                                        </span>
                                    </div>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {total_marks || '----'}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {grade || '---'}
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                    <tbody>
                        {graded_subject?.map((subjectsList, idx) => {
                            const { subject_name, written_marks, written_outoff_marks, sub_subject, total_marks, grade } = subjectsList
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className={`text-gray-700 px-6 py-3 block text-center text-lg ${sub_subject ? ' font-normal' : 'font-semibold'} `}>
                                        {subject_name}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <div className='flex items-center justify-center'>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {written_outoff_marks || '----'}
                                        </span>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {subjectsList['i/e_outoff_marks'] || '----'}
                                        </span>
                                    </div>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <div className='flex items-center justify-center'>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {written_marks || '----'}
                                        </span>
                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                            {subjectsList['i/e_marks'] || '----'}
                                        </span>
                                    </div>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {total_marks || '----'}
                                    </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 lastName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {grade || '---'}
                                    </span>
                                </td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                        <tr className='bg-gray-100 font-semibold tet-lg'>
                            <td className="border-dashed border-t border-gray-200 lastName">
                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                    Total
                                </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                                <span className="text-gray-700 px-6 py-3 block text-center text-lg font-semibold">
                                </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                                <div className='flex items-center justify-center'>
                                    <span className="text-gray-700 px-1 py-3 text-center tet-lg">
                                    {overviewData.Total_Marks}
                                    </span> 
                                    /
                                    <span className="text-gray-700 px-1 py-3 text-center tet-lg">
                                    {overviewData.Total_Outoff}
                                    </span> 
                                </div>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName">
                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                {overviewData.Percentage}
                                </span>
                            </td>
                            {/* <td className="border-dashed border-t border-gray-200 lastName">
                                <span className="text-gray-700 px-6 py-3 block text-center tet-lg">
                                </span>
                            </td>
                            <td className="border-dashed border-t border-gray-200 lastName text-center">
                            </td> */}
                        </tr>
                    </tfoot>
                </table>

            </div>
        </div>
    )
}

export default HighSchoolResultView