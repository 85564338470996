import React, { useEffect, useState } from 'react'
import Sidebar from '../sidebar'
import "./layout.css"
import Header from '../Header'
import { getList } from '../../api/collections';

function Layout({ children }) {

    return (
        <div className='layout'>
            <Sidebar />
            <div className='content-wrapper ml-[17rem]'>
                <Header />
                <div className='px-8'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout
