import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { ChevronLeftIcon } from '@heroicons/react/solid';
import EditIcon from '@mui/icons-material/Edit';
import Spinner from '../../components/spinner';
import Layout from '../../components/Layout';
import { useLocation, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import useFetchList from '../../hooks/useFetchList';

const MarksEntrySubject = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate();
  const { handleGetData } = useFetchList("teacherSubjectListing", JSON.parse(localStorage.getItem("marksEntryQuery")))

  return (
    <Layout >
      <div className='container_xxl mb-10'>
        <div className='flex items-center mt-8'>
          <div>
            <button onClick={() => { navigate(-1,{ replace: true }) }} className='flex items-center hover:underline'>
              <ChevronLeftIcon className='w-6' />
              <span className='text-lg'>Back</span>
            </button>
          </div>
        </div>
        <div className='my-6 flex flex-row'>
          <p className='font-semibold'>Subject: <span className='font-normal'>{searchParams.get('std')}</span></p>
          <p className='font-semibold ml-4'>Year: <span className='font-normal'>{searchParams.get('year')}</span></p>
        </div>
        {
          handleGetData?.isLoading ? <Spinner /> : (
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-6">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Sr.no
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                      Subject Name
                    </th>
                    <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {handleGetData?.data?.data.map((subjectData, idx) => {
                    const { subject_name, subject_id } = subjectData

                    return <tr key={idx} onClick={() => {
                      navigate(
                        `/marks-entry/students?sub=${subject_name}&std=${searchParams.get('std')}&year=${searchParams.get('year')}`, 
                        { state: { standard_type_id: '1' } 
                      });
                      localStorage.setItem("marksEntryQuery", JSON.stringify({ ...JSON.parse(localStorage.getItem("marksEntryQuery")), subject_id }))
                    }} >
                      <td className="border-dashed border-t border-gray-200 userId">
                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                          {idx + 1}
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 firstName">
                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                          {subject_name}
                        </span>
                      </td>
                      <td className="border-dashed border-t border-gray-200 phoneNumber">
                        <div className='flex items-center justify-center'>
                          <div>
                            <IconButton>
                              <EditIcon />
                            </IconButton>
                          </div>
                        </div>
                      </td>
                    </tr>
                  })
                  }
                  {
                    handleGetData?.data?.data?.length === 0 && (
                      <tr className="bg-white border-b hover:cursor-pointer hover:bg-gray-50">
                        <td className="border-dashed border-t border-gray-200 text-center py-3" colSpan={3}>No Subjects Mapped...</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          )
        }

      </div>
    </Layout>
  )
}

export default MarksEntrySubject