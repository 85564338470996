import axios from 'axios';

// const BASE_URL = 'http://192.168.1.35/';
// const BASE_URL = 'https://sapi.pandayschool.in/';
const BASE_URL = 'https://api.pandayschool.in/';
// const BASE_URL = 'http://pandeyapi.local.com/';

export default axios.create({
    baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    // withCredentials: true
});