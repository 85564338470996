import { XIcon } from '@heroicons/react/solid'
import { Alert, Modal, IconButton, FormControlLabel, Checkbox } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FormElement from '../../components/Form/FormElement'
import Spinner from '../../components/spinner'
import usePostData from '../../hooks/usePostData'
import DeleteIcon from '@mui/icons-material/Delete';
import useGetData from '../Standard/useGetData'
import { getList } from '../../api/collections'

const standartInitialState = {
    limit: 100,
    pageNo: 0,
    type: 'Standard'
}

function EditSubject({ open, onClose, singleData }) {
    const [formData, setFormData] = useState(singleData)
    const [yearOptionsData, setYearOptionsData] = useState(null);
    const { handlePostData, error, success, onError, onSuccess } = usePostData({ queryName: 'subjectList', url: 'updateSubject' })
    const { handlePostData:handleDeleteSubject, error:deleteError, success:deleteSuccess} = usePostData({ queryName: 'subjectList', url: 'deleteSingleSubSubject' })
    const getStandardList = useGetData(standartInitialState, 'getStandardList')
    const [standardList, setStandardList] = useState([])
    const handleSetFromData = (e) => {
        let value = e.target.value
        let data = {
            ...formData,
            [e.target.name]: value
        }
        if(e.target.name === 'sub_subjects_toggle'){
            if(e.target.value === "0"){
                setFormData({
                    ...formData,
                    sub_subjects_toggle: "0",
                    sub_subjects: []
                })
                return
            }else if(e.target.value === "1"){
                setFormData({
                    ...formData,
                    sub_subjects_toggle: "1",
                    sub_subjects: [{name: ""}]
                })
                return
            }
        }
        setFormData(data)
    }

    const handleSubSubjectFormData = (e,idx) => {
        let value = e.target.value
        let temp = formData.sub_subjects
        temp[idx].name = value;
        setFormData({
            ...formData,
            sub_subjects: temp
        })
    }
    const addSubSubject = (e) => {
        e.preventDefault();
        let subSubject = formData.sub_subjects;
        subSubject.push({name: ""})
        setFormData({
            ...formData,
            sub_subjects: subSubject,
        })
    }

    const handleDeleteSubSubject = (id,subId = "new") => {
        let subSubject = formData.sub_subjects;
        if(subId !== "new"){
            handleDeleteSubject.mutate({id: subId,subject_id: formData.subject_id})
        }
        subSubject.splice(id,1)
        if(subSubject.length > 0){
            setFormData({
                ...formData,
                sub_subjects: subSubject
            })
        }else{
            setFormData({
                ...formData,
                sub_subjects_toggle: "0",
                sub_subjects: subSubject
            })
        }
    }

    const handleSaveFromData = (e) => {
        e.preventDefault()
        let tempStdSelected = [];
        standardList.forEach((std) => {
            if(std.isCheck === true) tempStdSelected.push(std.id)
        })
        const data = {
            ...formData,
            sub_subjects: JSON.stringify(formData['sub_subjects']),
            standard_selected: JSON.stringify(tempStdSelected)
        }
        handlePostData.mutate(data)
    }

    const handleCheckbox = (e,idx) => {
        const data = [...standardList]
        data[idx].isCheck = e.target.checked; 
        setStandardList(data)
    }

    useEffect(() => {
        if(getStandardList.handleGetDataList.isFetched){
            let tempArr = []
            console.log(formData)
            console.log(getStandardList.handleGetDataList.data?.data)
            getStandardList.handleGetDataList.data?.data?.map((it) => {
                let data = {
                    name: it.name,
                    id: it.id,
                    isCheck: false
                }
                let item = formData.standard_selected.find((id) =>{ return id === it.id})
                if(item){
                    data.isCheck = true   
                }
                return tempArr.push(data)
            })
            setStandardList(tempArr)
        }
    },[getStandardList.handleGetDataList.isLoading])

    const fetchOptionsData = () => {
		getList('Year')
			.then(res => {
				setYearOptionsData(res.data);
			})
    }

    useEffect(() => {
		fetchOptionsData();
	}, [])

    return (
        <>
            <Modal disableEscapeKeyDown open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[60%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Edit Subject</h2>
                            <XIcon onClick={onClose} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <div>
                            <form className='py-4 space-y-4' onSubmit={handleSaveFromData}>
                                <div className='flex flex-row'>
                                    <div className='w-[50%]'>
                                        <div className='mt-2'>
                                            <FormElement
                                                label='Subject'
                                                element={'input'}
                                                placeholder={`Enter Subject`}
                                                name={'subject_name'}
                                                required={true}
                                                value={formData.subject_name}
                                                errorMessage='This Field Cannot Be Empty !'
                                                onChange={handleSetFromData}
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <FormElement
                                                label={'Year'}
                                                name={`year_id`}
                                                element="select"
                                                optionValue={yearOptionsData}
                                                value={formData.year_id}
                                                onChange={handleSetFromData}
                                            />
                                        </div>
                                        <div className='mt-2'>
                                            <FormElement
                                                label='Contains Sub-Subjects'
                                                element={'select'}
                                                name={'sub_subjects_toggle'}
                                                value={formData.sub_subjects_toggle}
                                                optionValue={[{ name: 'Yes', value: '1' },{ name: 'No', value: '0' }]}
                                                required={true}
                                                errorMessage='This Field Cannot Be Empty !'
                                                onChange={handleSetFromData}
                                            />
                                        </div>
                                        {formData.sub_subjects_toggle === "1" && formData.sub_subjects.map((sub,idx) => {
                                            return (
                                                <div key={idx} className='flex items-center mt-2'>
                                                    <FormElement
                                                        label={`Sub-Subject ${idx + 1}`}
                                                        name={`sub_subject_${idx}`}
                                                        element="input"
                                                        value={formData.sub_subjects[idx].name}
                                                        onChange={(e) => handleSubSubjectFormData(e,idx)}
                                                    />
                                                    <IconButton sx={{ ml: 1, mb: -4 }} onClick={() => handleDeleteSubSubject(idx,sub.id)}>
                                                            <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            )  
                                        })}                            
                                        {formData.sub_subjects_toggle === "1" && <button onClick={addSubSubject} className="w-max text-xs text-gray-800 mt-2">+ <span className='underline underline-offset-1'>Add Subject</span></button>}
                                    </div>
                                    <div className='flex-1 ml-8'>
                                        <p className='label mt-2'>Select Standard:</p>
                                        <div className={`grid ${getStandardList.handleGetDataList.isLoading ? 'grid-cols-1' : 'grid-cols-2'}`}>
                                            {
                                                getStandardList.handleGetDataList.isFetched && standardList?.map((std,idx) => {
                                                    const {name,id,isCheck} = std
                                                    return <>
                                                        <FormControlLabel
                                                            key={id}
                                                            value={id}
                                                            control={<Checkbox checked={isCheck} onChange={(e) => handleCheckbox(e,idx)}/>}
                                                            label={name}
                                                            labelPlacement="end"
                                                        />
                                                    </>
                                                })
                                            }
                                            {
                                                getStandardList.handleGetDataList.isLoading && <Spinner />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {handlePostData.isLoading ?
                                    <Spinner /> :
                                    <div>
                                        <button type='submit' className='primaryButton '>Save</button>
                                    </div>}
                                {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{handlePostData?.error?.response?.data.message}</Alert>}
                                {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Subject Updated Succesfully</Alert>}
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditSubject