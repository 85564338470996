import { Button, Dialog, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import axios from "../../api/axios";

const cookies = new Cookies();
function DeleteStudent({ open, onClose, id, onDelete, url,year_id }) {
    const deleteDataFromServer = useMutation((id) => deleteData(id), {
        onSuccess: (data) => {
            if (data.status === 200) {
                onDelete()
            }
        },
    })
    const deleteData = async (id) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        fd.append('id', id);
        fd.append('year_id', year_id);
        const response = await axios({
            method: "POST",
            data: fd,
            url: `/${url}`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response
    }
    const handleDeleteData = (e) => {
        e.preventDefault()
        deleteDataFromServer.mutate(id)
    }
    if (!open) return null
    return (
        <>
            <Dialog disableEscapeKeyDown fullWidth={true} maxWidth={'sm'} open={open} onClose={onClose}>
                <div className=' bg-white w-full px-5'>
                    <div className='modal-head flex items-center justify-end'>
                        <div onClick={onClose}>
                            <IconButton>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <form className=' space-y-4' onSubmit={handleDeleteData}>
                        <div className=' space-y-7'>
                            <div>
                                <h1 className=' text-2xl text-red-700 font-semibold text-center'>Are You Sure You Want To Delete?</h1>
                            </div>
                        </div>
                        <div className=' space-x-4 text-center pb-5'>
                            <Button type='submit' variant="contained" color={`error`}>Delete</Button>
                            <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </>

    )
}

export default DeleteStudent