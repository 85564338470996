import React from 'react'
import { useState } from 'react'
import useFetchList from '../../hooks/useFetchList'
import FormElement from '../../components/Form/FormElement'
import { PlusIcon } from '@heroicons/react/solid'
import Spinner from '../../components/spinner';
import usePostData from '../../hooks/usePostData'
import { Alert, Snackbar } from '@mui/material'
import DeleteItem from '../../components/deleteItem'

const initialYear = {
  from: '',
  till: '',
  current_year: false
}

let yearFilter = {
  pageNo: 0,
  limit: 10,
}

const YearMaster = () => {
  const [optilleleteModal, setOptilleleteModal] = useState(false)
  const [deleteModalId, setDeleteModalId] = useState(null)
  const [yearsList, setYearsList] = useState([])
  const { handleGetData } = useFetchList(
    'getYearList',
    yearFilter,
    (res) => {
      let { data } = res
      setYearsList(data)
    })

  const handleAddYearData = () => {
    setYearsList((prevData) => [...prevData, initialYear])
  }

  const [tableYearId, setTableYearId] = useState(null)
  const [clientYearId, setClientYearId] = useState(null)

  const { handlePostData: createYear } = usePostData({ queryName: 'getYearList', url: 'createYear' })
  const { handlePostData: updateYear } = usePostData({ queryName: 'getYearList', url: 'updateYear' })

  const handleSaveData = (e) => {
    e.preventDefault()
    let $this = e.currentTarget
    let idx = $this.getAttribute('data-idx')
    let tableYearID = $this.getAttribute('data-tableyearid')
    let yearObjectToSave = yearsList[idx]
    if (yearObjectToSave.id) {
      updateYear.mutate(yearObjectToSave)
      setTableYearId(tableYearID)
    } else {
      createYear.mutate(yearObjectToSave)
      setClientYearId(idx)
    }
  }

  const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
  const [isDeletedError, setIsDeletedError] = useState(false)

  const deleteYearList = (e, yearid) => {
    let $this = e.currentTarget
    let yearidx = $this.getAttribute('data-idx')
    if (yearid) {
      setOptilleleteModal(true)
      setDeleteModalId(yearid)
    } else {
      let yearObjectToSave = yearsList
      yearObjectToSave.splice(yearidx, 1)
      setYearsList([...yearsList])
    }
  }

  const handleChangeYearData = (e) => {
    let $this = e.currentTarget
    let name = $this.name
    let value = $this.value
    let idx = $this.getAttribute('data-idx')
    let yeadDataCopy = yearsList[idx]
    yeadDataCopy[name] = value
    setYearsList([...yearsList])
  }

  const handleCheckCurrentYear = (e) => {
    let $this = e.currentTarget
    let name = $this.name
    let idx = $this.getAttribute('data-idx')
    let yeadDataCopy = yearsList[idx]
    yeadDataCopy[name] = $this.checked
    setYearsList([...yearsList])
  }

  return (
    <>
      <div>
        <div>
          {handleGetData?.isLoading || handleGetData?.isRefetching
            ? <Spinner />
            : handleGetData?.status === "error" ? <Spinner />
              : <>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:lg:grid-cols-4 gap-4">
                  <div onClick={handleAddYearData} className=" h-64 cursor-pointer border-gray-300 border-dashed border rounded p-3 space-y-3 flex flex-col items-center justify-center">
                    <PlusIcon className='w-10 h-10 text-gray-600' />
                    <small>Add Year</small>
                  </div>
                  {yearsList.map((yearList, yearIdx) => {
                    const { from, till, id, current_year } = yearList
                    return <div key={yearIdx} className=" border-gray-200 border rounded p-4 bg-white shadow-lg">
                      <form data-idx={yearIdx} data-tableyearid={id} onSubmit={handleSaveData} className=' space-y-3'>
                        <div>
                          <FormElement value={from} name={'from'} data-idx={yearIdx} onChange={handleChangeYearData} placeholder="Enter from Year" label='from Year' />
                        </div>
                        <div>
                          <FormElement value={till} name={'till'} data-idx={yearIdx} onChange={handleChangeYearData} placeholder="Enter till Year" label='till Year' />
                        </div>
                        <div className="space-x-2">
                          {console.log(current_year)}
                          <label htmlFor="">Is Current Year</label>
                          <input type="checkbox" name="current_year" data-idx={yearIdx} checked={current_year} onChange={handleCheckCurrentYear} />
                        </div>
                        <div className="flex items-center justify-center space-x-5">
                          {id
                            ? <button className=" bg-[color:var(--primarycolor)] text-white border w-full rounded py-1">
                              <div className="flex items-center justify-center">
                                <span>Save</span>
                                {(updateYear.isLoading && id === tableYearId) && <Spinner className='w-5 h-5 mx-0 my-0 ml-2' />}
                              </div>
                            </button>
                            : <button className=" bg-[color:var(--primarycolor)] text-white border w-full rounded py-1">
                              <div className="flex items-center justify-center">
                                <span>Save</span>
                                {(createYear.isLoading && yearIdx === clientYearId) && <Spinner className='w-5 h-5 mx-0 my-0 ml-2' />}
                              </div>
                            </button>}
                          <button data-idx={yearIdx} onClick={(e) => deleteYearList(e, id)} type="button" className=" border border-red-500 text-red-600 rounded w-full py-1">Delete</button>
                        </div>
                      </form>
                    </div>
                  })}
                </div>
                {/* <div className=" my-7">
                <Pagination currentPage={paramsObject.page_no} pageCount={list?.data.total_count} limit={paramsObject.limit} onPageChange={handlePageClick} />
              </div> */}
              </>
          }
        </div>
      </div>
      {optilleleteModal && <DeleteItem
        url={`/deleteYear`}
        queryKey={'getYearList'}
        onDelete={() => { setIsDeletedError(false); setIsDeletedSuccess(true) }}
        onError={() => { setIsDeletedError(true); setIsDeletedSuccess(false) }}
        open={optilleleteModal}
        deleteData={{ id: deleteModalId }}
        onClose={() => setOptilleleteModal(false)}
      />}
      <Snackbar open={isDeletedSuccess} autoHideDuration={2000} onClose={() => setIsDeletedSuccess(false)}>
        <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Subject Deleted Succesfully
        </Alert>
      </Snackbar>
      <Snackbar open={isDeletedError} autoHideDuration={2000} onClose={() => setIsDeletedError(false)}>
        <Alert onClose={() => setIsDeletedError(false)} severity="error" sx={{ width: '100%' }}>
          Something went wrong!!
        </Alert>
      </Snackbar>
    </>
  )
}

export default YearMaster