import { MenuIcon } from '@heroicons/react/solid'
import React from 'react'
import Cookies from 'universal-cookie';
import profile from '../../assets/images/profile.jpg'

import "./header.css";

const cookies = new Cookies
function Header({ onClick }) {
    const userName = cookies.get('_username')
    return (
        <>
            <div className=" text-white bg-[color:var(--primarycolor)] h-16 px-4 shadow sticky top-0 mb-4 z-40">
                <div className=" flex items-center justify-end h-full">
                    {/* <div className="cursor-pointer toggle" onClick={onClick}>
                        <MenuIcon className='w-8 text-white ' />
                    </div> */}
                    <div className="relative">
                        <div className=" cursor-pointer flex items-center">
                            <img src={profile} alt="profile" className=' h-10 rounded-full' />
                            <p className=' roboto ml-4 font-semibold'>{userName}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header