import React, { useEffect, useState } from 'react'
import Spinner from '../../components/spinner'
import useCreateFeeBook from './useCreateFeeBook'
import { Alert } from '@mui/material'

function EditFeeBookList({ data, feeBookId }) {
    const [termData, setTermData] = useState(data)
    const [selectedTab, setSelectedTab] = useState(1)
    const [currentsaveId, setCurrentSaveId] = useState('')
    const handleSelectTab = (idx) => {
        setSelectedTab(idx)
    }

    const handleChangeInput = (e) => {
        let $this = e.target
        let form = $this.form
        let termidx = form.getAttribute('data-termidx')
        let particularidx = form.getAttribute('data-particularidx')
        let data = termData[termidx].particulars_data[particularidx]
        if ($this.type === 'checkbox') {
            data.is_checked = $this.checked
        } else {
            let value = $this.value
            data.amount = value
        }
        setTermData([...termData])
    }

    const { setDataToServer, success, onSuccess, error, onError } = useCreateFeeBook('/updateFeesBook')
    const handleSaveFeeDetail = (e) => {
        e.preventDefault()
        let form = e.target
        let termidx = form.getAttribute('data-termidx')
        let term_id = form.getAttribute('data-termid')
        let particularidx = form.getAttribute('data-particularidx')
        let data = termData[termidx].particulars_data[particularidx]
        setCurrentSaveId(+particularidx)
        let formData = {
            ...data,
            fee_book_id: feeBookId,
            term_id
        }
        setDataToServer.mutate(formData)

    }
    return (
        <>
            <ul className='flex items-center justify-center space-x-4 mt-4'>
                {termData.map((item, idx) => {
                    const { term_name } = item
                    return <li key={idx} onClick={() => handleSelectTab(idx + 1)} className={`editStudent-tab  ${idx + 1 === selectedTab && 'active'}`}>{term_name}</li>
                })}
            </ul>
            <div className='tab-container my-10'>
                {termData.map((termData, idx) => {
                    const { particulars_data, term_id,is_edit } = termData
                    return <div key={idx} className={`tab-content presonalDetails divide-y ${selectedTab === idx + 1 && 'active'}`}>
                        {particulars_data.map((item, itemId) => {
                            const { particular_name, is_checked, amount } = item
                            console.log(item)
                            return <form key={itemId} onSubmit={handleSaveFeeDetail} data-termid={term_id} data-termidx={idx} data-particularidx={itemId}>
                                <div className='flex items-center py-4'>
                                    <input name='is_checked' type="checkbox" checked={is_checked} disabled={!is_edit} onChange={handleChangeInput} />
                                    <div className='flex-1 text-xl capitalize ml-2'>{particular_name}</div>
                                    <div>
                                        <input disabled={!is_edit} name='amount' type="text" value={amount} onChange={handleChangeInput} placeholder='Enter Amount' className='input' />
                                    </div>
                                    <div className='text-center ml-4 m'>
                                        {(currentsaveId === itemId && setDataToServer.isLoading === true) ? <Spinner className={'w-6 h-6 my-0'} /> : <button disabled={!is_edit} className='btn4 disabled:cursor-pointer disabled:opacity-50'>Save</button>}
                                    </div>
                                </div>
                            </form>
                        })}
                        {error && <Alert onClose={() => onError(false)} className='mb-4 capitalize' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.response?.data.message}, Please try again later</Alert>}
                        {success && <Alert onClose={() => onSuccess(false)} className='mb-4' severity="success" sx={{ width: '100%' }}>Updated Succesfully</Alert>}
                    </div>
                })}
            </div>
        </>
    )
}

export default EditFeeBookList