import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import Widgets from '../../components/widgets'
import { UserGroupIcon } from '@heroicons/react/solid'
import useFetchData from '../../hooks/useFetchData'
import FormElement from '../../components/Form/FormElement'
import usePostData from '../../hooks/usePostData'
import { getList } from '../../api/collections'
import { useSearchParams } from 'react-router-dom'
import { useGetData } from '../../hooks/useGetData'
import Spinner from '../../components/spinner'

function Dashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { handlePostData } = usePostData({ queryName: 'studentCount', url: 'studentCount' })

  const initialFilterData = {
    year_id: searchParams.get('year_id') || '',
    term_id: searchParams.get('term_id') || '',
    page_no: searchParams.get('_p') || 0,
    limit: 15,
  }
  const [resultFilterData, setResultFilterData] = useState(initialFilterData)
  const options = {
    standardArray: [],
    yearArray: [],
    termArray: []
  }
  const [optionsArray, setOptionsArray] = useState(options)

  const handleSetFromData = (e) => {
    let $this = e.target
    let data = {
      ...resultFilterData,
      [$this.name]: $this.value
    }
    searchParams.set($this.name, $this.value)
    setSearchParams(searchParams)
    setResultFilterData(data)
  }

  const fetchOptionData = (type) => {
    getList(type)
      .then(res => {
        if (type === "Year") {
          let tempId = res.data.filter((i) => i.current_year === "1")
          setResultFilterData({ ...resultFilterData, year_id: tempId[0].id })
          setOptionsArray(prevState => ({
            ...prevState,
            yearArray: res.data
          }));
          console.log({ ...resultFilterData, year_id: tempId[0].id })
          handlePostData.mutate({ ...resultFilterData, year_id: tempId[0].id })
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  useEffect(() => {
    const typeList = ["Year"]
    typeList.forEach((type) => {
      fetchOptionData(type)
    })
  }, [])

  const handleFilterFromData = (e) => {
    e.preventDefault()
    handlePostData.mutate(resultFilterData)
  }

  return (
    <>
      {handlePostData.isLoading &&
        <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffff9e] z-50 flex items-center justify-center'>
          <Spinner />
        </div>}
      <Layout>
        <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
          <div className='grid grid-cols-5 gap-x-4 items-end'>
            <FormElement
              label={"Select Year"}
              name={'year_id'}
              element={'select'}
              required={true}
              value={resultFilterData.year_id}
              optionValue={optionsArray.yearArray}
              errorMessage='Please Select Year!'
              onChange={handleSetFromData}
            />
            <div className='flex'>
              <button className='primaryButton w-full'>Fetch Data</button>
            </div>
          </div>
        </form>
        <div className="grid grid-cols-4 gap-x-4">
          <Widgets title={'Students'} count={handlePostData?.data?.countAll} icon={<UserGroupIcon />} />
          <Widgets title={'Pre Primary'} count={handlePostData?.data?.countPrePrimary} icon={<UserGroupIcon />} />
          <Widgets title={'Primary'} count={handlePostData?.data?.countPrimary} icon={<UserGroupIcon />} />
          <Widgets title={'Higher Secondary'} count={handlePostData?.data?.countHigherSecondary} icon={<UserGroupIcon />} />
        </div>
      </Layout>
    </>
  )
}

export default Dashboard