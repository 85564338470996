import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import axios from "../../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

function useCreateFeeBook(url) {
    const queryClient = useQueryClient()
    const [error, onError] = useState()
    const [success, onSuccess] = useState()
    const setDataToServer = useMutation((data) => setData(data), {
        onError: (err) => {
            onError(true)
        },
        onSuccess: () => {
            onSuccess(true)
            queryClient.invalidateQueries('feeBookListing')
        }
    })
    const setData = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({
            method: "POST",
            data: fd,
            url: url,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
        })
        return response.data
    }
    return { setDataToServer, error, onError, success, onSuccess }
}

export default useCreateFeeBook