import { DownloadIcon, EyeIcon } from '@heroicons/react/solid'
import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from '../../api/axios'
import { getList } from '../../api/collections'
import FormElement from '../../components/Form/FormElement'
import Layout from '../../components/Layout'
import Pagination from '../../components/paginate'
import Spinner from '../../components/spinner'
import { useGetData } from '../../hooks/useGetData'
import DownloadResult from './DownloadResult'
import ResultDialog from './ResultDialog'

const cookies = new Cookies;

function Results() {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialFilterData = {
        year_id: searchParams.get('year_id') || '',
        standard_id: searchParams.get('standard_id') || '',
        term_id: searchParams.get('term_id') || '',
        pageNo: searchParams.get('_p') || 0,
        limit: 10,
        searchQuery: '',
        student_id: ''
    }
    const [promotedTerms, setPromotedTerms] = useState([])
    const [resultFilterData, setResultFilterData] = useState(initialFilterData)
    const [finalSem, setFinalSem] = useState([])
    const [isResultDialogOpen, setResultDialogOpen] = useState(false)

    const { handleGetPostList } = useGetData('ResultGenerationStudentList', {
        onSuccess: () => { }
    })
    const handleSearchVal = (e) => {
        let $this = e.target
        let data = {
            ...resultFilterData,
            [$this.name]: $this.value
        }
        searchParams.set($this.name, $this.value)
        setSearchParams(searchParams)
        setResultFilterData(data)

        handleGetPostList.mutate(data)
    }
    const handleSetFromData = (e) => {
        let $this = e.target
        let textVal = e.target.options[e.target.selectedIndex].text;
        let data;
        if ($this.name === 'term_id') {
            data = {
                ...resultFilterData,
                [$this.name]: $this.value,
                term_name: textVal
            }
        } else {
            data = {
                ...resultFilterData,
                [$this.name]: $this.value,
            }
        }
        console.log(data);
        setResultFilterData(data)
    }

    const reportDownload = useMutation((data) => downloadReports(data), {
        onSuccess: (data) => {
            const href = URL.createObjectURL(new Blob([data.data]));
            let a = document.createElement('a');
            a.href = href;
            a.download = `${data.name ? data.name + '.pdf' : 'semester_result.pdf'}`;
            a.click();
        },
        onError: () => {
        }
    })

    const downloadReports = async (data) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in data) {
            fd.append(key, data[key]);
        }
        let res = await axios(
            {
                method: "POST",
                url: `/${data.url ? data.url : 'getSemesterReport'}`,
                responseType: 'blob',
                data: fd,
                headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '' }
            })
        return res
    };

    const handleFilterFromData = (e) => {
        if (e) {
            e.preventDefault()
        }
        searchParams.set('year_id', resultFilterData.year_id)
        searchParams.set('standard_id', resultFilterData.standard_id)
        searchParams.set('term_id', resultFilterData.term_id)
        setSearchParams(searchParams)
        handleGetPostList.mutate(resultFilterData)
    }

    const options = {
        standardArray: [],
        yearArray: [],
        termArray: []
    }
    const [optionsArray, setOptionsArray] = useState(options)
    useEffect(() => {
        const typeList = ["Year", "Standard", "SchoolTerm"]
        typeList.forEach((type) => {
            fetchOptionData(type)
        })
    }, [])
    const fetchOptionData = (type) => {
        getList(type)
            .then(res => {
                if (type === "Standard") {
                    setOptionsArray(prevState => ({
                        ...prevState,
                        standardArray: res.data
                    }));
                } else if (type === "SchoolTerm") {
                    let temp = res.data
                    let tempFinalSem = []
                    temp.forEach((it) => {
                        if (parseInt(it.ispromote) === 1) {
                            tempFinalSem.push(parseInt(it.id))
                        }
                    })
                    setFinalSem(tempFinalSem)
                    setOptionsArray(prevState => ({
                        ...prevState,
                        termArray: res.data
                    }));
                } else if (type === "Year") {
                    let tempId = res.data.filter((i) => i.current_year === "1")
                    setOptionsArray(prevState => ({
                        ...prevState,
                        yearArray: res.data
                    }));
                    setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handlePageClick = (val) => {
        let data = {
            ...resultFilterData,
            pageNo: val.selected
        }
        searchParams.set('_p', val.selected)
        setSearchParams(searchParams)
        setResultFilterData(data)
        handleGetPostList.mutate(data)
    }

    const handleOpenResultDialog = (e, name, studentData) => {
        let $this = e.currentTarget
        let data = {
            ...resultFilterData,
            student_id: $this.getAttribute('data-studentid'),
            student_name: name,
            ...studentData,
        }
        setResultFilterData(data)
        setResultDialogOpen(true)
    }

    const [isOpenDownloadModal, setIsOpenDownloadModal] = useState(false)
    const handleDownloadReport = (e) => {
        setIsOpenDownloadModal(true)
    }
    const handleDownloadAllReport = (e) => {
        // setIsOpenDownloadModal(true)
        reportDownload.mutate({ ...resultFilterData, url: 'downlaodAllReportStandardWise', name: 'reports' })
    }

    const handleCloseDialog = () => {
        setResultDialogOpen(false)
        handleFilterFromData()
    }
    return (
        <>
            <Layout>
                <div>
                    <form className='py-4 space-y-4' onSubmit={handleFilterFromData}>
                        <div className='grid grid-cols-5 gap-4 items-end'>
                            <FormElement
                                label={"Select Year"}
                                name={'year_id'}
                                element={'select'}
                                required={true}
                                value={resultFilterData.year_id}
                                optionValue={optionsArray.yearArray}
                                errorMessage='Please Select Year!'
                                onChange={handleSetFromData}
                            />
                            <FormElement
                                label={"Select Standard"}
                                name={'standard_id'}
                                element={'select'}
                                required={true}
                                errorMessage='Please Select Standard!'
                                onChange={handleSetFromData}
                                optionValue={optionsArray.standardArray}
                                value={resultFilterData.standard_id}
                            />
                            <FormElement
                                label={"Select Term"}
                                name={'term_id'}
                                element={'select'}
                                required={true}
                                optionValue={optionsArray.termArray}
                                errorMessage='Please Select Term!'
                                onChange={handleSetFromData}
                                value={resultFilterData.term_id}
                            />
                            <div className='flex'>
                                <button className='primaryButton w-full text-sm'>Fetch Student List</button>
                            </div>
                            <div className='flex' onClick={handleDownloadReport}>
                                <button type='button' className='primaryButton w-full disabled:cursor-not-allowed flex items-center justify-center text-sm' disabled={resultFilterData['term_id'] === "" || resultFilterData['standard_id'] === '' || resultFilterData['year_id'] === ''}>
                                    Download Report
                                    {/* <div className='ml-4'>
                                        <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>} */}
                                </button>
                            </div>
                            <div className='flex' onClick={handleDownloadAllReport}>
                                <button type='button' className='primaryButton text-sm w-full disabled:cursor-not-allowed flex items-center justify-center' disabled={resultFilterData['term_id'] === "" && resultFilterData['standard_id'] === '' && resultFilterData['year_id'] === ''}>
                                    Download All
                                    {reportDownload.isLoading && <div className='ml-4'>
                                        <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                    </div>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
                        <input onChange={handleSearchVal} name='searchQuery' className='input flex-1' placeholder='Search Student By First Name, Middle Name and Last Name ...' />
                    </div>
                </div>
                <div>
                    {handleGetPostList.isLoading
                        ? <Spinner />
                        : handleGetPostList?.data?.data.length === 0 || !handleGetPostList?.data?.data ? null : <>

                            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mb-16">
                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                    <thead>
                                        <tr className="text-left">
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                Sr.no
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Student Name
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Standard
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Division
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                View Result
                                            </th>
                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                Download
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {handleGetPostList?.data?.data.map((studentData, idx) => {
                                            const { year_name, standard_name, student_name, division_name, student_id, resultGenerated, standard_type_id } = studentData
                                            return <tr key={idx}>
                                                <td className="border-dashed border-t border-gray-200 userId">
                                                    <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                        {(resultFilterData.limit * (parseInt(resultFilterData.pageNo) + 1)) - (resultFilterData.limit - (idx + 1))}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-left text-sm">
                                                        {student_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                        {standard_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 lastName">
                                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                        {division_name}
                                                    </span>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <div className='flex items-center justify-center'>
                                                        <div data-studentid={student_id} data-standardtypeid={standard_type_id} onClick={(e) => handleOpenResultDialog(e, student_name, studentData)}>
                                                            <EyeIcon className='w-6 h-6 text-gray-700 cursor-pointer' />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                    <div className='flex items-center justify-center'>
                                                        <div data-studentid={student_id} data-standardtypeid={standard_type_id}>
                                                            <DownloadIcon className={`w-6 h-6  ${resultGenerated === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={(e) => {
                                                                e.preventDefault()
                                                                if (resultGenerated === 1) reportDownload.mutate({ ...resultFilterData, ...studentData })
                                                            }} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className=" my-7">
                                <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetPostList?.data?.total_count / resultFilterData.limit)} pageNo={handleGetPostList?.data?.page_no} />
                            </div>
                        </>
                    }
                </div>
            </Layout>
            {isResultDialogOpen && <ResultDialog terms={optionsArray.termArray} promotedTerms={promotedTerms} filterData={resultFilterData} open={isResultDialogOpen} onClose={handleCloseDialog} finalSem={finalSem} />}
            {isOpenDownloadModal && <DownloadResult filterData={resultFilterData} open={isOpenDownloadModal} onClose={() => setIsOpenDownloadModal(false)} />}
        </>
    )
}

export default Results