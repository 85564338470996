import React, { useEffect, useState } from 'react'
import { getList } from '../../api/collections';
import FormElement from '../../components/Form/FormElement';
import Layout from '../../components/Layout'
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import EditSubjectMapping from './EditSubjectMapping';
import useFetchList from '../../hooks/useFetchList';
import useGetData from '../Standard/useGetData';
import Spinner from '../../components/spinner';

const standardInitialState = {
  limit: 100,
  pageNo: 0,
  type: 'Standard'
}

const StandardManagement = () => {
  const [isEditSubjectMappingOpen,setIsEditSubjectMappingOpen] = useState(false)
  const [standard, setStandard] = useState('')
  const [editStandardId,setEditStandardId] = useState(null)
  const {handleGetDataList} = useGetData(standardInitialState, 'getStandardList')

  const handleModalClose = () => {
    setIsEditSubjectMappingOpen(false)
    setEditStandardId(null)
  }

  const handleEditStandard = (id,std) => {
    setIsEditSubjectMappingOpen(true)
    setEditStandardId(id)
    setStandard(std)
  }

  return (
    <>
      <Layout>
        <div className='mt-4'>
        {
          handleGetDataList.isLoading ? <Spinner /> : (
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative mt-6">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                    <thead>
                        <tr className="text-left">
                            <th className="bg-gray-100 w-20 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                Standard
                            </th>
                            <th className="bg-gray-100 w-40 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-right">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {handleGetDataList?.data?.data.map((standardData, idx) => {
                            const { name, id } = standardData
                            return <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                    {idx + 1}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 firstName">
                                    <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                        {name}
                                    </span>
                                </td>
                                <td className='border-dashed border-t border-gray-200 phoneNumber text-right'>
                                  <div className='px-6 py-2'>
                                    <IconButton onClick={() => handleEditStandard(id, name)}>
                                        <EditIcon />
                                    </IconButton>
                                  </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
          )
        }
          
        </div>
      </Layout>
      {isEditSubjectMappingOpen && <EditSubjectMapping standard={standard} standardId={editStandardId} open={isEditSubjectMappingOpen} onClose={handleModalClose} />}
    </>
  )
}

export default StandardManagement