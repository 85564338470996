import { useQuery } from "@tanstack/react-query";
import { useState } from 'react'
import axios from "../../api/axios";
import Cookies from "universal-cookie";
const cookies = new Cookies(); 



function useGetStudent(initialData) {
    const [getFormData, setGetFormData] = useState(initialData)
    const handleGetStudentList = useQuery(['', getFormData], () => getUsers(getFormData), {
        refetchOnWindowFocus: false
    })
    const getUsers = async (getFormData) => {
        let TOKEN = cookies.get("_pn_token");
        let fd = new FormData()
        for (var key in getFormData) {
            fd.append(key, getFormData[key]);
        }
        const response = await axios({ 
            method: "POST", 
            data: fd, 
            url: `/getStudentList`,
            headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }})
        return response.data
    }
    return { getFormData, setGetFormData, handleGetStudentList }
}

export default useGetStudent