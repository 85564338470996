import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useGetStudent from '../students/useGetStudent'
import Spinner from '../../components/spinner';
import Pagination from '../../components/paginate';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useQueryClient } from "@tanstack/react-query";
import Errors from '../errors';
import { getList } from '../../api/collections';
import EditReceipts from './EditReceipts';
import { useSearchParams } from 'react-router-dom';
import FormElement from '../../components/Form/FormElement';
import { useGetData } from '../../hooks/useGetData';
import Cookies from 'universal-cookie';
import axios from '../../api/axios';
import { Modal } from '@mui/material';
import { XIcon } from '@heroicons/react/solid';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const cookies = new Cookies();
const downloadReport = async (data) => {
    let fd = new FormData()
    let TOKEN = cookies.get("_pn_token");
    for (var key in data) {
        fd.append(key, data[key]);
    }
    const response = await axios({
        method: "POST",
        url: `/getReceiptCollectionStudentReport`,
        responseType: 'blob',
        data: fd,
        headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data;
}

function Receipts() {
    const [searchParams, setSearchParams] = useSearchParams();
    const initialState = {
        limit: 10,
        pageNo: searchParams.get('pageNo') || 0,
        standard_id: searchParams.get('standard_id') || '',
        fee_book_no: searchParams.get('fee_book_no') || '',
        class: '',
        division: '',
        year_id: searchParams.get('year_id') || ''
    }
    const { handleGetPostList } = useGetData('getReceiptCollectionStudentList', {
        onSuccess: () => { }
    })
    const handleModalClose = () => {
        setIsOpenEditModal(false)
        setEditData({})
        handleGetPostList.mutate({ ...resultFilterData })
    }

    const handlePageClick = (val) => {
        let data = {
            ...resultFilterData,
            pageNo: val.selected
        }
        // setSearchParams({ ...searchParams, '_p': val.selected })
        setSearchParams(data)
        setResultFilterData(data)
        handleGetPostList.mutate(data)
    }
    const [editReceiptsSuccess, setEditReceiptsSuccess] = useState(false);
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const options = {
        standardArray: [],
        divisionArray: [],
        yearArray: [],
        quotaArray: [],
    }
    const [standardOptions, setStandardOptions] = useState(options)
    const [resultFilterData, setResultFilterData] = useState(initialState)
    const [studentName, setStudentName] = useState('')
    const handleOpenEditModal = (id, name) => {
        setIsOpenEditModal(true)
        let data = handleGetPostList.data?.data?.find(x => x.student_id === id)
        setEditData(data)
        setStudentName(name)
    }

    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...resultFilterData,
            [e.target.name]: value
        }
        setSearchParams(data)
        setResultFilterData(data)
        handleGetPostList.mutate(data)
    }

    const handleStandardSelect = (e) => {
        // searchParams.set(e.target.name, e.target.value)
        // setSearchParams(searchParams)
        setResultFilterData({ ...resultFilterData, standard_id: e.target.value })
    }

    const [reportLoading, setReportLoading] = useState(false)
    const handleFilterSubmit = (e) => {
        e.preventDefault()
        if (e.nativeEvent.submitter.name === 'report') {
            setReportLoading(true)
            downloadReport(resultFilterData)
                .then((res) => {
                    const href = window.URL.createObjectURL(res);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'receipt_report.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setReportLoading(false)
                })
                .catch((err) => {
                    setReportLoading(false)
                })
        } else {
            let data = {
                ...resultFilterData,
                pageNo: 0,
                fee_book_no: '',
            }
            setSearchParams(data)
            setResultFilterData(data)
            handleGetPostList.mutate(data)
        }
    }

    useEffect(() => {
        getList("Standard")
            .then(res => {
                setStandardOptions(prevState => ({
                    ...prevState,
                    standardArray: res.data
                }))
            })
        getList("Year")
            .then(res => {
                let tempId = res.data.filter((i) => i.current_year === "1")
                setStandardOptions(prevState => ({
                    ...prevState,
                    yearArray: res.data
                }))
                setResultFilterData({ ...resultFilterData, year_id: tempId[0]?.id })
            })

    }, []);

    const handleSetFromData = (e) => {
        let $this = e.target
        let data = {
            ...resultFilterData,
            [$this.name]: $this.value
        }
        setResultFilterData(data)
    }

    const [isReportModalOpen, setIsReportModalOpen] = useState(false)
    const handleOpenReportModal = () => {
        setIsReportModalOpen(true)
    }

    const handleSubmitDownloadReport = (e) => {
        e.preventDefault()
        setReportLoading(true)
        downloadReport(resultFilterData)
            .then((res) => {
                const href = window.URL.createObjectURL(res);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'receipt_report.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setReportLoading(false)
            })
            .catch((err) => {
                setReportLoading(false)
            })
    }
    return (
        <>
            <Layout>
                <form onSubmit={handleFilterSubmit} className="flex">
                    <FormElement
                        label={"Select Year"}
                        name={'year_id'}
                        element={'select'}
                        required={true}
                        value={resultFilterData.year_id}
                        optionValue={standardOptions.yearArray}
                        errorMessage='Please Select Year!'
                        onChange={handleSetFromData}

                    />
                    <div className='ml-4'></div>
                    {/* <FormElement
                        label={"Select Standard"}
                        name={'standard_id'}
                        element={'select'}
                        required={true}
                        errorMessage='Please Select Standard!'
                        onChange={handleStandardSelect}
                        optionValue={standardOptions.standardArray}
                        value={resultFilterData.standard_id}
                    /> */}
                    <div className='flex flex-1 justify-end items-end ml-6'>
                        {/* <button className='primaryButton h-min'>Fetch Student List</button> */}
                        {/* <button className='flex flex-row primaryButton' name='report'>Generate Report {(reportLoading) && <Spinner className='w-5 h-5 mx-0 my-0 ml-2' />}</button> */}
                        <button type='button' onClick={handleOpenReportModal} className='flex flex-row primaryButton' name='report'>Generate Report</button>
                    </div>
                </form>
                <div className=''>
                    <div className='flex items-center justify-end pt-4 pb-8 space-x-4'>
                        <input onChange={handleSearchVal} name='fee_book_no' className='input flex-1' placeholder='Search by Fee Book Number' />
                    </div>
                    {handleGetPostList?.isLoading ? <Spinner />
                        : handleGetPostList?.status === "error" ? <Errors errorObject={handleGetPostList?.error} inline /> :
                            handleGetPostList?.isSuccess ?
                                handleGetPostList?.data?.data.length <= 0 ?
                                    <div className='text-center'>
                                        <span className=' font-semibold text-xl text-gray-600'>
                                            {handleGetPostList?.data?.message}
                                        </span>
                                    </div>
                                    : <>
                                        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                                <thead>
                                                    <tr className="text-left">
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                            Sr.no
                                                        </th>
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Student Name
                                                        </th>
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Fee Book Number
                                                        </th>
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Roll Number
                                                        </th>
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Grade/Std.
                                                        </th>
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Division
                                                        </th>

                                                        {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Generate Fee Receipt
                                                </th> */}
                                                        {
                                                            handleGetPostList?.data?.extra_headers?.map((it) => {
                                                                return <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                    {it?.term_name}
                                                                </th>
                                                            })
                                                        }
                                                        <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {handleGetPostList?.data?.data?.map((studentData, idx) => {
                                                        const { student_name, roll_no, division_name, standard_name, student_id, fee_book_no } = studentData
                                                        return <tr key={idx}>
                                                            <td className="border-dashed border-t border-gray-200 userId">
                                                                <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                                    {(resultFilterData.limit * (parseInt(resultFilterData.pageNo) + 1)) - (resultFilterData.limit - (idx + 1))}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 firstName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                    {student_name}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                    {fee_book_no}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                    {roll_no}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                                    {standard_name}
                                                                </span>
                                                            </td>
                                                            <td className="border-dashed border-t border-gray-200 lastName">
                                                                <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                                    {division_name}
                                                                </span>
                                                            </td>
                                                            {
                                                                handleGetPostList?.data?.extra_headers?.map((it) => {
                                                                    return <td className="border-dashed border-t border-gray-200 lastName">
                                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm uppercase">
                                                                            {studentData[it?.term_name]}
                                                                        </span>
                                                                    </td>
                                                                })
                                                            }
                                                            <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                                <div className='flex items-center justify-center'>
                                                                    <div>
                                                                        <IconButton onClick={() => handleOpenEditModal(student_id, student_name)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className=" my-7">
                                            <Pagination onPageChange={handlePageClick} pageCount={Math.ceil(handleGetPostList?.data?.total_count / resultFilterData?.limit)} pageNo={handleGetPostList?.data?.page_no} />
                                        </div>
                                    </> : <></>
                    }
                </div>
            </Layout>
            {isOpenEditModal && <EditReceipts name={studentName} open={isOpenEditModal} setSuccessMessageAlert={setEditReceiptsSuccess} handleClose={handleModalClose} data={editData} yearId={resultFilterData.year_id} />}
            {editReceiptsSuccess && <Snackbar open={editReceiptsSuccess} autoHideDuration={6000} onClose={() => setEditReceiptsSuccess(false)}><Alert className='mb-4' severity="success" sx={{ width: '100%' }}>Receipt Edited Successfully!</Alert></Snackbar>}
            <Snackbar open={isDeletedSuccess} autoHideDuration={6000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Student Deleted Succesfully
                </Alert>
            </Snackbar>
            <Modal disableEscapeKeyDown open={isReportModalOpen} onClose={() => setIsReportModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-[40%] bg-white center-the-div rounded'>
                    <div className='px-8 divide-y'>
                        <div className=''>
                            <h2 className='heading capitalize'>Generate Report</h2>
                            <XIcon onClick={() => setIsReportModalOpen(false)} className='w-6 h-6  absolute top-2 right-2 cursor-pointer' />
                        </div>
                        <form className='py-4 space-y-4' onSubmit={handleSubmitDownloadReport}>
                            <FormElement
                                label={"Select Standard"}
                                name={'standard_id'}
                                element={'select'}
                                required={true}
                                errorMessage='Please Select Standard!'
                                onChange={handleStandardSelect}
                                optionValue={standardOptions.standardArray}
                                value={resultFilterData.standard_id}
                            />
                            {reportLoading ?
                                <Spinner /> :
                                <div>
                                    <button className='primaryButton w-full'>Download Report</button>
                                </div>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Receipts
